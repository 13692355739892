import React, {useState, useEffect} from 'react';
import { useUser } from '../../contexts/UserContext';
import { toLatlang, getSelect } from '../../services/map';
import { setSelect, delSelect } from '../../services/mapAdmin';
import utils from '../common/Utils';
import DataList from '../common/DataList';
import Maps from '../common/Maps';
import UploadBtn from '../common/UploadBtn';
import ReflectBtn from '../common/ReflectBtn';
import DownloadBtn from '../common/DownloadBtn';
import ToggleSwitch from '../common/ToggleSwitch';
import Alert from '../common/Alert';
import Loader from '../common/Loader';

function TabFlexibility(props) {
  const {user} = useUser();
  const [range, setRange] = useState();
  const [src, setSrc] = useState([]);
  const [storedArray, setStoredArray] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [uploadedArray, setUploadedArray] = useState([]);
  const [delArray, setDelArray] = useState([]);
  const [storedCenters, setStoredCenters] = useState([]);
  const [centers, setCenters] = useState([]);
  const [curFlex, setCurFlex] = useState();
  const [bound, setBound] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [errPhrase, setErrPhrase] = useState('에러가 발생했습니다.');
  const [isLoading, setIsLoading] = useState(false);

  const dataUploaded = async (data) => {
    setIsLoading(true);
    const tempErrs = [];
    const tempDups = [];
    const tempUploaded = [];
    const tempCenters = [];
    const storedMap = new Map(selectedArray.map(item => [item['관리번호'], item]));
    for (const item of data) {
      const targetAddr = (item['지번주소'] == null || item['지번주소'] === 'null') ? item['도로명주소'] : item['지번주소'];
      const addr = await toLatlang(targetAddr);
      if (addr && addr['lat'] !== -1 && addr['long'] !== -1) {
        const isDup = storedMap.has(item['관리번호']);
        if (isDup) {
          tempDups.push(item['관리번호']);
          continue;
        }
        tempUploaded.push({...item, lat: addr['lat'], lng: addr['long']});
        tempCenters.push({
          address: targetAddr,
          center: {
            lat: parseFloat(addr['lat']),
            lng: parseFloat(addr['long'])
          },
        });
      }else {
        tempErrs.push(item['관리번호']);
      }
    }

    setIsLoading(false);
    if (tempErrs.length || tempDups.length) {
      setErrPhrase(`다음 데이터는 보여지지 않습니다 \n\n관리번호: \n${tempErrs.join(': 주소가 정확하지 않습니다.\n')}${tempErrs && tempErrs.length>0 ? ': 주소가 정확하지 않습니다.\n' : ''}${tempDups.join(': 탄력순찰에 등록된 관리번호입니다.\n')}${tempDups && tempDups.length>0 ? ': 탄력순찰에 등록된 관리번호입니다.' : ''}`);
      setIsErr(true);
    }
    setCenters(tempCenters);
    setSrc(tempUploaded);
    setUploadedArray(tempUploaded);
    if (tempUploaded.length) setIsActive(true);

  }

  const onSelectedArrayChanged = async (item) => {
    setCurFlex(null);
    setIsActive(true);
    console.log('onSelectedArrayChanged: ',new Date(), item);
    const curAddr = (item['지번주소'] == null || item['지번주소'] === 'null' || item['지번주소'].length < 1) ? item['도로명주소'] : item['지번주소'];
    console.log(curAddr);
    if (delArray.includes(item['관리번호'])) {
      const filteredArr = delArray.filter((existingItem) => existingItem !== item['관리번호']);
      setDelArray(filteredArr);
      setSelectedArray([...selectedArray, item]);
      const addr = await toLatlang(curAddr);
      console.log(addr);
      const newCenter = { address: curAddr, center: {lat: parseFloat(addr['lat']), lng: parseFloat(addr['long'])} };
      setCenters([...centers, newCenter]);
    } else {
      setDelArray([...delArray, item['관리번호']]);
      setSelectedArray(selectedArray.filter((existingItem) => existingItem['관리번호'] !== item['관리번호']));
      const updatedCenters = centers.filter(center => center.address !== curAddr);
      setCenters(updatedCenters);
    }
  }

  const onSelectedAllChanged = async (e) => {
    setIsActive(true);
    const val = e.target.checked;
    if (val) {
      setDelArray([]);
      setCenters(storedCenters);
      setSelectedArray(storedArray);
    } else {
      setDelArray(storedArray.map(item => item['관리번호']));
      setCenters([]);
      setSelectedArray([]);
    }
  }

  const onUploadedArrayChanged = async (item) => {
    setCurFlex(null);
    const targetAddr = (item['지번주소'] == null || item['지번주소'] === 'null') ? item['도로명주소'] : item['지번주소'];
    if (uploadedArray.includes(item)) {
      const filteredArr = uploadedArray.filter((existingItem) => existingItem !== item);
      setUploadedArray(filteredArr);
      const updatedCenters = centers.filter(center => center.address !== targetAddr);
      setCenters(updatedCenters);
    } else {
      setUploadedArray([...uploadedArray, item]);
      const newCenter = { address: targetAddr, center: {lat: parseFloat(item['lat']), lng: parseFloat(item['lng'])} };
      setCurFlex(newCenter);
      setCenters([...centers, newCenter]);
    }
  }

  const onDataClick = async (item) => {
    // const addr = await toLatlang((item['지번주소'] == null || item['지번주소'] === 'null' || item['지번주소'].length < 1) ? item['도로명주소'] : item['지번주소']);
    const newCenter = { address: (item['지번주소'] == null || item['지번주소'] === 'null' || item['지번주소'].length < 1) ? item['도로명주소'] : item['지번주소'], center: {lat: parseFloat(item['lat']), lng: parseFloat(item['lng'])} };
    setCurFlex(newCenter);
  }

  const onRangeChanged = (area) => {
    setRange(area);
  }

  const onSave = async(data) => {
    let updatedStoredArray = [...storedArray];

    for (let i=0; i<delArray.length; i++) {
      const response = await delSelect(delArray[i]);
      if (response) {
        updatedStoredArray = updatedStoredArray.filter(item => item['관리번호'] !== delArray[i]);
      }
    }
    
    if (uploadedArray.length) {
      const convertedArr = uploadedArray.map(x => {
        for (const key in x) {
          if (x[key] === "null") {
            x[key] = "";
          }
        }
        return ({
          no: x['관리번호'],
          get_date: utils.dateFormatter(utils.excelSerialToDate(x['접수일자'])),
          start_date: utils.dateFormatter(utils.excelSerialToDate(x['순찰시작일자'])),
          end_date: utils.dateFormatter(utils.excelSerialToDate(x['순찰종료일자'])),
          start_time: x['순찰시작시간'].toString(),
          end_time: x['순찰종료시간'].toString(),
          addr: x['지번주소'] ?? '',
          road: x['도로명주소'] ?? '',
          request: x['순찰요청사항'] ?? '',
          reason: x['순찰사유'] ?? ''
        })
      })
      const result = await setSelect(convertedArr);
      if (result) {
        const uploadNos = new Set(uploadedArray.map(item => item['관리번호']));
        setSrc(src.filter((item) => !uploadNos.has(item['관리번호'])));
        setStoredArray([...updatedStoredArray, ...uploadedArray]);
        setSelectedArray([...updatedStoredArray, ...uploadedArray]);
        setUploadedArray([]);
      }
    } else {
      setSelectedArray(updatedStoredArray);
      setStoredArray(updatedStoredArray);
      if (delArray.length < 1) setIsEmpty(true);
    }
  }

  const getFlexData = async() => {
    const res = await getSelect(user.belong2, user.patrol.name);
    if (res.data.message != 'fail') {
      const centerArr = [];
      const reversedArr = res.data.data.map(item => {
        centerArr.push({ address: (item.addr == null || item.addr == 'null') ? item.road : item.addr, center: {lat: item.lat, lng: item.long} })
        return ({
          관리번호: item.no,
          접수일자: item.get_date,
          순찰시작일자: item.start_date,
          순찰종료일자: item.end_date,
          순찰시작시간: item.start_time,
          순찰종료시간: item.end_time,
          지번주소: item.addr,
          도로명주소: item.road,
          순찰요청사항: item.request,
          순찰사유: item.reason,
        })
      })
      setCenters(centerArr);
      setStoredCenters(centerArr);
      setStoredArray(reversedArr);
      setSelectedArray(reversedArr);
    }
  }

  const onCenterChange = (center) => {
    setBound({'sw':{'lat': center._sw._lat, 'long':center._sw._lng}, 
    'ne':{'lat': center._ne._lat, 'long':center._ne._lng}},);
    // getFlexData();
  }

  useEffect(() => {
    if (user) {
      getFlexData();
    }
  }, [user])

  return (
    <div className="flex">
      {isLoading && <div className='absolute z-50 w-full h-full bg-black opacity-30'><Loader className='opacity-100'></Loader></div>}
      <div className="flex flex-col w-2/5">
        <span className="flex-1">
          <div className="flex flex-wrap justify-between bg-white p-3 ">
            <div className='flex flex-wrap mb-3 w-full'>
              <p className="bg-white text-sm text-left break-all">&#8251; 
                <span className='bg-red-100 text-sm break-all '>요청 기간이 지난 탄력순찰 데이터</span>
                는 방범대원에게 보이지 않습니다.
              </p>
            </div>
            <div className="flex gap-1 items-center" >
              <img src={utils.car} alt="patrol car" width={'24px'}/>
              <p className="text-lg font-bold">탄력순찰</p>
            </div>
            <DownloadBtn text={'탄력 순찰 데이터 양식'} />
            <div className="flex w-full gap-2 justify-end mt-2">
              <UploadBtn text={'신규 탄력 순찰 업로드'} onComplete={dataUploaded}/>
              <ReflectBtn text={'선택지역'} onClick={onSave} isActive={isActive} setIsActive={(v) => setIsActive(v)}/>
            </div>
          </div>
          <div className="bg-white">
            <DataList source={src} selectedArray={uploadedArray} onChange={onUploadedArrayChanged} onTrClick={onDataClick}/>
          </div>
        </span>
        <span className="flex-1 mt-3">
          <div className="flex flex-col gap-1 p-3 bg-white">
            <div className="flex gap-1 items-center">
              <img src={utils.car} alt="patrol car" width={'24px'}/>
              <p className="text-lg font-bold">탄력순찰 적용 지점</p>
              <ToggleSwitch
                val={selectedArray.length === storedArray.length ? true : false}
                onChange={(e) =>onSelectedAllChanged(e)}
              />
            </div>
          </div>
          <div className="bg-white">
            {storedArray.length ? (
              <div>
                <DataList source={storedArray} selectedArray={selectedArray} onChange={onSelectedArrayChanged} onTrClick={onDataClick}/>
              </div>
              ) : ''}
          </div>
        </span>
      </div>
      <Maps className="w-3/5" curFlex={curFlex} centers={centers} setRange={onRangeChanged} onCenterChange={onCenterChange}/>
      {isEmpty && <Alert type="error" message="변경 사항이 없습니다." onClose={() => setIsEmpty(false)}/>}
      {isErr && <Alert type="error" message={errPhrase} onClose={() => setIsErr(false)}/>}
    </div>
  );
}

export default TabFlexibility;
