import React, { useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import { usePatrolMemo } from '../../../contexts/PatrolMemoContext';
import ArticleEditor from '../../common/ArticleEditor';

function MemoDetail(props) {
  const { id } = useParams();
  const {patrolMemo} = usePatrolMemo();
  const [target, setTarget] = useState();
  const setTargetMemo = async () => {
    const memo = await patrolMemo.filter(x => x.id === Number(id))[0];
    setTarget(memo);
  }
  useEffect(() => {
    if (id) {
      setTargetMemo();
    }
  }, [id])
  return (
    <div>
      {target && <ArticleEditor parent={'memo'} memo={target} />}
    </div>
  );
}

export default MemoDetail;