import React from 'react';
import '../../styles/loader.css';

function Loader(props) {
  return (
    <div className="absolute top-0 left-0 z-30 w-full flex justify-center items-center" style={{height: '70%'}}>
      <div className="loadingio-spinner-spin-omxliu0y44c">
        <div className="ldio-aqs9nuz2775">
          <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;