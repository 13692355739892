import React, {useState, useEffect} from 'react';
import ToggleSwitch from './ToggleSwitch';
import utils from '../common/Utils';

function DataList(props) {
  const [src, setSrc] = useState([]);
  const [tableHeader, setTableHeader] = useState(['요청기간', '요청시간', '주소', '요청사항', '사유', '적용여부']);
  const onToggleChange = (e, row) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    if (props.parent && !props.selectedArray) {
      props.onChange(row, false)
    } else {
      props.onChange(row)
    }
  }

  const onTrClick = (e, row) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    if (e.target.type == 'checkbox') {
      console.log('here');
      return;
    } else if (props.source) {
      props.onTrClick(row);
    }
  }
  useEffect(() => {
    setSrc(props.source);
  }, [props.source])
  useEffect(() => {
    if (props.parent) {
      setTableHeader(['시군', '구분', '축제명', '축제기간', '장소', '주소', '비고', '적용여부'])
    }
  }, [props.parent])
  return (
  <div className="overflow-auto" style={{maxHeight: "300px"}} >
    <table className="w-full table-fixed relative">
      <thead className="sticky top-0 left-0 z-20">
        <tr>
          {tableHeader.map(txt => (
            <th key={txt} className="py-3 bg-indigo01 text-center border border-gray-300">{txt}</th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {src ? src.map((row, idx) => (
          <tr key={'row' + idx} className="relative" onClick={(e) => props.parent ? null : onTrClick(e, row)}>
            
            {tableHeader.map(col => (
              (col === '적용여부') ? (
                (<td key={col} className={"py-2 border border-gray-300"}>
                  {
                    <div>
                    <ToggleSwitch
                      val={props.parent
                        ? (props.selectedArray && props.selectedArray.some(item => item['축제명'] === row['축제명']))
                          || (!props.selectedArray && props.source.includes(row))
                          ? true
                          : false
                        : (props.selectedArray && props.selectedArray.includes(row))
                          || (!props.selectedArray && props.source.includes(row))
                          ? true
                          : false}
                      onChange={(e) => onToggleChange(e, row)} />
                  </div>}
                </td>)
              ) : (col === '주소') ? (
                (<td key={col} className="py-2 border border-gray-300">
                  <div className="max-h-32 overflow-y-auto">
                    {(row['지번주소'] == null || row['지번주소'] === 'null' || row['지번주소'].length < 1) ? row['도로명주소'] : row['지번주소']}
                  </div>
                </td>)
              ): (col === '요청기간') ? (
                
                (<td key={col} className={`${utils.excelSerialToDate(row['순찰종료일자']) < new Date() ? 'bg-red-100' : null} py-2 border border-gray-300`}>
                  <div className="max-h-32 overflow-y-auto">
                    {utils.dateFormatter(utils.excelSerialToDate(row['순찰시작일자']))+' ~ ' + utils.dateFormatter(utils.excelSerialToDate(row['순찰종료일자']))}
                  </div>
                </td>)
              ) : (col === '요청시간') ? (
                (<td key={col} className="py-2 border border-gray-300">
                  <div className="max-h-32 overflow-y-auto">
                    {utils.convertToTwoDigits(row['순찰시작시간'])+' ~ ' + utils.convertToTwoDigits(row['순찰종료시간'])}
                  </div>
                </td>)
              ) : (col === '축제기간') ? (
                  <td key={col} className={`${utils.excelSerialToDate(row['축제종료일자']) < new Date() ? 'bg-red-100' : null} py-2 border border-gray-300`}>
                  <div className="max-h-32 overflow-y-auto">
                    {utils.dateFormatter(utils.excelSerialToDate(row['축제시작일자']))+' ~ ' + utils.dateFormatter(utils.excelSerialToDate(row['축제종료일자']))}
                  </div>
                </td>
              ) : (col === '요청사항') ? (
                  <td key={col} className="py-2 border border-gray-300">
                    <div className="max-h-32 overflow-y-auto">
                      {row['순찰요청사항'] && row['순찰요청사항'].length > 0 && row['순찰요청사항'] !== 'null' ? row['순찰요청사항'] : '-'}
                    </div>
                  </td>
              ) : (col === '사유') ? (
                  <td key={col} className="py-2 border border-gray-300">
                    <div className="max-h-32 overflow-y-auto">
                      {row['순찰사유'] && row['순찰사유'].length > 0 && row['순찰사유'] !== 'null' ? row['순찰사유'] : '-'}
                    </div>
                  </td>
              ) : (
                <td key={col} className="py-2 border border-gray-300">
                  <div className="max-h-32 overflow-y-auto">
                    {row[col]}
                  </div>
                </td>
              )
            ))}
          </tr>
        )) : ''}
      </tbody>
      <tfoot>

      </tfoot>
    </table>
  </div>
  );
}

export default DataList;