import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { mainSection } from '../../styles';
import { ColoredButton } from '../common/Button';
import { setUser as SETUser } from '../../services/auth';
import Alert from '../common/Alert';

function ModProfile(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const {user, setUser} = useUser();
  const navigate = useNavigate();
  const [isChanged, setIsChanged] = useState(false)
  const [phone, setPhone] = useState(props.userData.phone || '');
  useEffect(() => {
    console.log(user);
  }, [user])
  useEffect(() => {
    console.log(props);
  }, [props])
  const inputChanged = (e) => {
    if (e.target.value.trim() === '') setIsChanged(false);
    else setIsChanged(true);
    setPhone(e.target.value.trim());
  }
  const onPwBtnClick = () => {
    navigate('/password');
  }
  const goBack = () => {
    window.history.back();
  }
  const onSubmit = async () => {
    const data = {
      id: user.id,
      phone: phone,
    }
    const res = await SETUser(data);
    if (res) {
      setUser(data);
      await setAlertMsg('회원정보가 수정되었습니다.')
    } else {
      await setAlertMsg('에러가 발생했습니다.');
    }
    await setIsOpen(true);
  }
  return (
    <div className={`${mainSection} px-0 h-screen flex text-left justify-center`}>
      <div className="overflow-auto py-3 w-full max-w-[600px]">
      <div className="row border-2 mx-2 align-center w-max rounded-full px-4 active:bg-indigo03 text-sm" onClick={goBack}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" className="rotate-180" viewBox="-8.5 0 32 32" fill="#000000">
          <path xmlns="http://www.w3.org/2000/svg" d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
        </svg>
        뒤로
      </div>
        <div className="grid grid-cols-4 p-2">
          <p className="col-span-1 text-center p-2">이름</p>
          <p className="col-span-3 bg-gray02 p-2">{user.username ? user.username : '-'}</p>
        </div>
        <div className="grid grid-cols-4 p-2">
          <p className="col-span-1 text-center p-2">구분</p>
          <p className="col-span-3 bg-gray02 p-2">{user.role}</p>
        </div>
        <div className="grid grid-cols-4 p-2">
          <p className="col-span-1 text-center p-2">비밀번호</p>
          <button className="bg-indigo08 px-1 rounded text-white" onClick={onPwBtnClick}>변경</button>
        </div>
        <div className="grid grid-cols-4 p-2">
          <p className="col-span-1 text-center p-2">전화번호</p>
          <input type="number" inputMode='numeric' className="col-span-3 bg-indigo01 p-2 rounded hover:bg-indigo02 hover:border-indigo08 focus:border-indigo08" value={phone} onChange={inputChanged} />
        </div>
        <div className="grid grid-cols-4 p-2">
          <p className="col-span-1 text-center p-2">소속</p>
          <p className="col-span-3 bg-gray02 p-2">{user.belong1}-{user.belong2}-{user.patrol.name}방범대</p>
        </div>
        <div className="flex justify-center align-center p-2 mt-4">
          <ColoredButton type="custom" click={onSubmit} text="수정" disabled={!isChanged} />
        </div>
      </div>
      {isOpen && <Alert type="warning" message={alertMsg} onClose={() => setIsOpen(false)}/>}
    </div>
  );
}

export default ModProfile;