import axios from 'axios';
import { SERVER, NCPID, NCPKEY } from '../environment';
import { stringToDateFormatter } from '../components/common/Utils';

const headers = {
  'X-NCP-APIGW-API-KEY-ID': NCPID,
  'X-NCP-APIGW-API-KEY': NCPKEY,
}

export const getGrid = async (date, gridArr, area = '', patrol = '') => {
  const data = {
    date: date,
    grids: gridArr ? gridArr : '',
    area: area,
    patrol: patrol ? patrol : ''
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/getgrid/`, data);
    if (response.data.message === 'Success') {
      const jsonData = JSON.parse(response.data.grids);
      return {center: response.data.patrol_pos, grid: jsonData};
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getPath = async (locations='효자5동') => {
  const data = {
    patrollers: [locations]
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/getpath/`, data);
    if (response.data.message === 'success') {
      
      const transformedArray = await transformArray(response.data.patrollers)
      return transformedArray;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getSelect = async (patrol, dong) => {
  const data = {
    patrol: patrol === '전체' ? 'all' : dong === '전체' ? '**' + (['전주시 덕진구', '전주시 완산구'].includes(patrol) ? patrol.slice(-3) : patrol) : '',
    dong: dong === '전체' ? '' : dong,
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/fxpathview/`, data);
      return response
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getLocal = async (area, location) => {
  const data = {

    patrol: location,
    area: location === 'all' ? "" : area,
    dong: "",
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/regionview/`, data);
    if (response.data.message === 'success') {
      return response.data.data;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const toLatlang = async (query) => {

  const data = {
    addr1: query,
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/addr2geo/`, data);
    if (response.data.message === 'success') {
      console.log(response.data.data);
      return response.data.data;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

// export const toLatlang = async (query) => {
//     try {
//       const response = await fetch(`/map-geocode/v2/geocode?query=${query}`, {
//         headers
//       })
//       const jsonData = await response.json();
//       // console.log(jsonData);
//       return jsonData;
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
// }

export const toAddress = async (query) => {
  try {
    const response = await fetch(`/map-reversegeocode/v2/geocode?coords=${query}`, {
      headers
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export const direction15 = async ({routeOption='traoptimal', start, goal, waypoints}) => {
  try {
    const response = await fetch(`/map-direction-15/v1/driving?start=${start[0]},${start[1]}&goal=${goal[0]},${goal[1]}&option=${routeOption}&waypoints=${waypoints}`, {
      headers
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    
  }
}

const transformArray = async (inputArray) => {
  const resultArray = [];
  await inputArray.forEach((item) => {
    const location = item.patroller;
    const nameArray = [];
    const routes = item.routes.map((route, idx) => {
      nameArray.push(route.name);
      return route.route[0];
    });

    resultArray.push({
      location,
      paths: routes.map((route, index) => ({
        name: nameArray[index],
        routes: route
      }))
    });
  });
  return resultArray;
}