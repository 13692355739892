import React from 'react';
import EduProgress from './EduProgress';
import EduDuration from './EduDuration';

function EduHeader(props) {
  const attrs = [{title: '진도율', comp: <EduProgress />}, {title: '수강기간', comp: <EduDuration />}];
  return (
    <div className="grid grid-cols-2 gap-2 mt-2 p-2 mx-auto">

      {attrs.map(attr => {
        return (
          <div key={attr.title} className="col-span-1 border border-indigo07 rounded pb-2">
            <p className="p-2 text-left font-bold">{attr.title}</p>
            {attr.comp}
          </div>
        )
      })}
    </div>
  )
}

export default EduHeader;