import { createContext, useContext, useState } from 'react';
import { useNavermaps } from 'react-naver-maps';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [navermapState, setNavermapState] = useState(useNavermaps());
  const [eventLocation, setEventLocation] = useState('');
  const [precasDate, setPrecasDate] = useState();

  return (
    <MapContext.Provider value={{ navermapState, setNavermapState, eventLocation, setEventLocation, precasDate, setPrecasDate }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  return useContext(MapContext);
}