import axios from 'axios';
import { TEST, SERVER } from '../environment';

export const uploadFile = async (file, msg) => {
    const formData = new FormData();
    formData.append('type', msg);
    formData.append('file', file);
    try {
        const response = await axios.post(`${window.location.origin !== 'http://localhost:3000' ? SERVER : TEST}/patrolmain/api/safetyinfo/`, formData);
        console.log(response);
        console.log(response.data.message ? response.data.message : 'no');
        return response.data.message ? response.data.message : false;

    } catch (error) {
    console.error('Error:', error);
    return false;
    }

}