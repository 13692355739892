import React, {useState} from 'react';
import {Button} from './Button';

function Alert({type, selection, message, onClose, onConfirm}) {
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = useState('all');


  const handleSelectionChange = async (e) => {
    setSelected(e.target.value);
  }

  const closeAlert = () => {
    if (onClose) {
      onClose();
    } else {
      setIsOpen(false);
    }
  };
  const alertClasses = {
    success: 'border-green-600',
    error: 'border-red-600',
    warning: 'border-yellow-600',
    info: 'border-blue-600',
  };
  if (isOpen) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
      <div className={`whitespace-pre-line shadow-lg p-4 rounded-md border-4 bg-white ${alertClasses[type]} text-left w-5/6 md:w-1/3 max-h-96 overflow-y-auto`}>
        {selection && (
          <select id="selection" name="selection" value={selected} onChange={(e) => handleSelectionChange(e)} className="w-1/2 border rounded p-3 text-center">
            {Object.entries(selection).map(([key, val]) => (
              <option key={key} value={key}>{val}</option>
            ))}
          </select>
        )}{message}
        <div className="mt-4 flex justify-end gap-2">
          {onConfirm ? <Button type={'success'} text={'확인'} click={() => onConfirm(selected)} disabled={false}/>: ''}
          <Button type={'error'} text={'닫기'} click={closeAlert} disabled={false}/>
        </div>
      </div>
    </div>
  );
  } else return null
}

export default Alert;