import React, { useState } from 'react';
import Signin from './Signin';
import Signup from './Signup';
import '../../styles/utils.css';

function Account(props) {
  const [isLogin, setLogin] = useState(true);
  const setLoginFunc = async(value) => {
    setLogin(value);
  }
  return (
    <div className="mainContent flex-grow bg-white flex flex-col justify-center items-center overflow-auto ">
      <Signup setLoginFunc={setLoginFunc} />
    </div>
  );
}

export default Account;