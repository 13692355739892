import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../common/Utils';
import TabMap from '../main/TabMap';
import TabLocal from '../main/TabLocal';
import TabRoute from '../main/TabRoute';
import MemoDetail from '../main/TabRoute/MemoDetail';
import TabEdu from '../main/TabEdu';
import EduDetail from '../main/TabEdu/EduDetail';
import ArticleEditor from '../common/ArticleEditor';
import Profile from '../sidemenu/Profile';
import ModProfile from '../sidemenu/ModProfile';
import NewsList from '../admin/tabNews/NewsList';
import PageNotFound from '../common/PageNotFound';
import { VideoProvider } from '../../contexts/VideoContext';
import { useHeader } from '../../contexts/HeaderContext';
import { useUser } from '../../contexts/UserContext';
import { getVideoData } from '../../services/mapMain';

const tabs = ['안심지도', '순찰경로', '안전교육', '마이페이지'];
const tabPath = ['', 'route', 'edu', 'profile'];
const tabImgs = utils.getMainTabImg();
const activeTabImgs = utils.getActiveTabImg();
function Main(props) {
  const { setHeader } = useHeader();
  const { user, setUser, setUserAuth } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [pathName, setPathName] = useState('');
  const [eduData, setEduData] = useState(null);
  const [addr, setAddr] = useState({belong1: '전라북도', belong2: '', belong3: ''});

  const handleEduData = (data) => {
    setEduData(data);
  };
  const updateVideo = async(res) => {
    await setUser({
      ...props.userData,
      lectures: res
    })
  }
  const getLectures = async() => {
    let res;
    if (window.location.hostname === 'localhost') {
      res = user.lectures || [];
    } else {
      res = await getVideoData();
    }
    await updateVideo(res);
  }

  useEffect(() => {
    if (props.userData && props.userData.belong1) {
      getLectures()
      setUser(props.userData);
      setUserAuth(props.userState);
      setAddr({
        belong1: props.userData.belong1,
        belong2: props.userData.belong2 || '',
        patrol: props.userData.patrol.name || ''
      })
    }
  }, [props])
  useEffect(() => {
    const pathname = location.pathname.split('/')[1];
    setPathName(pathname);
    const targetIdx = tabPath.findIndex(x=> x === pathname);
    setHeader(tabs[targetIdx]);
  }, [location])

  return (
    <VideoProvider>
      <div className="w-full max-w-screen-md mx-auto">
        <Routes>
          <Route path="" element={<TabMap />} />
          <Route path="local" element={<TabLocal />} />
          <Route path="route" element={<TabRoute />} />
          <Route path="route/write" element={<ArticleEditor parent="route" />} />
          <Route path="route/:id" element={<MemoDetail />} />
          <Route path="edu" element={<TabEdu eduData={eduData} />} />
          <Route path="edu/:id" element={<EduDetail handleEduData={handleEduData} />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/mod" element={<ModProfile userData={props.userData} />} />
          <Route path="profile/news" element={<NewsList addr={addr} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <div className="flex-none">
          <div className="tab mainTabs flex mt-2 w-full max-w-screen-md mx-auto fixed bottom-0">
            {props.userState === 1 ? (
              tabs.map((tab, index) => (
                <Link to={tabPath[index]} key={'tab'+index} className="w-1/3">
                  <button
                    className={`tabBtn btn p-3 w-full mt-auto text-sm text-gray900 hover:bg-indigo01 ${pathName === tabPath[index] ? 'bg-indigo01 text-indigo08 font-bold' : 'bg-white'} ${index === 3 ? 'text-xs h-[74px]' : ''}`}
                  >
                    <img alt={'tab'+index} src={pathName === tabPath[index] ? index === 0 ? activeTabImgs['map'] : activeTabImgs[tabPath[index]] : tabImgs[index]} 
                      style={{width: '30px'}}
                      className="mx-auto"
                    />
                    {tab}
                  </button>
                </Link>
              ))
            ) : (
              tabs.map((tab, index) => (
                index === 0 || (index === 3 && props.userState === 2) ? (
                <Link to={tabPath[index]} key={'tab'+index} className="w-1/2">
                  <button
                    className={`tabBtn btn p-3 w-full mt-auto text-sm text-gray900 hover:bg-indigo01 ${pathName === tabPath[index] ? 'bg-indigo01 text-indigo08 font-bold' : 'bg-white'}`}
                  >
                    <img alt={'tab'+index}  src={pathName === tabPath[index] ? index === 0 ? activeTabImgs['map'] : activeTabImgs[tabPath[index]] : tabImgs[index]}
                      style={{width: '30px'}}
                      className="mx-auto"
                    />
                    {tab}
                  </button>
                </Link>
                ) : index === 2 ? (
                  <a href="tel:112" key={'tab'+index} className="w-1/2">
                    <button
                      className={`tabBtn btn p-3 w-full mt-auto text-sm text-gray900 hover:bg-indigo01 bg-white`}
                    >
                      <img alt={112} src={utils.car} style={{width: '30px'}} className="mx-auto"/>
                      {'신고하기'}
                    </button>
                  </a>
                ) : ''
              ))
            )}
          </div>

        </div>
      </div>
    </VideoProvider>
  );
}

export default Main;