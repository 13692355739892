import axios from 'axios';
import { SERVER } from '../environment';

export const setGrid = async (gridArr = [
  {
    grid: "다마637583",
    long: 2.333,
    lat: 3.321
  },
  {
    grid: "다마637584",
    long: 2.223,
    lat: 4.11
  },
  ]) => {
  const data = {
    grids: gridArr
  }
  console.log(data);
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/setgrid/`, data);
    console.log(response)
    if (response.data.message === 'Success') {
      return true;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const setSafety = async (metadata, file) => {
  const delResult = await delSafety(metadata.addr, metadata.type);
  const formData = new FormData();
  formData.append('type', metadata.type);
  formData.append('prea', metadata.prea);
  formData.append('embed', metadata.embed);
  formData.append('file', file);
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/setsafetygeo/`, formData);
    console.log(response)
    return response.data.message;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const delSafety = async (addr, type) => {
  const data = {
    safety_area: ['전주시 완산구', '전주시 덕진구'].includes(addr) ? addr.slice(-3) : addr,
    type: type,
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/delsafety/`, data);
    console.log(response);
    return response.data.message;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}


export const setSelect = async (selectedArr = [
  {
      "no": "11111114",
      "get_date": "2023-10-15",
      "start_date": "2023-10-15",
      "end_date": "2023-11-15",
      "start_time": "00",
      "end_time": "00",
      "addr": "",
      "road": "전라북도 전주시 완산구 강변로 180",
      "request": "야간시간대 순찰강화",
      "reason": "기타"
  }
]) => {
  const data = {
    type: 'flex',
    data: selectedArr
  }
  console.log(data);
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/fxpathsave/`, data);
    console.log(response)
    if (response.data.message === 'success') {
      return true;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const delSelect = async (no) => {
  const data = {
    command: "flexdelete",
    no: no,
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/fxpathdel/`, data);
    console.log(response);
    return response
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const setLocal = async (selectedArr = [
  {
    "event_id" : "<ID>",   // blank이면신규, 아니면 수정
    "get_date": "2023-07-30",
    "start_date": "2023-08-01",
    "end_date": "2023-09-02",
    "addr1": "전주", // 시군
    "category": "대표축제", // 구분
    "title":"전주비빔밥축제", // 축제명
    "place": "한옥마을 일원", // 장소
    "road": "전주시 완산구 태조로 44", // 주소
    "reason": "경기전" //비고
  }
]) => {
  const data = {
    type: 'region',
    data: selectedArr
  }
  console.log(data);
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/regionsave/`, data);
    console.log(response)
    return response.data.message;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const delLocal = async (id) => {
  const data = {
    delete_target: id
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/regiondel/`, data);
    return response.data.message;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}


export const setNews = async (type, newsData) => {
  const formData = new FormData();
  formData.append("category", type);
  formData.append("id", newsData.id || '');
  formData.append("created_at", newsData.created_at);
  formData.append("close_at", newsData.close_at);
  formData.append("title", newsData.title);
  formData.append("contents", newsData.contents);
  formData.append("belong1", newsData.belong1);
  formData.append("belong2", newsData.belong2);
  formData.append("patrol", newsData.patrol);
  if (newsData.attach_files) {
    for (let i = 0; i < newsData.attach_files.length; i++) { 
      formData.append("attach_files", newsData.attach_files[i]);
    }
  } else {
    formData.append("attach_files", []);
  }
  if (newsData.delete_files) {
    for (let i = 0; i < newsData.delete_files.length; i++) { 
      formData.append("delete_files", newsData.delete_files[i]);
    }
  } else {
    formData.append("delete_files", []);
  }
  formData.append("type", newsData.type);
  console.log(newsData)
  for (let key of formData.entries()) {
    console.log(key);
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/newssave/`, formData);
    return response.data.news_id;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getNews = async (target, category, addr) => {
  const data = {
    target: target,
    category: category,
    belong1: addr.belong1,
    belong2: addr.belong2 === '전체' ? '' : addr.belong2,
    patrol: addr.belong3 === '전체' ? '' : addr.belong3
  }
  if (window.location.origin === 'http://localhost:3000') {
    if (category === 'news') {
      return [{
        id: "1",
        created_at: "2023-10-17",
        close_at: "2023-10-17",
        title: "title1",
        constents: "content1",
        files: "{'files': ['BAND_512.png'], 'path': 'https://kr.object.ncloudstorage.com/spatrol-test/files/2023/12/20/police001/'}",
        attached: 1,
      },
      {
        id: "3",
        created_at: "2023-10-24",
        close_at: "2023-10-30",
        title: "title3",
        constents: "content3",
        files: "{'files': [], 'path': ''}",
        attached: 0,
      },]
    } else {
      return [{
        id: "1",
        created_at: "2023-10-17",
        close_at: "2023-10-17",
        title: "title1",
        contents: "https://www.naver.com",
        files: "{'files': ['BAND_512.png'], 'path': 'https://kr.object.ncloudstorage.com/spatrol-test/files/2023/12/20/police001/'}",
        attached: 1,
      },
      {
        id: "2",
        created_at: "2023-10-17",
        close_at: "2023-10-17",
        title: "title2",
        contents: "https://www.naver.com",
        files: "{'files': ['BAND_512.png'], 'path': 'https://kr.object.ncloudstorage.com/spatrol-test/files/2023/12/20/police001/'}",
        attached: 1,
      },
      {
        id: "3",
        created_at: "2023-10-24",
        close_at: "2023-10-30",
        title: "title3",
        contents: "https://www.naver.com",
        files: "{'files': [], 'path': ''}",
        attached: 0,
      },]
    }
  } else {
    try {
      const response = await axios.post(`${SERVER}/patrolmain/api/newsview/`, data);
      if (response.data.message !== "fail") {
        console.log(response.data)
        return response.data.data;
      } else {
        return false;
      }
    } catch(error) {
      console.error('Error:', error)
      return false;
    }
  }
}

export const delNews = async (target) => {
  const data = {
    id: target,
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/newsdelete/`, data);
    if (response.data.message === "success") {
      return true;
    } else {
      return false;
    }
  } catch(error) {
    console.error('Error:', error)
    return false;
  }
}

export const pushNews = async (pushData) => {
  const data = {
    "title": pushData.title,
    "body" : pushData.contents,
    "channel_list": []
  }
  data.channel_list.push(pushData.channel);
  if (window.location.origin === 'http://localhost:3000') {
    console.log(data);
    return true;
  } else {
    try {
      const response = await axios.post(`${SERVER}/patrolmain/api/apppush/`, data);
      const replacedRes = response.data.message.replace(/'/g, '"');
      const pasedRes = JSON.parse(replacedRes);
      if (pasedRes.success) {
        return true;
      } else {
        return false;
      }
    } catch(error) {
      console.error('Error:', error)
      return false;
    }
  }
}

export const videoSave = async (videoData) => {
  const data = {
    video_id: videoData.videoId,
    title: videoData.title,
    src: videoData.src,
    tutor: videoData.tutor,
    desc: videoData.desc,
    length: videoData.length,
    start_date: videoData.startDate,
    end_date: videoData.endDate,
    thumbnail: videoData.thumbnail ?? '',
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/videosave/`, data);
    console.log(response)
    if (response.data.message === 'success') {
      return true;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const videoRemove = async (id) => {
  const data = {
    video_id: id
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/videoremove/`, data);
    console.log(response);
    if (response.message && response.message === 'success') return true;
    else return response;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}