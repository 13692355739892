import React, { useState, useEffect } from 'react';
import utils from '../../common/Utils';
import { useUser } from '../../../contexts/UserContext';

const safetyMapImgs = utils.getSafetyMapImg();
const activeSafetyMapImgs = utils.getActiveSafetyMapImg();
const colors = utils.getSafetyMapColors();
const borders = utils.getSafetyMapBorders();

function SafetyOptions({safetymapOption, onOptionClick}) {
  const {userAuth} = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [imgs, setImgs] = useState([]);
  const [activeImgs, setActiveImgs] = useState([]);
  const [colorArr, setColorArr] = useState([]);

  const handleMenuOpenClick = (e) => {
    if (!e.target.classList.contains('optionDetail')) {
      setIsOpen(!isOpen);
    }
  }
  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setCurrentY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    const deltaY = currentY - startY;
    if (deltaY > 50) {
      setIsOpen(true); // 끌어내린 경우
    } else if (deltaY < -50) {
      setIsOpen(false); // 끌어올린 경우
    }
  };

  useEffect(() => {
    if ([1, 3, 4].includes(userAuth)) {
      setActiveImgs(activeSafetyMapImgs);
      setImgs(safetyMapImgs);
      setColorArr(colors);
    } else {
      setActiveImgs(activeSafetyMapImgs.slice(1,));
      setImgs(safetyMapImgs.slice(1,));
      setColorArr(colors.slice(1,));
    }
  }, [userAuth])

  return (
    <div className="flex flex-col flex-grow justify-center bg-indigo08 -mt-3">
      <span className="flex justify-center">
      {imgs.map((marker, idx) => {
        return (
          <div onClick={() => onOptionClick(marker.name)} key={marker.name} className={`marker ${safetymapOption[marker.name] ? colorArr[idx]: 'bg-white'} border-2 border-transparent rounded-full m-1 p-1 flex flex-col justify-center items-center z-50`}>
            <img alt={marker.name} src={safetymapOption[marker.name] ? marker.img: activeImgs[idx].img} style={{width: '20px', height: '20px'}}/>
          </div>)
      })}
      </span>
      <div className={`marker bg-indigo01 hover:bg-white flex flex-col justify-center items-center z-50 `}
        onClick={handleMenuOpenClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {isOpen && (
          <div className={`optionDetail w-full bg-white p-2 flex-grow transition-transform ease-in-out delay-500 transform origin-top`}>
            {imgs.map((marker, idx) => {
              return (
                <div key={marker.name} className={`marker max-w-xs ${colorArr[idx]} rounded-full my-1 mx-auto p-2 flex gap-2 items-center z-50`}>
                  <img alt={marker.name} src={marker.img} style={{width: '20px', height: '20px'}}/>
                  <p className='font-bold text-white'>{marker.name}</p>
                </div>)
            })}
            <p className="hidden text-left text-gray-900">현재 전라북도 김제시, 부안군, 임실군, 전주시의 데이터만 볼 수 있습니다. </p>
          </div>
        )}
        <img alt="expand" src={utils.open} style={{width: '20px', height: '20px'}} className={isOpen ? 'rotate-90' : '-rotate-90'}/>
      </div>
    </div>
  );
}

export default SafetyOptions;
