import React from 'react';
import utils from '../common/Utils';
import { isDisabled } from '@testing-library/user-event/dist/utils';

const buttonClasses = {
  custom: 'hover:bg-indigo08 border-b-indigo08 hover:border-indigo08',
  success: 'hover:bg-green-800 border-b-green-600 hover:border-green-800',
  error: 'hover:bg-red-700 border-b-red-600 hover:border-red-700',
  warning: 'hover:bg-amber-500 border-b-amber-300 hover:border-amber-500',
  info: 'hover:bg-blue-800 border-b-blue-600 hover:border-blue-800',
};
const borderButtonClasses = {
  custom: 'hover:bg-indigo08 border-indigo08 text-indigo08 hover:border-indigo08',
  success: 'hover:bg-green-800 border-green-600 text-green-800 hover:border-green-800',
  error: 'hover:bg-red-700 border-red-600 text-red-700 hover:border-red-700',
  warning: 'hover:bg-amber-500 border-amber-300 text-amber-500 hover:border-amber-500',
  info: 'hover:bg-blue-800 border-blue-600 text-blue-800 hover:border-blue-800',
};
const coloredButtonClasses = {
  custom: 'bg-indigo08 border-indigo08 text-white hover:bg-indigo06',
  success: 'bg-green-800 border-green-800',
  error: 'bg-red-700 border-red-700',
  warning: 'bg-amber-500 border-amber-500',
  info: 'bg-blue-800 border-blue-800',
  gray: 'bg-gray-100 border-gray-100 hover:bg-indigo08 hover:border-indigo08 hover:text-white',
};

export function Button({type, text, click, disabled}) {
  return (
    <button className={`px-2 py-1 border-4 border-transparent rounded ${buttonClasses[type]} hover:text-white disabled:text-gray-800 disabled:grayscale disabled:cursor-not-allowed disabled:bg-gray-100`}
        disabled={disabled !== undefined ? disabled : '' }
        onClick={click}>{text}</button>
  );
}

export function BorderedButton({type, isSubmit, text, click, disabled, classname}) {
  return (
    <button className={`px-3 py-2 border rounded ${borderButtonClasses[type]} font-bold hover:text-white disabled:text-gray-800 disabled:grayscale disabled:cursor-not-allowed disabled:bg-gray-100 ${classname} `}
        type={isSubmit ? 'submit' : 'button'}
        disabled={disabled !== undefined ? disabled : '' }
        onClick={click}>{text}</button>
  );
}

export function ColoredButton({type, isSubmit, image, text, click, disabled}) {
  return (
    <button className={`w-full px-2 py-1 border-4 border-transparent flex justify-center font-bold rounded ${coloredButtonClasses[type]} disabled:text-gray-800 disabled:grayscale disabled:cursor-not-allowed disabled:bg-gray-200`}
        type={isSubmit ? 'submit' : 'button'}
        disabled={disabled}
        onClick={click}>
        {image && <img src={utils[image]} alt={image}/>}
        {text}
    </button>
  );
}
