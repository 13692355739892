import { useEffect } from 'react';
import { Container as 
  MapDiv, NaverMap, useNavermaps,
  Overlay, Listener, useListener,
  Marker, Polyline, Polygon,
  } from 'react-naver-maps';

const Route = ({data}) => {
  
  if (data && data.path) {
    return (
      <Polyline
        strokeColor= "#000000"
        strokeOpacity="0.5"
        strokeWeight="6"
        path={data ? data.path :[]}
      >
      </Polyline>
    )
  }
}
export default Route