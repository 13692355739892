import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  }
  return (
    <div className="flex-grow bg-white h-screen flex flex-col justify-start items-start p-20">
      <h2 className="text-2xl font-bold mt-20 mb-10">404 ERROR</h2>
      <p>이 페이지는 존재하지 않습니다</p>
      <p>주소를 다시 확인해주세요</p>
      <button onClick={goHome} className="border rounded px-2 py-1 my-5 bg-indigo01 hover:bg-white hover:border-indigo08 hover:border-4">홈페이지로 돌아가기</button>
    </div>
  );
}

export default PageNotFound;