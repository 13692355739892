import React, {useState, useEffect} from 'react';
import { useUser } from '../../../contexts/UserContext';
import lectures from '../../../assets/data/lectures.json';
import '../../../styles/chart.css';
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getVideoData } from '../../../services/mapMain';

ChartJS.register(ArcElement);

function EduProgress(props) {
  const {user} = useUser();
  const [completed, setCompleted] = useState(0)
  const [total, setTotal] = useState(0)
  const getCompleted = async () => {
    const countCompleted = await user.lectures.reduce((count, lecture) => {
      if (lecture.state === 2) {
        return count + 1;
      }
      return count;
    }, 0);
    setCompleted(countCompleted);
  }

  const getTotalVideo = async () => {
    const res = await getVideoData();
    if (res) setTotal(res.length);
    else setTotal(lectures.length);
  }

  const rate = Math.ceil((completed/total)*100);
  const doughnutData = {
    datasets: [
      {
        data: [(100-rate), rate],
        backgroundColor: [
          '#DEE2E6',
          '#4263EB',
        ],
        borderWidth: 0,
        reponsive: false,
      },
    ],
  };
  const options = {
    centerText: rate + '%',
    cutout: '80%',
  };
  const plugins = [{
    beforeDraw: function(chart) {
      const chartArea = chart.chartArea;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const text = chart.config.options.centerText;
      const ctx = chart.ctx;
      ctx.restore();
      ctx.font = '32px Pretendard'
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, centerX, centerY);
      ctx.save();
    } 
  }]

  useEffect(() => {
    if (user.lectures && user.lectures.length) {
      getTotalVideo();
      getCompleted();
    };
  }, [user])

  return (
      <div className='text-black relative m-auto p-2'>
        <Doughnut
          data={doughnutData}
          options={options}
          plugins={plugins}
          className="w-3/4 m-auto"
        />
      </div>

  )
}

export default EduProgress;