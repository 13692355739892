import React, { useEffect, useState, useRef } from 'react';
import { Container as 
  MapDiv, NaverMap, useNavermaps,Marker, Polyline, Polygon,
  } from 'react-naver-maps';
import {memoImg, filledLogoImg} from '../../common/Utils'
import Route from './Route';
import Overlay from '../Overlay';
import { useUser } from '../../../contexts/UserContext';
import { usePatrolMemo } from '../../../contexts/PatrolMemoContext';
import { getSelect } from '../../../services/map';
import Loader from '../Loader';

const colors = ['#BB1A1A', '#e51f1f', '#F26234', '#f2a134','#F2C734', '#f7e379', '#EDF779', '#bbdb44', '#7DCE1B', '#287710'];

const GridOnZoom = ({map, layer, markers, way, routes, lineArr, riskLevel, isPoint, isProcessing, pointHandler, gridHandler, isChanged}) => {
  const [clickedGrids, setClickedGrids] = useState([]);
  const [patrolRoute, setPatrolRoute] = useState([]);
  const [flexData, setFlexData] = useState(null);
  const [targetFlexData, setTargetFlexData] = useState({});
  const [localData, setLocalData] = useState([]);
  const [memo, setMemo] = useState([]);
  const [targetMemo, setTargetMemo] = useState({});
  const [targetFlex, setTargetFlex] = useState({});
  const navermaps = useNavermaps();
  const {user} = useUser();
  const {patrolMemo} = usePatrolMemo();

  const onPolygonClick = (latlang, grid) => {
    if (clickedGrids.includes(grid.grid)) {
      setClickedGrids(clickedGrids.filter(x => x !== grid.grid))
    } else {
      if (clickedGrids.length === 15) {
        return alert('경로는 최대 15개까지 설정하실 수 있습니다.');
      }
      setClickedGrids([...clickedGrids, grid.grid])

    }
    if (isPoint) pointHandler(latlang, grid.grid)
    else gridHandler(grid)
  }

  const memoClicked = (item) => {
    console.log(item);
    setTargetMemo(item);
  }

  const goMemoLocation = (location) => {
    console.log(location);
  }

  const flexClicked = (item) => {
    console.log(item)
    setTargetFlex(item);
  }

  useEffect(() => {
    if (isPoint) setClickedGrids([])
    else if (routes && routes.length) {
      setClickedGrids(routes.map(route => route.grid))
    }
  }, [isPoint, routes])

  useEffect(() => {
      const getFlexData = async () => {
        const getRange = () => {
          const sw = map.getBounds().getSW();
          const ne = map.getBounds().getNE();
          const bound = {
            sw: {
              lat: sw.y,
              long: sw.x
            },
            ne: {
              lat: ne.y,
              long: ne.x
            }
          }
          return bound;
        }
        // const range = await getRange();
        // const response = await getSelect(range, user.patrol.name);
        const response = await getSelect(user.belong2, user.patrol.name);
        // console.log(response.data.data);
        setFlexData(response.data.data);
      }
    if (layer === 'route') {

      getFlexData();
      // setClickedGrids(markers);
    }
  }, [layer, map])

  useEffect(() => {
    setPatrolRoute(way)
  }, [way])

  useEffect(() => {
    setMemo(patrolMemo.filter(memo => {
      if (memo.patrol === user.patrol.name || memo.public === 1) {
        return true;
      }
      return false;
    }))

  }, [patrolMemo])

  if (layer === 'grid' && lineArr && lineArr.length === 0) return null;

  return(
    <div>
      {lineArr.map((line) => {
        return (
          <span
            key={line.grid+'wrapper'}
          >
            {['grid'].includes(layer) && map.getZoom() > 15 ? (
              <Polygon
                fillColor={map.getZoom() > 15 && riskLevel >= line.crime_val ? colors[line.crime_val-1] : 'transparent'}
                fillOpacity={0.35}
                strokeColor= {clickedGrids.includes(line.grid) ? '#3818D9' : '#FF0000'} //선의 색 빨강 
                strokeOpacity={clickedGrids.includes(line.grid) ? '1' : '0.4'} //선의 투명도
                strokeWeight={clickedGrids.includes(line.grid) ? '4' : '1'} //선의 두께
                clickable={true}
                onClick={map.getZoom() > 15 && isProcessing === 1 && riskLevel >= line.crime_val ? (e) => onPolygonClick([e.coord.y, e.coord.x], line) : ''}
                paths={[
                  [
                    new navermaps.LatLng(line.latLngArr[0][0], line.latLngArr[0][1]),
                    new navermaps.LatLng(line.latLngArr[1][0], line.latLngArr[1][1]),
                    new navermaps.LatLng(line.latLngArr[2][0], line.latLngArr[2][1]),
                    new navermaps.LatLng(line.latLngArr[3][0], line.latLngArr[3][1]),
                  ]
                ]}
              >
              </Polygon>
            ):''}
            {layer === 'grid' && map.getZoom() > 16 ? (
              <Marker
                id={line.id}
                position={new navermaps.LatLng(line.lat, line.long)}
                onClick={isProcessing === 1 && !isPoint ? (e) => onPolygonClick([e.coord.y, e.coord.x], line) : ''}
                icon = {clickedGrids.includes(line.grid) ? {content: `<div class="bg-white -mt-8 -ml-3 px-2 rounded-xl border-2 border-indigo08">${clickedGrids.findIndex(x => x === line.grid) + 1}</div>`}
                  : ''}
              />
            ): ''}
            {layer === 'route' ? (
              <Marker
                id={line.id}
                position={new navermaps.LatLng(line.lat, line.long)}
                icon = {{content: `<div class="w-8 h-8 rounded-full" style="background-color: ${riskLevel >= line.crime_val ? colors[line.crime_val-1] : 'transparent'}; opacity: 0.7;"></div>`}}
              />
            ): ''}
          </span>
        )
      })}
      {layer === 'route' && patrolRoute && markers ? (
        <Route data={patrolRoute} />
        ): ''}
      {['route', 'grid'].includes(layer) && patrolMemo ? (
        memo.map(item => {
          return <Marker
            key={item.id}
            position={new navermaps.LatLng(item.location.lat, item.location.long)}
            onClick={() => memoClicked(item)}
            icon = {{content: `<div class="w-12 rounded-full">
            <img src=${memoImg} alt="memo" class="w-full" /></div>`}}
          />
        })
      ): null}
      {layer === 'route' && flexData && flexData.length > 0 ? (
        flexData.map(item => {
          return <Marker
          key={item.no}
          position={new navermaps.LatLng(item.lat, item.long)}
          onClick={() => flexClicked(item)}
          icon = {{content: `<div class="w-8 rounded-full">
          <img src=${filledLogoImg} alt="filled logo" class="w-full" /></div>`}}
        />
        })
      ) : null}
      {['route', 'grid'].includes(layer) && targetMemo.title ? <Overlay type="patrol_memo_detail" isOpen={targetMemo.title} onClose={() => setTargetMemo({})} contents={targetMemo.id} title={targetMemo.title} onMemoClick={goMemoLocation} /> : ''}
      {layer === 'route' && targetFlex.no ? <Overlay type="flex_detail" isOpen={targetFlex.no} onClose={() => setTargetFlex({})} contents={targetFlex} title={targetFlex.reason} /> : ''}
      {/* {layer === 'route' && targetFlexData.no ? <Overlay type="flexdata" isOpen={targetFlexData.request} onClose={() => setTargetFlexData({})} contents={targetFlexData} title={targetMemo.title} /> : ''} */}
    </div>
  )
}

export default GridOnZoom;