import { createContext, useContext, useState } from 'react';
import exportedObject from '../components/common/Utils';
import sample from '../assets/images/smaple.png';

const PatrolMemoContext = createContext();
const patrolMemoSample = [
  {
    id: 'memo1',
    title: '우림교 밑 순찰 주의 필요',
    author: '김훈',
    patrol: '덕진1대',
    date: new Date('2023-11-25'),
    contents: '우림교 밑에 무리지어 있는 사람들 때문에 통행이 불편하다고 합니다',
    address: '우림교',
    type: '범죄위험',
    public: 'public',
    files: [sample]
  },
  {
    id: 'memo2',
    title: '두 명이 킥보드를 타는 모습을 보면 멈춰세워주세요',
    author: '이일환',
    patrol: '효자3동',
    date: new Date('2023-12-31'),
    contents: '이런 모습을 보고도 아무 조치도 취하지 않는 모습이 자주 눈에 띕니다 누가 봐도 위험한 상황이니만큼 직접 나서주세요',
    address: '',
    type: '인적요인',
    public: 'public',
    files: []
  },
  {
    id: 'memo3',
    title: '전동성당 주위 순찰',
    author: '김한일',
    patrol: '풍남동',
    date: new Date('2023-10-31'),
    contents: '가을이라 외지인의 방문이 부쩍 늘었습니다 아무래도 관광지 근처에 사람이 많이 모여 위험한 상황이 있을 수 있으므로 근처 순찰시 주의가 필요합니다',
    address: '',
    type: '범죄위험',
    public: 'public',
  },
  {
    id: 'memo4',
    title: '상산고등학교에서 28일에 국가시험이 있다고 합니다',
    author: '김유성',
    patrol: '효자3동',
    date: new Date('2023-10-28'),
    contents: '아침 저녁으로 통행이 늘어날 예정이니 순찰시 주의를 기울여주세요 모두 화이팅!',
    address: '상산고등학교',
    location: {lat: 35.804863, long: 127.116787},
    type: '인적요인',
    public: 'private'
  },
  {
    id: 'memo5',
    title: '천변을 산책하는 사람들이 많아졌어요',
    author: '조상희',
    patrol: '덕진1대',
    date: new Date('2023-11-15'),
    contents: '날씨가 좋다고 밤에도 산책하는 사람들이 많아졌습니다 아무래도 가로등이 안 닿는 곳도 있으니 그런 곳들 위주로 살펴주세요',
    address: '',
    type: '범죄위험',
    public: 'public'
  },
];

export function PatrolMemoProvider({ children }) {
  const [patrolMemo, setPatrolMemo] = useState([]);

  const addPatrolMemo = (newItem) => {
    console.log(newItem)
    setPatrolMemo((prevData) => [...prevData, newItem]);
  };
  return (
    <PatrolMemoContext.Provider value={{ patrolMemo, setPatrolMemo, addPatrolMemo }}>
      {children}
    </PatrolMemoContext.Provider>
  );
};

export function usePatrolMemo() {
  return useContext(PatrolMemoContext);
}