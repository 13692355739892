import React, {useState, useEffect} from 'react';
import {Button, BorderedButton, ColoredButton} from '../common/Button';
import { logoImg } from '../common/Utils';
import '../../styles/utils.css';
import { SERVER } from '../../environment';
import { getCSRF } from '../../services/auth';
import CSRFToken, {getCookie} from '../common/CSRF';

const errorMsgs = {
  // 'pw': '비밀번호는 영어 대문자, 소문자, 특수문자($,@,#,%,.), 숫자의 조합으로 만들어주세요',
  'pw2': '비밀번호가 일치하지 않습니다',
}
function PasswordChange(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [errorList, setErrorList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (e) => {
    let regex;
    switch (e.target.name) {
      case 'oldpassword':
        setOldPassword(e.target.value.trim());
        break;
      case 'password1':
        setPassword1(e.target.value.trim());
        // regex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[$@#%.])(?=.*\d).{8,}/;
        // const regexRes = regex.test(e.target.value);
        // if (regexRes && errorList.includes('pw')) {
        //   setErrorList(errorList.filter(item => item !== 'pw'))
        // } else if (regexRes === false && !errorList.includes('pw')) {
        //   setErrorList([...errorList, 'pw'])
        // }
        if (errorList.includes('pw2')) {
          if (e.target.value === password2) setErrorList(errorList.filter(item => item !== 'pw2'));
        }
        break;
      case 'password2':
        setPassword2(e.target.value.trim());
        if (errorList.includes('pw2')) {
          if (password1 === e.target.value) setErrorList(errorList.filter(item => item !== 'pw2'));
        } else if (!errorList.includes('pw2') && password1 !== e.target.value) {
          setErrorList([...errorList, 'pw2']);
        }
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    form.submit();
  }

  useEffect(() => {
    if (!errorList.length && oldPassword && password1 && password2) {
        setIsDisabled(false);
    } else setIsDisabled(true);
  }, [oldPassword, password1, password2]);
  return (
    <form className="pt-10 px-2 mx-auto w-full max-w-screen-md pb-20 overflow-auto" method="POST" action="/accounts/password/change/" onSubmit={handleSubmit}>
      <CSRFToken />
      <span className="flex justify-center items-center gap-2">
        <img src={logoImg} alt="logo" className="w-12"/>
        <h1 className="text-left rounded-md text-3xl font-bold my-2">비밀번호 변경</h1>
      </span>
      <div className="flex flex-col gap-3 text-left justify-center content-center my-9">
        <div className="">
          <p className="font-bold">현재 비밀번호</p>
          <input name="oldpassword" value={oldPassword} onChange={handleInputChange} type="password" className="w-full text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="현재 비밀번호" />
        </div>
        <div className="">
          <p className="font-bold">새 비밀번호</p>
          <input name="password1" value={password1} onChange={handleInputChange} type="password" className="w-full text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="새 비밀번호" />
          <p className={`${errorList.includes('pw') ? 'font-bold' : ''} text-gray-600 text-sm text-left `}>{errorMsgs['pw']}</p>
        </div>
        <div className="">
          <p className="font-bold">새 비밀번호 확인</p>
          <input name="password2" value={password2} onChange={handleInputChange} type="password" className="w-full text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="새 비밀번호" />
          {errorList.includes('pw2') ? <p className={`text-gray-600 text-sm text-left`}>{errorMsgs['pw2']}</p> : ''}
        </div>
      </div>
      {errorList.length ? <p>제출할 내용을 다시 확인해주세요</p> : ''}
      <ColoredButton type={'custom'} isSubmit={true} text={'비밀번호 변경'} disabled={isDisabled}/>
      <BorderedButton classname={'w-full mt-3 py-2'} isSubmit={false} type={'custom'} text={'취소'} click={props.cancel} />
    </form>
  );
}

export default PasswordChange;