import axios from 'axios';
import { SERVER } from '../environment';
import { getCookie } from '../components/common/CSRF';


export const getSafetyMapData = async (type, sw, ne) => {
  axios.defaults.xsrfCookieName = 'csrftoken'
  axios.defaults.xsrfHeaderName = "X-CSRFToken"
  axios.defaults.headers.common['X-CSRFToken'] = getCookie("csrftoken");
  const data = {
    type: type,
    sw: sw,
    ne: ne
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/getsafetygeo/`, data);
    if (response.data.message === 'success') {
      const jsonData = JSON.parse(response.data.result.replace(/'/g, '"'));
      return jsonData;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const setPath = async (area, risk, location, date="2022-04-01") => {
  const data = {
    patrol: location,
    area: area,
    crime_val: risk,
    date: date
  }
  console.log('setPath', data);
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/getautopath/`, data);
    if (response.data.message === 'success') {
      const routeData = JSON.parse(response.data.route.replace(/'/g, '"'));
      const pathData = JSON.parse(response.data.way15.replace(/'/g, '"'));

      return {route: routeData, path: pathData};
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getReport = async (id = 'all') => {
  const today = new Date();
  let MonthAfterToday = new Date(new Date().setDate(today.getDate() + 30));
  let WeekAfterToday = new Date(new Date().setDate(today.getDate() + 7));
  const data = [
    {
      id: 'report1',
      created_date: new Date('2023-10-23'),
      title: '집 앞 공원에서 밤 늦은 시간에 너무 시끄러워요',
      author: '장필순',
      end_date: MonthAfterToday,
      contents: '원래도 안 그랬던 건 아니지만 요즘은 정말 심각하게 시끄러워요. 다 늦은 시간에 대체 뭐하는지 모르겠지만 직접 나가서 뭘 할 수는 없고 순찰을 좀 자주 돌아주실 수 있나요? 정말 잠을 잘 수 없을 정도로 시끄럽거든요..',
      files: [],
      type: '범죄위험',
      location: '나무향 어린이공원',
      publicity: true,
      password: '',
      status: 0,
    },
    {
      id: 'report2',
      created_date: new Date('2023-10-22'),
      title: '층간소음 때문에 미쳐버리겠습니다',
      author: '김안수',
      end_date: new Date('2023-10-30'),
      contents: '직접 찾아가면 제가 고소당할 수도 있다고 들었습니다. 윗집이랑은 말이 안 통합니다. 시끄러운 게 싫으면 단독주택에 살라는 식입니다. 경비실에 말해도 아무 소용이 없어요. 제발 경찰 분들이 어떻게 해결해주세요. 부탁드립니다.',
      files: [],
      type: '인적요인',
      location: '광진라미안 아파트 101동 802호',
      publicity: false,
      password: 'asdf1234',
      status: 1,
    },
    {
      id: 'report3',
      created_date: new Date('2023-10-22'),
      title: '자전거를 훔쳐갔습니다',
      author: '이미나',
      end_date: new Date('2023-11-5'),
      contents: '문학대공원에 갔다가 잠깐 화장실을 다녀온 사이에 화장실 앞에 자물쇠를 걸어놓았던 자전거가 사라졌습니다. 근처에 cctv가 있겠죠?',
      files: [],
      type: '범죄위험',
      location: '',
      publicity: true,
      password: '',
      status: 0,
    },
    {
      id: 'report4',
      created_date: new Date('2023-10-10'),
      title: '호남제일고 근처에 하교시간 이후로 순찰 자주 해주실 수 있을까요',
      author: '오한성',
      end_date: WeekAfterToday,
      contents: '밤에 운동장에서 교복 입은 학생들이 자주 보입니다 어떻게 들어간 건지 몰라도 앞으로 이러지 못하게 조치가 필요할 것 같습니다',
      files: [],
      type: '학교주변',
      location: '',
      publicity: true,
      password: '',
      status: 0,
    },
    {
      id: 'report5',
      created_date: new Date('2023-10-03'),
      title: '남부시장 쪽 골목에 가로등이 나가서 밤에 위험해요',
      author: '김모하',
      end_date: new Date('2023-10-30'),
      contents: '가로등 나간지 꽤 됐는데 여전히 안 고쳐졌네요 시청에 건의는 넣고 있으니까 고쳐지기 전에는 순찰 부탁드립니다',
      files: [],
      type: '범죄위험',
      location: '',
      publicity: true,
      password: '',
      status: 0,
    },
    {
      id: 'report6',
      created_date: new Date('2023-10-01'),
      title: '가게 근처 순찰 요청합니다!!',
      author: '한영신',
      end_date: new Date('2023-10-30'),
      contents: '가을이라고 외지분들이 많이 오시는데 좀 불안합니다 아무래도 범죄 위험도 높은 거 같고 당분간만이라도 순찰을 늘려주세요!',
      files: [],
      type: '범죄위험',
      location: '전동성당',
      publicity: true,
      password: '',
      status: 0,
    },
    {
      id: 'report7',
      created_date: new Date('2023-10-01'),
      title: '신호등 불빛이 잘 안 보이는데요',
      author: '하일환',
      end_date: MonthAfterToday,
      contents: '이런 것도 여기 말해도 되는지 모르겠네요 일단 글 남깁니다 효자 초등학교 앞 사거리 신호등 불이 약해 잘 보이지가 않네요 학생들도 많이 다니는 거리인데 너무 위험할 것 같아서요',
      files: [],
      type: '환경요인',
      location: '효자 초등학교',
      publicity: true,
      password: '',
      status: 0,
    },
    {
      id: 'report8',
      created_date: new Date('2023-09-10'),
      title: '학교 앞에 상인들이 너무 많네요',
      author: '성우리',
      end_date: WeekAfterToday,
      contents: '우전 초등학교 학생 학부모입니다. 개학한 지 얼마나 됐다고 정문 앞에 잡상인들이 너무 많아요. 아이들을 안심하고 학교에 보낼 수가 없습니다. 저사람들 중에 이상한 사람이 있으면 어떡하나요? 학교 앞에서 상행위든 광고행위든 못하게 해주세요.',
      files: [],
      type: '학교주변',
      location: '우전초등학교',
      publicity: true,
      password: '',
      status: 0,
    },
  ]
  if (id === 'all') {
    return data;
  } else {
    const filteredData = data.filter(data => data.id === id);
    return filteredData[0];
  }
}

export const addMemo = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/noticesave/`, data, config);
    if (response.data.message === 'success') {
      console.log(response);
      return true;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getMemo = async (category = 'all') => {
  const data = {
    category: category
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/noticelist/`, data);
    if (response.data.message === 'success') {
      response.data.data.map(item => item.files = JSON.parse(item.files.replace(/'/g, '"')))
      return response.data.data;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const delMemo = async (id) => {
  const data = {
    id: id
  }
  // return {message: 'success'}
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/noticedel/`, data);
    if (response.data.message === 'success') {
      console.log(response);
      return true;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getVideo = async (type='all') => {
  const data = {
    type: type
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/videolist/`, data);
    if (response.data.message === 'success') {
      return response.data.video_list;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const getVideoData = async () => {
  try {
    const response = await axios.get(`${SERVER}/patrolmain/api/eduinfoview/`);
    if (response.data.message === 'success') {
      return response.data.educates;
    } else return false;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const setVideoData = async (updatedData) => {
  if (updatedData && updatedData.hasOwnProperty('video_id')) {
    try {
      const response = await axios.post(`${SERVER}/patrolmain/api/eduinfosave/`, updatedData);
      console.log(response)
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  } else return false;
}

export const reportMemo = async (id, text) => {
  const data = {
    notice_id: id,
    reason: text
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/complainsave/`, data);
    console.log(response)
    return true;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}
