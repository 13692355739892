import React, { useEffect, useState, useRef } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps, Marker } from 'react-naver-maps';
import { isMobile } from 'react-device-detect';
import { useMapContext } from '../../contexts/MapContext';
import { useUser } from '../../contexts/UserContext';
import grsConverter from './GrsConverter';
import GridOnZoom from './mapOptions/GridOnZoom';
import Route from './mapOptions/Route';
import SafetyMapMarkers from './mapOptions/SafetyMapMarkers';
import Alert from './Alert';
import Loader from './Loader';
import { getGrid } from '../../services/map';
import utils from './Utils';

const colorRGB = [
  {r: 187, g: 25, b: 26},
  {r: 229, g: 31, b: 31},
  {r: 242, g: 98, b: 52},
  {r: 242, g: 161, b: 52},
  {r: 242, g: 199, b: 52},
  {r: 247, g: 227, b: 121},
  {r: 237, g: 247, b: 121},
  {r: 187, g: 219, b: 68},
  {r: 125, g: 206, b: 27},
  {r: 40, g: 119, b: 16}
]

const Center = ({curFlex, centers, gps}) => {
  const [markArr, setMarkArr] = useState([]);
  // const [mapContext, setMapContext] = useMapContext();
  const mapContext = useNavermaps();
  const [curCenter, setCurCenter] = useState();

  useEffect(() => {
    if (centers) {
      setMarkArr(centers)
    }
  }, [centers])

  useEffect(() => {
    setCurCenter(curFlex)
  }, [curFlex])

  if ((markArr && markArr[0]) || curCenter) {
    return (
      <>
        {markArr.map((latlng, index) => {
          return <Marker
            key={latlng.center.lat+index}
            position={new mapContext.LatLng(latlng.center.lat, latlng.center.lng)}
            icon={{
              content: `<div class="w-8 rounded-full bg-indigo08 p-1"><img src=${utils.filledLogo} width="100%" /></div>`
            }}
          />
        })}
        {curCenter ? <Marker
            key={curCenter.center.lat}
            position={new mapContext.LatLng(curCenter.center.lat, curCenter.center.lng)}
            icon={{
              content: `<div class="w-8 rounded-full outline outline-4 outline-whome p-1"><img src=${utils.star} width="100%" /></div>`
            }}
          /> : ''}
      </>
    )
  }
  if (gps) {
    return (
      <Marker 
        position={new mapContext.LatLng(gps.lat, gps.lng)}
        icon={{
          content: `<div class="w-8 rounded-full p-1"><img src=${utils.gps} width="100%"/></div>`
        }}
      />
    )
  }
}

function MyCustomControl({zoomLevel, onClick}) {
  return (
    <div
      className="absolute block top-0 left-0 text-left bg-indigo01 text-black font-bold"
      style={{margin: '69px 10px', width: '30px'}}
    >
      <div className=" border border-black border-t-0 flex justify-center items-center" style={{width: '100%', height: '30px'}}>
        <p className="m-0">{zoomLevel}</p>
      </div>
      <div className=" border border-black border-t-0 flex justify-center items-center" style={{width: '100%', height: '30px'}}>
        <img src={utils.target} alt="gps" onClick={onClick} width="25px" className="m-0 p-px"/>
      </div>
    </div>
  )
}


const Maps = ({layer, safetymapOption, optionClicked, date, isPoint, isProcessing, routes, onChanged, setChangedArray, onLongTap, target, way, setMapInfo, curFlex, centers, isChanged, setRange, memoLocation, onCenterChange}) => {
  const didMountRef = useRef(false);
  const prevCenters = useRef(centers);
  const {user, setUser} = useUser();
  const [map, setMap] = useState(null);
  const [init, setInit] = useState(false);
  const [cur, setCur] = useState({});
  const [zoomLevel, setZoomLevel] = useState(16); // 7-9 시도, 10-12 시군구
  const [data, setData] = useState(null);
  const [lineArr, setLineArr] = useState([]);
  const [modifiedArr, setModifiedArr] = useState([]);
  const [location, setLocation] = useState({lat: 35.806559, lng: 127.1103191}); // 전라북도경찰청 35.8242796, 127.1050755
  const [markers, setMarkers] = useState([]);
  const [riskLevel, setRiskLevel] = useState(5);
  const [loading, setLoading] = useState(false);
  const [bounds, setBounds] = useState(null);
  const {eventLocation, setEventLocation} = useMapContext();
  const mapContext = useNavermaps();

  const getLineArr = async (bool=true) => {
    if (!isProcessing && map) {
      try {
        await setLoading(true);
        const sw = map.getBounds().getSW();
        const ne = map.getBounds().getNE();
        if (!bool && ['grid', 'safetymap'].includes(layer)) {
          await onCurClicked();
        }
        if (['route'].includes(layer)) {
          if (!user.patrol.crimeVals.length) {
            const targetDate = utils.dateFormatter(new Date());
            const response = await getGrid(targetDate, null, '', user.patrol.name);
            setLineArr(response.grid);
            setUser(prevState => ({
              ...prevState,
              patrol: {
                ...prevState.patrol,
                crimeVals: response.grid,
                center: response.center
              }
            }))
            if (!bool) {
              setLocation({lat: response.center.lat, lng: response.center.long})
              map.setCenter(new mapContext.LatLng(response.center.lat, response.center.long))
            };
          } else {
            setLineArr(user.patrol.crimeVals);
            if (!bool) await map.setCenter(new mapContext.LatLng(user.patrol.center.lat, user.patrol.center.long));
          }
        } else {
          // await map.setCenter(new mapContext.LatLng(location.lat, location.lng));
          const result = await grsConverter.lineArray(
            date || utils.dateFormatter(new Date()),
            map.getZoom(),
            sw,
            ne
          );
          setLineArr(result)

        }
      } catch (error) {
        console.error('Error', error)
      } finally {
        setLoading(false);
      }
    } else return null
  }

  const riskChanged = async (level) => {
    if (level < 9) {
      setRiskLevel(level)
    }
  }

  const zoomChanged = async () => {
    setZoomLevel(map.getZoom());
    if (![10, 12, 13, 15, 16].includes(map.getZoom()) ) {
      getLineArr();
    }
    if (layer === 'route') setMapInfo({riskLevel, zoomLevel, bounds});
  }
  const boundsChanged = async () => {
    const newBounds = new mapContext.LatLngBounds(
      new mapContext.LatLng(map.getBounds().getSW()),
      new mapContext.LatLng(map.getBounds().getNE())
    )
    const range = {
      sw: {
        lat: newBounds['_sw'].y,
        long: newBounds['_sw'].x,
      },
      ne: {
        lat: newBounds['_ne'].y,
        long: newBounds['_ne'].x,
      }
    }
    if (setRange) setRange(range)
    if (onCenterChange !== undefined) onCenterChange(newBounds);
    setBounds(newBounds);
    if (map.getZoom() < 18 ) {
      getLineArr();
    }
    if (layer === 'route') setMapInfo({riskLevel, zoomLevel, bounds});
  }
  const handleUpdateMarker = (newCenter, cbc) => {
    const updatedLineArr = lineArr.map(line => {
      if (line.grid === cbc) {
        console.log(line)
        setModifiedArr([...modifiedArr, {...line, lat: newCenter[0], long: newCenter[1]}])
        return {
          ...line,
          lat: newCenter[0],
          long: newCenter[1]
        };
      }
      return line;
    });
    onChanged(true);
    setLineArr(updatedLineArr)
  }

  const handleClickedGrids = (grid) => {
    onChanged(true);
    setChangedArray(grid);
  }

  const onMapLongTap = async(e) => {
    const position = {lat: e.latlng.y, lng: e.latlng.x};
    await setEventLocation(position);
    onLongTap(e.coord);
  }

  useEffect(() => {
    const settingLine = async () => {
      getLineArr(false);
    }
    if (!didMountRef.current && map !== null) {
      settingLine();

      const newBounds = new mapContext.LatLngBounds(
        new mapContext.LatLng(map.getBounds().getSW()),
        new mapContext.LatLng(map.getBounds().getNE())
      )
      if (onCenterChange !== undefined) onCenterChange(newBounds);
      setBounds(newBounds)
      didMountRef.current = true;
    }
  }, [map]);

  useEffect(() => {
    if ((map&&layer)&&layer === 'route') setMapInfo({riskLevel, zoomLevel, bounds});
  },[riskLevel])

  useEffect(() => {
    if (isProcessing === 2) {
      setChangedArray(modifiedArr);
      setBounds(null);
    } else if (isProcessing === 0) {
      getLineArr();
      setBounds(null);
    }
  }, [isProcessing])

  useEffect(() => {
    const processData = async () => {
      setMarkers(target)
    }
    if (target) processData();
  }, [target])

  useEffect(() => {
    if (centers && centers.length && prevCenters.current.length < centers.length) {
      map.setCenter(new mapContext.LatLng(centers[centers.length-1].center.lat, centers[centers.length-1].center.lng));
    }
    prevCenters.current = centers;
  }, [centers])

  useEffect(() => {
    if(curFlex) {
      map.setCenter(new mapContext.LatLng(curFlex.center.lat, curFlex.center.lng));
    }
  }, [curFlex])

  useEffect(() => {
    getLineArr();
  }, [date])

  useEffect(() => {
    if (memoLocation) {
      map.setCenter(new mapContext.LatLng(memoLocation.lat, memoLocation.lng));
    }
  }, [memoLocation])

  const onCurClicked = async () => {
    setLoading(true);
    try {
      const currentPosition = await utils.getCur();
      setCur(currentPosition);
      map.setCenter(currentPosition);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }
  if (!didMountRef) return <Loader />
  return (
    <div className="flex-grow flex flex-col bg-white items-center">
      {layer !== 'safetymap' ? (
        <div className="w-full flex flex-col">
          {!isPoint && isProcessing ? (
            <ul className="py-2 px-4 text-left list-disc text-gray-600">
              <li>하단의 지도를 클릭하시면 경로에 해당 지역이 추가됩니다.</li>
              <li>선택된 지역을 다시 클릭하시면 경로에서 삭제됩니다.</li>
              <li>경로는 최대 15개까지 선택하실 수 있습니다.</li>
            </ul>
          ) : isPoint && isProcessing ? (
            <ul className="py-2 px-4 text-right list-none text-gray-600">
              <li>하단의 지도를 클릭하시면 해당 그리드의 마커 위치가 변경됩니다.</li>
              <li>위험도가 보이는 그리드의 마커 위치만 변경하실 수 있습니다.</li>
              <li>위험도는 하단의 숫자를 클릭해서 변경하실 수 있습니다.</li>
            </ul>
          ) : ''}
          {['setting', 'grid', 'route'].includes(layer) ? (
            <div
              className="flex"
              style={{height: '25px'}}
            >
              {colorRGB.map((color, idx) => {
                return (
                  <div
                    key={idx}
                    className={`${idx > 7 ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'} flex-1 border ${riskLevel === idx+1 ? 'border-black' : riskLevel > idx ? 'border-black border-r-0' : ''}`}
                    style={{backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${riskLevel>idx ? 0.8 : 0.35})`,
                            color: riskLevel > idx ? 'white' : 'black',
                            fontWeight: riskLevel > idx ? 'bold' : '',
                          }}
                    onClick={() => riskChanged(idx+1)}
                  >
                    <p style={{margin: '-1px'}}>{idx + 1}</p>
                  </div>
                )
              })}
            </div>
          ): ''}
        </div>
      ) : ''}
      <MapDiv style={{width:"100%", height: "90vh"}}>
        <NaverMap
          ref={setMap}
          id="react-naver-maps-introduction"
          style={{ borderTop: 'transparent', position: 'relative' }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
          minZoom={layer === 'safetymap' ? 15 : 9}
          maxZoom={['safetymap', 'route'].includes(layer) ? 21 : 18}
          onInit={() => {setInit(true)}}
          onIdle={boundsChanged}
          onZoomChanged={zoomChanged}
          onLongtap={layer === 'route' ? onMapLongTap : ''}
          onRightclick={layer === 'route' ? onMapLongTap : ''}
          maxBounds={isProcessing === 1 ? bounds : null}
          zoomControl={true}
          zoomControlOptions={{
              style: mapContext.ZoomControlStyle.SMALL,
              position: mapContext.Position.LEFT_TOP,
          }}
          >
          {loading ? <Loader /> : (<>

            { !['safetymap'].includes(layer) ? loading ? '' :
              <GridOnZoom map={map} data={data} layer={layer} markers={markers} way={way} routes={routes} lineArr={lineArr} riskLevel={riskLevel} isPoint={isPoint} isProcessing={isProcessing} pointHandler={handleUpdateMarker} gridHandler={handleClickedGrids} isChanged={isChanged}/> : ''}
            {centers && <Center centers={centers} /> }
            {curFlex && <Center curFlex={curFlex} /> }
            {cur.lat && <Center gps={cur} />}
            {/* <Route data={data} /> */}
            { init && ['safetymap', 'grid'].includes(layer) ? <SafetyMapMarkers bounds={bounds} zoomLevel={zoomLevel} safetymapOption={safetymapOption} optionClicked={optionClicked} /> : ''}
            {/* { init && ['safetymap'].includes(layer) ? <SafetyMapMarkers bounds={bounds} zoomLevel={zoomLevel} safetymapOption={safetymapOption} optionClicked={optionClicked} /> : ''} */}
            {init && <MyCustomControl zoomLevel={zoomLevel} onClick={onCurClicked} />}
          </>)}

        </NaverMap>
      </MapDiv>
    </div>
  );
};

export default Maps;