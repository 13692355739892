import React, { useEffect, useState, useRef } from 'react';
import { read, utils } from 'xlsx';
import uploadImg from '../../assets/images/cloud_upload.svg'
import { uploadFile } from '../../services';
import Loader from './Loader';
import Alert from './Alert';

function UploadBtn(props) {
  const [text, setText] = useState('');
  const [isError, setIsError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('파일이 업로드되어 처리 중입니다');
  const [isFail, setIsFail] = useState(false);
  const [failMsg, setFailMsg] = useState('에러가 발생했습니다.');
  const [isParsing, setIsParsing] = useState(false);
  const inputRef = useRef(null);
  const tableRows = ['grid_id', 'prdt_ymd', 'dd_all_crim_dgrsd_val'];
  useEffect(() => {
    setText(props.text);
    ['신규 탄력 순찰 업로드', '지역 특성 데이터 업로드'].includes(props.text) ? setIsParsing(true) : setIsParsing(false);
  }, [props.text]);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  }
  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;

    if (props.src && props.src === 'safetyUpload') {
      return props.onFileChange(fileObj);
    }

    if (isParsing) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(fileObj);
      fileReader.onload = (e) => {
        if (!e.target) return;
        const bufferArray = e.target.result;
        const fileInformation = read(bufferArray, {
          type: 'buffer',
          cellText: false,
          cellDates: true,
        });
        const sheetName = props.text === '지역특성 데이터 업로드' ? fileInformation.SheetNames[1] : fileInformation.SheetNames[0];
        const rawData = fileInformation.Sheets[sheetName];
        const decodedRange = utils.decode_range(rawData['!ref']);
        decodedRange.s['r'] = 2;
        rawData['!ref'] = utils.encode_range(decodedRange);
        const data = utils.sheet_to_json(rawData);
        // const data = utils.sheet_to_html(rawData);
        props.onComplete(data);
        // data && setUploadedFileData(mappingDataToTable(data as any[]));
      };
    } else {
      try {
        setIsError(true);
        const data = await uploadFile(fileObj, text.toLowerCase());
        console.log('data', data)
        //         - 정상 일 때 "message: "dbsaving" || complete
// - 파일 문제 일때 "message: "error-file"
// - 내용형식이 문제 일 때 "message: "error-form1"
// - 이미 처리중일 때(같은 파일이나 다른 파일을 업로드할 수 없음) "messae":"in_progress", "status": 진행률
// message 없으면 false
// 다른 경우는 그냥 에러 메세지 그대로 보여주기
        setIsError(false);
        if (['dbsaving', 'in_progress'].includes(data)) {
          setIsSuccess(true);
          // props.setIsChanged();
        } else {
          switch (data) {
            case 'error-file':
              setFailMsg('잘못된 파일입니다.');
              break;
            case 'error-form1':
              setFailMsg('잘못된 형식입니다.');
              break;
            default:
              break;
          }
          setIsFail(true);
        }
        props.setIsChanged();
        // setTimeout(() => {
        //   setIsError(false);
        //   setIsSuccess(true);
        //   props.setIsChanged();
        // }, 3000);
      } catch {
        // 오류 발생시 실행
      }
    }

  };
  return (
    <div className="col-span-6 md:col-span-1"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
    >
      {isError ? <Loader /> : ''}
      {isSuccess ? <Alert type={'success'} message={successMsg} onClose={() => setIsSuccess(false)}/> : ''}
      {isFail ? <Alert type={'error'} message={`업로드에 실패했습니다.\n ${failMsg}`} onClose={() => setIsFail(false)}/> : ''}
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        accept={isParsing || props.src ? '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' : '.zip, .rar, .7zip'}
        onChange={handleFileChange}
      />
      <button className="w-full bg-indigo01 hover:bg-indigo02 font-bold py-2 px-4 flex justify-center align-center rounded relative" onClick={handleClick}>
        <img src={uploadImg} alt="upload file" width={"26px"} className="mr-2"/>
        <p className="inline-block text-indigo08 leading-7">{text}</p>
        {!props.src && isHovered && (
          <div className="z-30 absolute left-full transform -translate-x-full -translate-y-full w-full bg-gray-800 text-white p-2 text-sm rounded shadow-md">
            {isParsing ? '엑셀파일을 올려주세요.' : '압축파일을 올려주세요.'}
          </div>
        )}
      </button>
    </div>
  );
}

export default UploadBtn;