import React, {useState, useEffect} from 'react';
import { addZero } from '../../common/Utils';

function MonthSelect(props) {
  const [selectedValue, setSelectedValue] = useState({year: props.selectedYear, month: props.selectedMonth});
  const [selectOptions, setSelectOptions] = useState([]);

  const setOptions = (months) => {
    const dateArray = [];
    if (months) {
      for (let i=0; i < months.length; i++) {
        dateArray.push({year: months[i].year, month: months[i].month});
      }
    } else {
    dateArray.push({year: props.selectedYear, month: props.selectedMonth});

    }
    dateArray.sort(function(a, b) {
      return a.month - b.month;
    });
    setSelectOptions(dateArray)
  }
  const handleSelectChange = async (event) => {
    const selectedValue = JSON.parse(event.target.value);
    await setSelectedValue(selectedValue);
    props.onSelectChange(selectedValue);
  };

  useEffect(() => {
    if (props.months) {
      // setOptions(precasDate);
      setOptions(props.months);
    } else {
      setOptions();
    }
  }, [props.months])
  // useEffect(() => {
  //   setOptions();
  // }, [])
  return (
    <div className="my-auto col-span-6 md:col-span-1">
      <select value={JSON.stringify(selectedValue)} onChange={handleSelectChange} className="py-2 px-6 border border-indigo08 rounded">
        {selectOptions.map((data, index) => (
          <option value={JSON.stringify(data)} key={'yearmonth'+index} className="text-indigo08">{data.year}.{addZero(data.month)}</option>
        ))}
      </select>
    </div>
  );
}

export default MonthSelect;