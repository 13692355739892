import React, {useState, useEffect} from "react";
import { useUser } from "../../../contexts/UserContext";
import utils from "../../common/Utils";
import Label from "../../common/Label";
import BorderLabel from '../../common/BorderLabel';

const stateUI = [
  {text: '학습 전', css: 'custom'},
  {text: '학습 중', css: 'custom'},
  {text: '학습완료', css: 'unset'}
]

function Lecture({lecture, wasPlayed}) {
  const {user} = useUser();
  const [targetState, setTargetState] = useState(0);
  
  useEffect(() => {

    if (wasPlayed.length) {
      setTargetState(wasPlayed[0].state);
    }
  }, [lecture])

  return (
    <div className="flex border-b p-2">
      <div className="w-1/3 flex justify-center items-center">
        <img alt={lecture.lecture_title + 'thumbnail'} src={utils[lecture.thumbnail] || utils.logoImg} width={'100px'}/>
      </div>
      <div className="w-2/3 p-2 flex flex-col justify-between">
        <div className="flex flex-wrap gap-1">
          <p className="text-xl font-bold text-left text-wrap line-clamp-2">{lecture.title}</p>
          {
            targetState === 1
            ? <BorderLabel type={stateUI[targetState].css} text={stateUI[targetState].text}/>
            : <Label type={stateUI[targetState].css} text={stateUI[targetState].text}/>
          }
        </div>
        <span className="flex justify-between items-center">
          <p className="text-sm ">{lecture.tutor} · {Math.ceil(lecture.length / 60)}분 {wasPlayed.length ? '중 ' + Math.ceil(wasPlayed[0].played_time / 60) + '분 수강' : ''}</p>
        </span>
      </div>

    </div>
  )
}

export default Lecture;