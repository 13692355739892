import proj4 from 'proj4';
import { getGrid, setGrid, direction15 } from '../../services/map';

export const w = {7: "가", 8: "나", 9: "다", 10: "라", 11: "마", 12: "바", 13: "사"};
export const h = {13: "가", 14: "나", 15: "다", 16: "라", 17: "마", 18: "바", 19: "사", 20: "아"};
const grs80 = "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs";
const wgs84 = "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";

/**
 * 위경도 좌표 => 국가지점번호
 * @param coord 위경도 좌표 ex) [number, number]
 * @returns {string, number, number} 국가지점번호 ex) ["가나", 1234, 1235]
 */
const convertToCbc = (coord) => {
  let grs80p = proj4(wgs84, grs80, coord);
  let wP = parseInt(grs80p[0].toString().split(".")[0]);
  let hP = parseInt(grs80p[1].toString().split(".")[0]);
  let code = [
    w[Math.floor(Math.floor(wP) / 100000)] + h[Math.floor(Math.floor(hP) / 100000)],
    Math.floor((wP % 100000) / 10),
    Math.floor((hP % 100000) / 10),
  ]
  return code;
}
/**
 * 국가지점번호 => 국가지점번호위경도 좌표
 * @param cbc 국가지점번호 ex) ["가나", 1234, 1235]
 * @returns {[number, number]} 위경도 좌표(lat, lng) ex) [number, number]
 */
const convertToCoord = (cbc) => {
  const cbcCode = cbc.split(" ");
  let lat, lng;
  // ["가나", 1234, 1235]의 가
  Object.keys(w).forEach((key) => {
    if (w[parseInt(key)] === cbcCode[0].charAt(0)) {
      lat = parseInt(key);
    }
  });
  // ["가나", 1234, 1235]의 나
  Object.keys(h).forEach((key) => {
    if (h[parseInt(key)] === cbcCode[0].charAt(1)) {
      lng = parseInt(key);
    }
  });
  if (lat === null || lng === null || cbcCode[1].length !== cbcCode[2].length) {
    return -1;
  } else {
    // marker의 중앙을 찾기 위한 로직
    const length = cbcCode[1].length + 1;
    lat = Math.pow(10, length) * lat + parseInt(cbcCode[1]) * Math.pow(10, 6 - length) + 5;
    lng = Math.pow(10, length) * lng + parseInt(cbcCode[2]) * Math.pow(10, 6 - length) + 5;
  }
  const wgs84P = proj4(grs80, wgs84, [lat, lng]);

  return wgs84P;
}

// grid 배열 생성
/**
 * 
 * @param m number
 * @param minX number x 포지션 최솟값
 * @param maxX number
 * @param minY number
 * @param maxY number
 * @returns
 */
const smallPointXY = (m, minX, maxX, minY, maxY) => {
  let p;
  let TKM = 100000;
  // 현재 바운드가 지정 범위 이내인지 확인(아니면 범위 지정)
  minX = minX > 7 * TKM ? minX : 7 * TKM;
  maxX = maxX > 15 * TKM ? 15 * TKM : maxX;
  minY = minY > 13 * TKM ? minY : 13 * TKM;
  maxY = maxY > 22 * TKM ? 22 * TKM : maxY;

  minX = Math.floor(minX / m) * m < minX ? (Math.floor(minX / m) - 1) * m : Math.floor(minX / m) * m;
  maxX = Math.floor(maxX / m) * m < maxX ? (Math.floor(maxX / m) + 1) * m : Math.floor(maxX / m) * m;
  minY = Math.floor(minY / m) * m < minY ? (Math.floor(minY / m) - 1) * m : Math.floor(minY / m) * m;
  maxY = Math.floor(maxY / m) * m < maxY ? (Math.floor(maxY / m) + 1) * m : Math.floor(maxY / m) * m;
  let pArr = [];
  for (let x=minX; x<=maxX; x+=m) {
    let t= [];
    for (let y=minY; y<=maxY; y+=m) {
      p = proj4(grs80, wgs84, [x, y]);
      t.push(p);
    }
    pArr.push(t);
  }
  return pArr;
}

/**
 * grid 모양을 원하는 범위만큼 필터링
 * @param coord [number, number] [lat, lng]
 * @returns {boolean}
 */
const isBound = (coord) => {
  let grs80P = proj4(wgs84, grs80, coord);
  let TKM = 100000;
  let filter = {
    7: [13, 21],
    8: [13, 20],
    9: [14, 21],
    10: [15, 21],
    11: [15, 21],
    12: [17, 21],
    13: [18, 21],
  };

  grs80P[0] = Math.round(grs80P[0]);
  grs80P[1] = Math.round(grs80P[1]);

  let t = Math.floor(grs80P[0] / TKM);
  if (filter[t] !== undefined) {
    if (grs80P[1] >= filter[t][0] * TKM && grs80P[1] < filter[t][1] * TKM) {
      return true;
    }
  }
  return false;
}

/**
 * 그리드의 이름 생성
 * @param d number 배율(100000 | 100000 | 1000 | 100)
 * @param text 국가지점번호 배열 {[string, number, number]}
 * @returns {string}
 */
const labelText = (d, text) => {
  let returnTxt = "";
  let t1, t2;
  switch (d) {
    case 100000:
      returnTxt = text[0];
      break;
    case 10000:
      t1 = Math.floor(text[1] / 1000).toString();
      t2 = Math.floor(text[2] / 1000).toString();
      returnTxt = `${text[0]}${t1}00${t2}00`;
      break;
    case 1000:
      t1 = Math.floor(text[1] / 10).toString().padStart(3, '0');
      t2 = Math.floor(text[2] / 10).toString().padStart(3, '0');
      returnTxt = `${text[0]}${t1}${t2}`;
      // t1 = Math.floor(text[1] / 100).toString().padStart(2, '0');
      // t2 = Math.floor(text[2] / 100).toString().padStart(2, '0');
      // returnTxt = `${text[0]}${t1}0${t2}0`;
      break;
    case 100:
      t1 = Math.floor(text[1] / 10).toString().padStart(3, '0');
      t2 = Math.floor(text[2] / 10).toString().padStart(3, '0');
      returnTxt = `${text[0]}${t1}${t2}`;
      break;
    default:
      break;
  }
  return returnTxt;
}

/**
 * 서버에서 가져온 데이터로 그리드 정보 업데이트
 * @param date Date
 * @param lineArr [{latLngArr: [[lat, lng],[lat, lng],[lat, lng],[lat, lng]],
                    id: string,
                    center: [lat, lng],
                    cbcText: cbcText}]
 * @returns [{latLngArr: [[lat, lng],[lat, lng],[lat, lng],[lat, lng]],
              id: string,
              center: [lat, lng],
              cbcText: cbcText,
              risk: number
            }]
 */
const mergeGridInfo = async (date, lineArr, _start, _end) => {
  const area = {
    sw: {
      lat: _start._lat,
      long: _start._lng
    },
    ne: {
      lat: _end._lat,
      long: _end._lng
    }
  }
  const gridInfo = await getGrid(date, lineArr.map(x => x.grid), area);
  const mergedArray = [];
  for (const line of lineArr) {
    const match = gridInfo.grid.find(info => info.grid === line.grid);
    if (match) {
      const { lat, long, crime_val } = match;
      if (lat !== 0 && long !== 0) {
        line.lat = lat;
        line.long = long;
      }
      line.crime_val = crime_val < 1 ? 10 : crime_val;
    }
    mergedArray.push(line);
  }
  return mergedArray
}


/**
 * 그리드 그리기
 * @param date string 목표날짜
 * @param zoomLevel number
 * @param _start [number, number] [lat, lng] 시작 좌표
 * @param _end [number, number] [lat, lng] 끝 좌표
 * @returns []그리드배열
 */
const lineArray = async (date, zoomLevel, _start, _end) => {
  let reArr = [];
  let start = proj4(wgs84, grs80, [_start._lng, _start._lat]);
  let end = proj4(wgs84, grs80, [_end._lng, _end._lat]);

  let divide = 100000;
  if (zoomLevel > 16) {
    divide = 100;
  } else if (zoomLevel > 13) {
    divide = 100;
  } else if (zoomLevel > 10) {
    divide = 10000;
  } else {
    divide = 100000;
  }

  let pArr = smallPointXY(divide, start[0], end[0], start[1], end[1]);
  for (let i=0; i<pArr.length; i++) {
    for (let j=0; j<pArr[0].length; j++) {
      if (i < pArr.length - 1 && j < pArr[i].length - 1) {
        let c = pArr[i][j];
        if (isBound(c)) {
          let nx = pArr[i+1][j];
          let ny = pArr[i][j+1];
          let nxy = pArr[i+1][j+1];
          let cbc = convertToCbc([(nxy[0] + c[0]) / 2, (nxy[1] + c[1]) / 2]);
          let cbcText = "";
          if (cbc !== undefined) {
            cbcText = labelText(divide, cbc);
          }
          reArr.push({
            latLngArr: [
              [c[1], c[0]],
              [nx[1], nx[0]],
              [nxy[1], nxy[0]],
              [ny[1], ny[0]],
            ],
            id: zoomLevel.toString() + "." + pArr[i][j] + "y",
            lat: (c[1] + nx[1] + nxy[1] + ny[1]) / 4,
            long: (c[0] + nx[0] + nxy[0] + ny[0]) / 4,
            grid: cbcText,
          });
        }
      }
    }
  }
  const lineArr = await mergeGridInfo(date, reArr, _start, _end);
  return lineArr;
};

const exportedObject = {
  convertToCbc,
  convertToCoord,
  lineArray,
}

export default exportedObject;