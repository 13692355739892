import axios from 'axios';
import { SERVER } from '../environment';
import { getCookie } from '../components/common/CSRF';
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.headers.common['X-CSRFToken'] = getCookie("csrftoken");

export const login = async (id, pw) => {
  const formData = new FormData();
  formData.append('login', id);
  formData.append('password', pw);

  try {
    const response = await axios.post(`${SERVER}/accounts/login/`, formData);
    console.log(response)
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
  return true;

}

export const checkUserState = async () => {
  const data = {
    ask_list: ["role","accept"]
  }

  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/getuserinfo`, data);
    console.log(response)
    if (['patroller', 'citizen'].includes(response.data.role)) {
      if (!response.data.accept || response.data.role === 'citizen') return 2;
      else return 1;
    } else if (response.data.role === 'police') {
      return 3
    } else return 0;
  } catch (error) {
    console.error('Error:', error);
    return 0;
  }
}

export const checkLogin = async () => {
  try {
    const response = await axios.get(`${SERVER}/patrolmain/api/logincheck`);
    if (response.data.is_authenticated) {
      return response.data;
    } else return false;
  } catch(error) {
    console.error('Error:', error);
    return false;
  }
}

export const getCSRF = async () => {
  try {
    const response = await axios.get(`${SERVER}/patrolmain/api/getcsrf`);
    if (response.data.message === "success") {
      return true;
    } else {
      return false;
    }
  } catch(error) {
    console.error('Error:', error);
    return false;
  }
}

export const getUser = async (type = 'all') => {
  const data = {
    type: type
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/memberlist/`, data);
    if (response.data.message === "success") {
      return response.data;
    } else {
      return false;
    }
  } catch(error) {
    console.error('Error:', error)
    return false;
  }
}

export const setUser = async (userData) => {
  const data = {
    "member_list":
    [
      {
        id: userData.id,
      }
    ]
  }
  for (let item of Object.keys(userData)) {
    if (item !== 'id') {
      data['member_list'][0][item] = userData[item];
    }
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/membersave/`, data);
    if (response.data.message === "success") {
      return true;
    } else {
      return false;
    }
  } catch(error) {
    console.error('Error:', error)
    return false;
  }
}

export const delUser = async (userId) => {
  const data = {
    exit_member: userId,
  }
  try {
    const response = await axios.post(`${SERVER}/patrolmain/api/memberexit/`, data);
    if (response.data.message === "success") {
      return true;
    } else {
      return false;
    }
  } catch(error) {
    console.error('Error:', error)
    return false;
  }
}
