import React, {useEffect, useState} from 'react';
import ToggleSwitch from '../common/ToggleSwitch';
import utils from '../common/Utils';
import UserConfirm from '../common/UserConfirm';
import Overlay from '../common/Overlay';
import { ColoredButton } from '../common/Button';
import { useUser } from '../../contexts/UserContext';
import { getUser, setUser as SETUser } from '../../services/auth';
import { dateFormatter, getUserCategory } from '../common/Utils';
import SetRegion from '../common/SetRegion';
import { Chart as ChartJS,
        ArcElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
        BarElement,
        Title, } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title,Tooltip, Legend);

const userList = [
  {
    "id": 12,
    "role": "patroller",
    "accept": "pass",
    "phone": "010-1234-5678",
    "username": "safety003",
    "first_name": "부안",
    "last_name": "방범대원",
    "belong1": "전라북도",
    "belong2": "부안군",
    "belong3": "부안읍",
    "date_joined": "2023-11-08T09:10:21Z",
    "last_login": "2023-11-15T05:48:37.317141Z",
    "learned_time": 0
},
{
    "id": 13,
    "role": "patroller",
    "accept": "pass",
    "phone": "010-1234-5678",
    "username": "safety004",
    "first_name": "임실",
    "last_name": "방범대원",
    "belong1": "전라북도",
    "belong2": "임실군",
    "belong3": "임실읍",
    "date_joined": "2023-11-08T09:11:03Z",
    "last_login": "2023-11-15T16:52:59.565059Z",
    "learned_time": 600
}
]
const checkList = ['승인', '미승인']
function TabUser(props) {
  const { user, setUser, setUserAuth } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [category] = useState(getUserCategory('skim'));
  const [targetUser, setTargetUser] = useState();
  const [userArr, setUserArr] = useState(userList);
  const [isChecked, setIsChecked] = useState(checkList);
  const [isOpen, setIsOpen] = useState(false);
  const [addr, setAddr] = useState({belong1: '전라북도', belong2: user.belong2, belong3: user.patrol.name});
  // const [addr, setAddr] = useState({belong1: '전라북도', belong2: '', belong3: ''});
  const [maxEdu, setMaxEdu] = useState(600);
  const [pieDataSet, setPieDataSet] = useState([0, 0]);
  const [barDataSet, setBarDataSet] = useState([0, 0, 0, 0]);

  const settingInfo = async() => {
    console.log(user);
    await setAddr({belong1: user.belong1, belong2: user.belong2, belong3: user.patrol.name});
  }

  const onUserClick = async(userData) => {
    await setTargetUser(userData);
    setIsEditing(true);
  }

  const handleCheckChange = async(val) => {
    if (isChecked.includes(val)) {
      setIsChecked(isChecked.filter(item => item !== val))
    } else {
      setIsChecked([...isChecked, val])
    }
  }

  const onConfirm = async(data={}) => {
    setTargetUser();
    if (Object.keys(data).length) {
      await SETUser(data, 'useronconfirm');
      const updatedUsers = userArr.map(user => {
        if (user.id === data.id) {
          return { ...user, ...data };
        }
        return user;
      });
      // console.log(updatedUsers)
      setUserArr(updatedUsers);
    }
    setIsEditing(false);
  }

  const onClose = async () => {
    setTargetUser();
    setIsEditing(false);
  }
  
  const setChartData = async () => {
    let passedCount = 0;
    let listened90 = 0;
    let listened5090 = 0;
    let listened050 = 0;
    let listened0 = 0;

    for (const item of userArr) {
      if (item.accept === 'pass') {
        passedCount++;
      }

      const listenPercentage = (item.learned_time / maxEdu) * 100;

      if (listenPercentage > 90) {
        listened90++;
      } else if (listenPercentage > 50) {
        listened5090++;
      } else if (listenPercentage > 0) {
        listened050++;
      } else {
        listened0++;
      }
    }
    setPieDataSet([passedCount, userArr.length - passedCount]);
    setBarDataSet([listened0, listened050, listened5090, listened90])
  }

  const filterUser = async (userList) => {
    const filteredUserList = await userList.filter(aUser => {
      if (user.belong2 === '' ||  user.belong2 === '전체') {
        return aUser;
      } else if (user.patrol.name === '' || user.patrol.name === '전체') {
        return aUser.belong2 === user.belong2
      } else {
        // return user;
        return aUser.belong2 === user.belong2 && aUser.belong3 === user.patrol.name
      }

      // if (addr.belong2 === '전체' || addr.belong2 === '') {
      //   return user
      // } else {
      //   return user.belong2 === addr.belong2
      // }
    })
    filteredUserList.sort((a,b) => b.learned_time - a.learned_time);
    await setUserArr(filteredUserList);
  }

  const getUserData = async(type) => {
    let userList;
    if (type === 'none') {
      userList = [];
    } else {
      const res = await getUser(type);
      userList = res.data;
      setMaxEdu(res.learn_total);
    }
    await filterUser(userList);
  }

  const setTargetRegion = async(data) => {
    await setAddr(data);
  }

  const pieData = {
    labels: ['승인', '미승인'],
    datasets: [{
      data: pieDataSet,
      backgroundColor: [
        'rgb(54, 162, 235)',
        'rgb(255, 99, 132)',
      ],
      hoverOffset: 4
    }]
  };
  const barData = {
    labels: ['0%', `0~50%`, `50~90%`, `100%`],
    datasets: [{
      label: `교육 이수 현황(전체 영상: ${Math.ceil(maxEdu/60)}분)`,
      data: barDataSet,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(36, 206, 56, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 205, 86)',
        'rgb(36, 206, 56)',
        'rgb(54, 162, 235)',
      ],
      borderWidth: 1
    }]
  };
  const barOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          min: 20,
        }
      }
    }
  };
  const pieOptions = {
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
        text: `${addr.belong3} 자율방범대 승인 현황 (${pieDataSet[0]}/${userArr.length})`
      }
  }
  };
  useEffect(() => {
    if (isChecked.includes('승인') && isChecked.includes('미승인')) {
      getUserData('all');
    } else {
      setIsChecked(checkList);
    }
  }, [addr.belong1, addr.belong2, addr.belong3])
  useEffect(() => {
    setChartData();
  }, [userArr])
  useEffect(() => {
    console.log(user);
    settingInfo();
  }, [user])
  useEffect(() => {
    if (isChecked.includes('승인')) {
      if (isChecked.includes('미승인')) {
        getUserData('all');
      } else {
        getUserData('pass');
      }
    } else if (isChecked.includes('미승인')) {
      getUserData('fail');
    } else {
      getUserData('none');
    }
  }, [isChecked])

  return (
    <div className="flex-grow p-10 overflow-y-auto">
      <div className="grid grid-cols-6 p-3">
        <ul className={`col-span-6 -mt-2 pl-auto `}>
          <li className="text-right">
            <div className='w-40 ms-auto'>
              <ColoredButton type={'custom'} className="mr-4 px-2 border-b-4 rounded border-indigo08 hover:bg-indigo01"
                click={() => setIsOpen(true)} text={'교육영상'}>
              </ColoredButton>
            </div>
          </li>
        </ul>
        </div>
        <Overlay type="video" isOpen={isOpen} title="교육영상" onClose={() => setIsOpen(false)} />

    <div className="flex flex-grow gap-1 items-center bg-white p-3" >
      <img src={utils.car} alt="patrol car" width={'24px'}/>
      <p className="text-lg font-bold">회원 목록</p>
    </div>
    <div className="p-3" >
      {/* <div className="flex justify-end">
        <SetRegion setTargetRegion={setTargetRegion} limit={'belong2'} />
      </div> */}
      <div className="flex justify-end">
        {checkList.map((x, idx) => (
          <div className="items-center p-2" key={x}>
            <input type="checkbox" id={x} name="checkList" value={x} checked={isChecked.includes(x)} onChange={() => handleCheckChange(x)} className="mr-2" />
            <label htmlFor={x}>{x}</label>
          </div>
        ))}
      </div>
    </div>
    <div className="p-8 flex flex-wrap justify-center gap-2 align-center" >
      <div className="flex-1 max-w-lg flex justify-center align-center my-auto">
        <Bar options={barOptions} data={barData} />
      </div>
      <div className="flex-1 max-w-xs flex justify-center align-center">
        <Pie options={pieOptions} data={pieData} />
      </div>
    </div>
    <div className="grid grid-cols-9 font-bold p-2 text-lg bg-white rounded-t">
      {Object.keys(category).map(cat => {
        return <p key={cat}>{cat}</p>
      })}
    </div>
    <div className="overflow-y-auto max-h-full">
      {userArr.map(user => {
        return (
          <div key={user.id} className={`grid grid-cols-9 p-2 border-b ${user.role === 'police' ? 'bg-indigo03 font-bold text-white' : user.accept === 'fail' ? 'bg-rose-100' : ''}`}>
            {Object.values(category).map(cat => {
              if (cat === 'first_name') {
                return <p className="my-auto" key={cat}>{user[cat]+user['last_name']}</p>
              } else if (cat === 'role') {
                return <p className="my-auto" key={cat}>{user[cat] === 'patroller' ? '방범대원' : user[cat] === 'police' ? '경찰' : '주민'}</p>
              } else if (cat === 'belong3') {
                return <p className="my-auto" key={cat}>{user[cat] ? user[cat] : ''}</p>
              } else if (cat === 'learned_time') {
                return <p className="my-auto" key={cat}>{(user[cat] ? Math.ceil(user[cat]/60) : 0) + (Math.ceil(user[cat]/60) > 0 ? (' / ' +  Math.ceil(maxEdu/60)) : '')}</p>
              } else if (cat === 'last_login') {
                return <p className="my-auto" key={cat}>{user[cat] ? dateFormatter(new Date(user[cat])) : '-'}</p>
              } else if (cat === 'accept') {
                return <p className="my-auto" key={cat}>{user[cat] === 'pass' ? '승인' : '미승인'}</p>
              } return (
                <p className="my-auto" key={cat}>{user[cat]}</p>
              )
            })}
            <ColoredButton type={'custom'} text={'수정'} click={() => onUserClick(user)} />
          </div>
        )
      })}
    </div>
    {targetUser && <UserConfirm isOpen={isEditing} onConfirm={onConfirm} onClose={onClose} user={targetUser} />}
    </div>
  );
}

export default TabUser;