import React from 'react';

function BorderLabel({type, text}) {
  const buttonClasses = {
    custom: 'border-indigo08 text-indigo08',
    success: 'border-green-600 text-green-600',
    error: 'border-red-500 text-red-500',
    warning: 'border-amber-500 text-amber-500',
    info: 'border-indigo05 text-indigo05',
    unset: 'border-gray-600 text-gray-600'
  };
  return (
    <span className={`px-2 py-1 rounded-full border-2 ${buttonClasses[type]} font-bold text-sm`}
        >{text}</span>
  );
}

export default BorderLabel;