import React, {useState, useEffect} from 'react';
import Maps from '../common/Maps';
import SafetyOptions from './TabMap/SafetyOptions';
import Overlay from '../common/Overlay';
import utils from '../common/Utils';

const target = 'CCTV';
function TabMap(props) {
  const [options, setOptions] = useState(utils.getSafetyMapImg().reduce((acc, option) => {
    acc[option.name] = false;
    return acc;
  }, {}))
  const [isOpen, setIsOpen] = useState(true);
  const [hidePopup, setHidePopup] = useState(false);

  const optionClicked = (option) => {
    setOptions((prevData) => ({
      ...prevData, [option]: !prevData[option]
    }))
  }
  useEffect(() => {
    const storedTimestamp = localStorage.getItem('hidePopupTimestamp');
    const isHidden = storedTimestamp && new Date().getTime() - Number(storedTimestamp) < 24 * 60 * 60 * 1000;
    console.log(storedTimestamp, new Date().getTime() - Number(storedTimestamp), isHidden)
    if (isHidden === true) {
      setHidePopup(true);
    }
  }, []);

  const handleCloseForToday = (val) => {
    console.log(val)
    setHidePopup(val);
    if (val) {
      localStorage.setItem('hidePopupTimestamp', new Date().getTime().toString());
    } else {
      localStorage.removeItem('hidePopupTimestamp');
    }
  };

  return (
    <div className="bg-white flex-grow">
      <SafetyOptions safetymapOption={options} onOptionClick={optionClicked}/>
      <Maps layer={'safetymap'} safetymapOption={options} optionClicked={optionClicked}/>
      {!hidePopup && <Overlay type="ad" title=" " contents={hidePopup} onHide={handleCloseForToday} isOpen={isOpen} onClose={() => setIsOpen(false)} /> }
    </div>
  );
}

export default TabMap;