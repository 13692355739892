import React, { createContext, useContext, useState } from 'react';
import { getHeaderTitle } from '../components/common/Utils';

const HeaderContext = createContext();

export function HeaderProvider({ children }) {
  const [header, setHeader] = useState(getHeaderTitle());

  return (
    <HeaderContext.Provider value={{ header, setHeader }}>
      {children}
    </HeaderContext.Provider>
  );
}

export function useHeader() {
  return useContext(HeaderContext);
}
