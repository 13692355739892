import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import EditButtons from '../../common/EditButtons';
import Overlay from '../../common/Overlay';
import { useUser } from '../../../contexts/UserContext';
import { getNews } from '../../../services/mapAdmin';
import utils from '../../common/Utils'

const newsHeader = {id: '아이디', title: '제목', period: '기간', edit: ''};
const popupHeader = {id: '아이디', title: '제목', period: '기간', contents: 'url', edit: ''};
function NewsList(props) {
  const {user, userAuth} = useUser();
  const [src, setSrc] = useState([]);
  const [curNews, setCurNews] = useState();
  const [curTarget, setCurTarget] = useState('공지');
  const [tableHeader, setTableHeader] = useState(newsHeader);
  const [expandedNews, setExpandedNews] = useState({});

  const onEdit = async (target) => {
    props.onEditClick(src[target].id);
    setCurNews(src[target].id)
  }

  const onDel = async (target) => {
    props.onDelClick(src[target].id);
  }

  const getNewsDetail = async(id) => {
    if (window.location.origin === 'http://localhost:3000') {
      return {
            "id": 5,
            "created_at": "2023-12-19",
            "close_at": "2023-12-27",
            "title": "공지입니다",
            "contents": "오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다",
            "belong2": "전주시 완산구",
            "files": "{'files': [], 'path': 'https://kr.object.ncloudstorage.com/spatrol-test/files/2023/12/20/police001/'}",
            "attached": 1
      }
    } else {
      return await getNews(Number(id), 'news', {belong1: user.belong1, belong2: user.belong2, patrol: user.patrol.name});
    }
  }

  const onNewsClick = async (id) => {
    if (userAuth === 1) {
      const res = await getNewsDetail(id)
      setExpandedNews(res);
    }
  }
  const getNewsList = async (addr) => {
    if (window.location.origin !== 'http://localhost:3000') {
      const res = await getNews('list', 'news', addr);
      setSrc(res.sort((a, b) => a.id - b.id));
    } else {
      setSrc([{
        id: "1",
        created_at: "2023-10-17",
        close_at: "2023-10-17",
        title: "title1",
        attached: 1,
      },
        {
        id: "2",
        created_at: "2023-10-17",
        close_at: "2023-10-17",
        title: "title2",
        attached: 0,
      },])
    }
  }

  useEffect(() => {
    if (!window.location.pathname.startsWith('/profile')) {
      setSrc(props.source);
    }
  }, [props.source])
  useEffect(() => {
    if (props.addr) {
      getNewsList(props.addr);
    }
  }, [props.addr])
  useEffect(() => {
    if (props.target) {
      setCurTarget(props.target);
      setTableHeader(props.target === '공지' ? newsHeader : popupHeader);
    }
  }, [props.target])

  return (
    <div className="w-full mt-2 overflow-auto" style={{maxHeight: "100%"}} >
      {props.addr && (
        <Link to={`/profile`}>
          <span className="rounded m-1 mb-2 hover:bg-indigo02 hover:pointer flex align-center">
            <img src={utils.back} alt="back" width={'20px'}/>
          </span>
        </Link>
      )}
    <table className="w-full table-fixed relative">
      <colgroup>
        <col width={[3, 4].includes(userAuth) ? '15%' : '20%'}></col>
        <col width={[3, 4].includes(userAuth) ? '25%' : '40%'}></col>
        <col width={[3, 4].includes(userAuth) ? '25%' : '40%'}></col>
        <col width={[3, 4].includes(userAuth) ? '25%' : '0%'}></col>
      </colgroup>
      <thead className="sticky top-0 left-0 z-10">
        <tr>
          {Object.keys(tableHeader).map(txt => (
            txt === 'edit'
            ? [3, 4].includes(userAuth)
              ? <th key={txt} className="py-3 bg-indigo02 text-center border border-gray-300">{tableHeader[txt]}</th>
              : ''
            : <th key={txt} className="py-3 bg-indigo02 text-center border border-gray-300">{tableHeader[txt]}</th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {src ? src.map((row, idx) => (
          <tr key={'row' + idx} onClick={() => onNewsClick(row.id)} className={curNews === row.id ? 'border-2 border-indigo08' : ''}>
            {Object.keys([3, 4].includes(userAuth) ? tableHeader : (({ 'edit': _, ...rest }) => rest)(tableHeader)).map(col => (
              (
                <td key={col} className="py-2 border border-gray-300">
                  <div className="max-h-32 overflow-y-auto">
                    {col === 'period'
                      ? row['created_at'] + ' - ' + row['close_at']
                      : col === 'edit'
                        ? <EditButtons parent="admin" onEdit={() => onEdit(idx)} onDel={() => onDel(idx)} isAuthor={true} />
                        : row[col]}
                  </div>
                </td>
              )
            ))}
          </tr>
        )) : ''}
      </tbody>
    </table>
    <Overlay type={'newsDetail'} contents={expandedNews} isOpen={expandedNews.id} onClose={() => setExpandedNews({})} />
  </div>
  );
}

export default NewsList;