import React, { useEffect, useState } from 'react';
import utils from '../../common/Utils'
import { useVideo } from '../../../contexts/VideoContext';

function EduDuration(props) {
  const {video} = useVideo();
  const [target, setTarget] = useState(new Date());
  const [duration, setDuration] = useState();
  const [diffDay, setDiffDay] = useState(0);
  const today = new Date();
  useEffect(() => {
    if (video && video[0]) {
      const endDate = new Date(video[0].end_date)
      setTarget(endDate);
      setDuration(utils.dateFormatter(endDate));
      setDiffDay(Math.floor((endDate - new Date()) / (1000*60*60*24)));
    }
  }, [video])
  
  return (
    <div className="relative h-5/6 p-2 flex flex-col justify-center align-center">
      {(diffDay >= 0) && <p className="text-center text-lg"><span className="text-indigo08 text-xl font-bold">D-{diffDay}</span>일 <br /> 남았습니다.</p>}
      {(diffDay < 0) && <p className="text-center text-lg">기한이 만료되었습니다.</p>}
      <p className="text-sm text-gray-500">{duration}까지</p>
    </div>
  )
}

export default EduDuration;