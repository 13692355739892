import React, {useState, useEffect, startTransition} from 'react';
import {Button, BorderedButton, ColoredButton} from '../common/Button';
import CSRFToken from '../common/CSRF';
import {titleImg} from '../common/Utils';


function Signin(props) {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleIdChange = (e) => {
    const value = e.target.value.trim()
    setId(value);
    if (value && password) setIsDisabled(false);
    else setIsDisabled(true)
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value.trim()
    setPassword(value);
    if (value && id) setIsDisabled(false);
    else setIsDisabled(true)
  };

  const navigateToSignup = () => {
    props.setLoginFunc(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id === 'admin') {
      return props.onAdminClick();
    } else if (id === 'user123' || id === 'user1234') {
      return props.onClick(id);
    }
    if (id && password) {
      // login(id, password)

      const form = e.target;
      form.submit();
    } else console.log('fileds are not filled')
    
  }

  return (
    <form className="mx-auto w-5/6 md:w-1/2 pb-20" method="POST" action="/accounts/login/" onSubmit={handleSubmit}>
      <CSRFToken />
      <img src={titleImg} alt="account" className="w-3/4 mx-auto"/>
      {/* <h1 className="text-indigo08 rounded-md text-4xl font-black my-6">자율방범 순찰</h1> */}
      <div className="flex flex-col justify-center content-center my-9">
        <input name="login" value={id} onChange={handleIdChange} type="text" className="text-black p-3 rounded mb-3 border focus:outline-none focus:border-indigo08" placeholder="아이디를 입력해주세요." />
        <input name="password" value={password} onChange={handlePasswordChange} type="password" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="비밀번호를 입력해주세요." />
      </div>
      <ColoredButton type={'custom'} isSubmit={true} text={'로그인'} disabled={isDisabled}/>
      <BorderedButton classname={'w-full mt-3 py-2'} type={'custom'} text={'이메일로 회원가입하기'} click={navigateToSignup} />
    </form>
  );
}

export default Signin;