import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import utils from './Utils';
import { useUser } from '../../contexts/UserContext';
import { ColoredButton } from './Button';
import { mobileInput, inputLabel } from '../../styles';
import { usePatrolMemo } from '../../contexts/PatrolMemoContext';
import { useMapContext } from '../../contexts/MapContext';
import { dateFormatter, getWeekAfterDate } from './Utils';
import { addMemo } from '../../services/mapMain';

const reportTypeList = [
  '무질서', '범죄위험', '인적요인', '환경요인', '학교주변', '현금다량취급', '기타'
]

function ArticleEditor({parent, memo}) {
  const {patrolMemo, addPatrolMemo, setPatrolMemo} = usePatrolMemo();
  const {user} = useUser();
  const navigate = useNavigate();
  const {eventLocation, setEventLocation} = useMapContext();
  const [id, setId] = useState();
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [address, setAddress] = useState('');
  const [type, setType] = useState('무질서');
  const [publicity, setPublicity] = useState('public');
  const [createDate, setCreateDate] = useState();
  const [endDate, setEndDate] = useState(getWeekAfterDate());
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'title':
        setTitle(e.target.value);
        break;
      case 'contents':
        setContents(e.target.value);
        break;
      case 'pic':
        const files = Array.from(e.target.files);
        setSelectedFiles(files);
        break;
      case 'address':
        setAddress(e.target.value);
        break;
      case 'type':
        setType(e.target.value);
        break;
      case 'publicity':
        setPublicity(e.target.value);
        break;
      case 'endDate':
        setEndDate(e.target.value);
        const res = checkDateFormat(e.target.value);
        if (res) {
          setError('');
        } else setError('날짜 형식은 2024-01-01입니다');
        break;
      case 'reportPW':
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const checkDateFormat = (string) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (datePattern.test(string)) {
      return true;
    } else {
      return false;
    }
  }

  const removeFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  const openFileInput = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };

  const onClick = async () => {
    if (['route', 'memo'].includes(parent)) {
      // const data = {
      //   id: '',
      //   created_at: dateFormatter(new Date()),
      //   close_at: dateFormatter(new Date('2023-11-31')),
      //   patrol: '효자3동',
      //   title: title,
      //   contents: contents,
      //   files: selectedFiles,
      //   type: type,
      //   location: {lat: address.lat, long: address.lng},
      //   public: publicity === 'public' ? true : false
      // }
      const formData = new FormData();
      formData.append('id', id || '');
      formData.append('created_at', createDate ? createDate : dateFormatter(new Date()));
      formData.append('close_at', endDate);
      formData.append('patrol', user.patrol.name);
      formData.append('title', title);
      formData.append('contents', contents);
      for (let i = 0; i < selectedFiles.length; i++) { 
        formData.append("files", selectedFiles[i]);
      }
      formData.append('type', type);
      formData.append('location', JSON.stringify({lat: address.lat, long: address.lng || address.long}));
      formData.append('public', publicity === 'public' ? 1 : 0);
      const res = await addMemo(formData);
      if (res) navigate(`/route`);
    };
  }

  const setMemoData = async () => {
    setId(memo.id);
    setTitle(memo.title);
    setContents(memo.contents);
    setSelectedFiles(memo.files.files);
    setAddress(memo.location);
    setType(memo.type);
    setPublicity(memo.public == '1' ? 'public' : 'private');
    setCreateDate(memo.created_at);
    setEndDate(memo.close_at);
  }


  useEffect(() => {
      setAddress(eventLocation);
  }, [eventLocation])

  useEffect(() => {
    if (!error && title && contents && address && type) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [title, contents, address, type, error]);

  useEffect(() => {
    if (parent === 'memo') {
      setMemoData();
    }
  }, [parent])

  return (
    <div className="bg-white h-screen flex-grow pb-20 overflow-auto">
      <div className="relative py-3">
        <Link to={`/route`}>
          <span className="absolute left-2 rounded p-2 -mt-1 hover:bg-indigo02 hover:pointer">
            <img src={utils.back} alt="back" width={'20px'}/>
          </span>
        </Link>
        <p className="text-center font-bold text-lg">
          {parent === 'report' ? '안전신고 접수' : '순찰메모 등록'}
        </p>
      </div>
      <div className="flex flex-col">
        <input type='text' name="title" value={title} onChange={handleInputChange} className={`${mobileInput} border-x-0`} placeholder='제목을 입력해주세요' />
        <textarea name="contents" value={contents} onChange={handleInputChange} className={`${mobileInput} border-x-0 min-h-[280px] `} placeholder='내용을 입력해주세요'>
        </textarea>
      </div>
      <div className="flex flex-col p-2 gap-2">
        <span className="grid grid-cols-4">
          <label className={`${inputLabel} col-span-1`} onClick={openFileInput}>사진</label>
          <div className="col-span-3">
            { selectedFiles.length > 0 &&
              <ul className="w-full">
                {selectedFiles.map((file, index) => (
                  <li key={index} className="flex items-center">
                    <p className="truncate">{file.name || 'file'+index}</p>
                    <img
                      src={utils.del}
                      alt="Remove"
                      onClick={() => removeFile(index)}
                      className="ml-2 h-4 w-4 cursor-pointer"
                    />
                  </li>
                ))}
              </ul>
            }
            <ColoredButton type="custom" image="plus" text="사진 첨부" click={openFileInput}/>
          </div>
          <input 
            type='file'
            id="fileInput" name="pic"
            accept=".jpg, .png"
            className="hidden"
            onChange={handleInputChange}
            multiple
          />
        </span>
        {/* <span className="grid grid-cols-4">
          <label className={`${inputLabel} col-span-1`} htmlFor="address">위치 선택</label>
          <input id="address" type="text" name="address" onChange={handleInputChange} className={`${mobileInput} col-span-3`} placeholder="입력하지 않으면 현재 위치로 설정됩니다" />
        </span> */}
        <span className="grid grid-cols-4">
          <label htmlFor="select" className={`${inputLabel} col-span-1`}>신고 유형</label>
          <select id="select" name="type" value={type} onChange={handleInputChange} className="col-span-3 border rounded px-3 py-2 w-full">
            {reportTypeList.map(x => (
              <option key={x} value={x}>{x}</option>
            ))}
          </select>
        </span>
        <span className="grid grid-cols-4">
          <label htmlFor="endDate" className={`${inputLabel} col-span-1`}>기한</label>
          <input id="endDate" type="text" name="endDate" value={endDate} onChange={handleInputChange} className={`${mobileInput} col-span-3`} placeholder="2024-01-01" />
          <p className="col-start-2 col-span-3 text-left">{error}</p>
        </span>
        <span className="grid grid-cols-4">
          <label className={`${inputLabel} col-span-1`}>공개 여부</label>
          <span className="col-span-3 flex ">
            {['public', 'private'].map(x => (
              <div className="items-center p-2" key={x}>
                <input type="radio" id={x} name="publicity" value={x} checked={publicity === x} onChange={handleInputChange} className="mr-2" />
                {parent === 'report' && <label htmlFor={x}>{x === 'public' ? '공개' : '비공개'}</label>}
                {['route', 'memo'].includes(parent) && <label htmlFor={x}>{x === 'public' ? '전체공개' : '방범대공개'}</label>}
              </div>
            ))}
          </span>
        </span>
        {parent === 'report' && publicity === 'private' && (
          <span className="grid grid-cols-4">
            <label className={`${inputLabel} col-span-1`} htmlFor="pw">비밀번호</label>
            <input id="pw" type="password" name="reportPW" onChange={handleInputChange} className={`${mobileInput} col-span-3`}/>
          </span>
        )}
      </div>
      <div className="p-4 mb-20">
        <ColoredButton type="custom" text={parent === 'report' ? '접수하기' : '등록하기'} click={onClick} disabled={isDisabled}/>
      </div>
    </div>
  );
}

export default ArticleEditor;