import React from 'react';
import {Button} from './Button';

function ButtonsRow({onClose, onConfirm, disabled}) {
  return (
    <div className='w-full flex justify-between my-3'>
      <Button type={'error'} text={'취소'} click={onClose}/>
      <Button type={'success'} text={'저장하기'} click={onConfirm} disabled={disabled !== undefined ? disabled : '' }/>
    </div>
  );
}

export default ButtonsRow;