import React, {useState, useEffect} from 'react';
import patrolData from '../../assets/data/patrol.json';

const jeonbukList = ['전주시 완산구', '전주시 덕진구', '김제시', '임실군', '부안군'];

function SetRegion(props) {
  const [addr1, setAddr1] = useState(props.addr ? props.addr.belong1 : '전라북도');
  const [addr2List, setAddr2List] = useState(jeonbukList);
  const [addr2, setAddr2] = useState(props.addr ? props.addr.belong2 : '전체');
  const [addr3List, setAddr3List] = useState(patrolData['전주시']['완산구'].map(x => x.patrol));
  const [addr3, setAddr3] = useState(props.addr ? props.addr.belong3 : '전체');
  const [isAddr2All, setIsAddr2All] = useState(true);
  const handleAddrChange = (e) => {
    let list;
    let targetPatrolData;
    switch (e.target.name) {
      case 'belong1':
        setAddr1(e.target.value);
        break;
      case 'belong2':
        setAddr2(e.target.value);
        targetPatrolData = e.target.value === '전체'
        ? []
        : e.target.value === '전주시 완산구'
          ? patrolData['전주시']['완산구']
          : e.target.value === '전주시 덕진구'
            ? patrolData['전주시']['덕진구']
            : patrolData[e.target.value]
        list = !targetPatrolData.length ? [] : targetPatrolData.map(x => x.patrol);
        setAddr3('전체')
        setAddr3List(list);
        break;
      case 'belong3':
        setAddr3(e.target.value);
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    const ADDR = {
      belong1: addr1,
      belong2: addr2,
      belong3: addr3
    }
    props.setTargetRegion(ADDR);
  }, [addr1, addr2, addr3])
  useEffect(() => {
    console.log(props.addr || 'no addr');
    if (props.addr && props.addr.belong2.length) {
      setAddr1(props.addr.belong1 || '전라북도');
      setAddr2(props.addr.belong2 || '전체');
      const targetPatrolData = props.addr.belong2 === ''
          ? []
          : props.addr.belong2 === '전주시 완산구'
            ? patrolData['전주시']['완산구']
            : props.addr.belong2 === '전주시 덕진구'
              ? patrolData['전주시']['덕진구']
              : patrolData[props.addr.belong2]
      const list = !targetPatrolData ? [] : targetPatrolData.map(x => x.patrol);
      setAddr3(props.addr.belong3 || '전체')
      setAddr3List(list);
    }
  }, [props.addr])
  useEffect(() => {
    if (props.isAllIncluded === false) {
      setIsAddr2All(false);
    }
  }, [props.isAllIncluded])
  return (
    <div>
      <div className="flex gap-2">
          <select id="addr1" name="belong1" value={addr1} onChange={handleAddrChange} className="hidden col-span-3 border rounded p-3 w-full">
            <option value="" disabled>시/도</option>
            {['전라북도'].map(x => (
              <option key={x} value='전라북도'>{x}</option>
            ))}
          </select>
          <select id="addr2" name="belong2" value={addr2} onChange={handleAddrChange} className="col-span-3 border rounded p-3 w-full">
            {isAddr2All === true ? <option value="전체">전체</option> : null}
            {addr2List.map(x => (
              <option key={x} value={x}>{x}</option>
            ))}
          </select>
          <select disabled={addr2 === '전체'} id="addr3" name="belong3" value={addr3} onChange={handleAddrChange} className={`col-span-3 border rounded p-3 w-full ${props.limit === 'belong2' ? 'hidden' : ''}`}>
            <option value="전체">전체</option>
            {addr3List.map(x => (
              <option key={x} value={x.slice(0, -5)}>{x}</option>
            ))}
          </select>
        </div>
    </div>
  );
}

export default SetRegion;