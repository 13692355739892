import React, {useState, useEffect} from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import TabPrecas from '../admin/TabPrecas';
import TabFlexibility from '../admin/TabFlexibility';
import TabCharacter from '../admin/TabCharacter';
import TabUser from '../admin/TabUser';
import TabNews from '../admin/TabNews';
import PageNotFound from '../common/PageNotFound';
import { useMapContext } from '../../contexts/MapContext';
import { useHeader } from '../../contexts/HeaderContext';
import { useUser } from '../../contexts/UserContext';
import { getHeaderTitle } from '../common/Utils';

const tabs = ['PRECAS', '탄력 순찰', '지역 특성 데이터', '공지 관리', '팝업 관리', '회원 관리'];
const tabPath = ['/', '/flex', '/char', '/news', '/popup', '/user']
function Admin(props) {
  const { setPrecasDate } = useMapContext();
  const { setHeader } = useHeader();
  const {user, setUser, setUserAuth} = useUser();
  const location = useLocation();
  const [isSuper, setIsSuper] = useState(false);

  useEffect(() => {
    setHeader(getHeaderTitle());
    if (props.userData) {
      setUser(props.userData, 'admin');
      setUserAuth(props.userState);
      if (props.userData.isSuperAdmin) {
        setIsSuper(true);
      }
    }
    if (props.precasDate && props.precasDate.length > 0) {
      setPrecasDate(props.precasDate);
    }
  }, [props])

  return (
    <div className="flex flex-col flex-grow overflow-y-auto">
      <div className="tab mainTabs bg-indigo08 flex justify-center mt-2">
        {tabs.map((tab, index) => (
          <Link to={tabPath[index]} key={'tab'+index}>
            <button
              className={`truncate btn py-3 px-5 rounded-t-lg me-1 text-xl font-bold text-white border-b-2 ${location.pathname === tabPath[index] ? 'border-white' : 'border-transparent'}`}
            >{tab}</button>
          </Link>
        ))}
      </div>
      <Routes>
        <Route path="" element={<TabPrecas />} />
        <Route path="flex" element={<TabFlexibility />} />
        <Route path="char" element={<TabCharacter />} />
        <Route path="news" element={<TabNews param="news" />} />
        <Route path="popup" element={<TabNews param="popup" />} />
        <Route path="user" element={<TabUser />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default Admin;