import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loader from '../common/Loader';
import Alert from '../common/Alert';
import Overlay from '../common/Overlay';
import { mainSection } from '../../styles';
import { delUser } from '../../services/auth';
import { SERVER } from '../../environment';


const exitMsg = '정말 탈퇴하시겠습니까?'
function Profile(props) {
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState(exitMsg);
  const {user} = useUser();
  const navigate = useNavigate();

  const onPwModBtnClick = () => {
    navigate('mod');
  }

  const onDelBtnClick = async (depth) => {
    if (depth) {
      const res = await delUser(user.id);
      if (res) {
        if (window.location.href !== 'http://localhost:3000/') {
          window.location.href = `${SERVER}/accounts/logout`;
        } else {
          await setAlertMsg(`${user.id}가 탈퇴되었습니다`);
        }
      } else {
        await setAlertMsg('에러가 발생했습니다.');
      }
    } else {
      await setAlertMsg(exitMsg);
      setIsOpen(true);
    }
  }

  const onNewsClick = async () => {
    navigate('news');
  }
  const onPrivacyClick = () => {
    setIsPrivacyOpen(true);
  };
  const onCompanyClick = () => {
    setIsCompanyOpen(true);
  };
  useEffect(() => {
    setIsReady(true);
  }, [])

  if (!isReady) return <Loader />
  return (
    <div className={`${mainSection} px-0 mb-20 h-screen flex flex-col justify-between text-left`}>
      <div className="upper">
        <div className="row">
          <p><span className="font-bold text-xl">{user.username}</span>님</p>
        </div>
        {!user.accept &&
        <div className="row flex-col">
          <p className="m-0 leading-loose">{user.role === 'patroller' ? user.belong2 + ' ' + user.patrol.name + '자율방범대원' : ''}</p>
          <div className="flex gap-2">
            <div className="bg-whome text-white text-sm font-bold w-max px-2 rounded-full leading-[32px]">
              <p>미승인</p>
            </div>
            <p className="leading-[32px] text-sm">관리자의 승인 대기 중입니다.</p>
          </div>
        </div>
        }
        {user.accept &&
        <div className="row hover:bg-indigo02" onClick={onNewsClick}>
          <button>공지 확인</button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-8.5 0 32 32" fill="#000000">
            <path xmlns="http://www.w3.org/2000/svg" d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
          </svg>
        </div>
        }
        <div className="row hover:bg-indigo02" onClick={onPwModBtnClick}>
          <button>회원정보 수정</button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-8.5 0 32 32" fill="#000000">
            <path xmlns="http://www.w3.org/2000/svg" d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
          </svg>
        </div>
        <div className="row hover:bg-indigo02" onClick={() => onDelBtnClick(false)}>
          <p>탈퇴</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-8.5 0 32 32" fill="#000000">
            <path xmlns="http://www.w3.org/2000/svg" d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
          </svg>
        </div>
      </div>
      <div className="lower mb-28">
        <div className="row hover:bg-indigo02" onClick={onPrivacyClick}>
          개인정보보호방침
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-8.5 0 32 32" fill="#000000">
            <path xmlns="http://www.w3.org/2000/svg" d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
          </svg>
        </div>
        <div className="row hover:bg-indigo02" onClick={onCompanyClick}>
          개발자 정보
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="-8.5 0 32 32" fill="#000000">
            <path xmlns="http://www.w3.org/2000/svg" d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
          </svg>
        </div>
      </div>
      {isOpen && (alertMsg === exitMsg ? <Alert type="warning" message={alertMsg} onClose={() => setIsOpen(false)} onConfirm={() => onDelBtnClick(true)}/>
      : <Alert type="warning" message={alertMsg} onClose={() => setIsOpen(false)}/>)}
      <Overlay type={'privacy'} title="개인정보 보호방침" isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />
      <Overlay type={'company'} title="테바소프트" isOpen={isCompanyOpen} onClose={() => setIsCompanyOpen(false)} />
    </div>
  );
}

export default Profile;