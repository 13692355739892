import React, {useState} from 'react';
import utils from '../common/Utils';

function ReflectBtn({text, onClick, isActive, setIsActive}) {
  return (
    <button className={`${isActive ? 'bg-red-300' : 'bg-indigo01 hover:bg-indigo02'} flex justify-center align-center rounded relative font-bold py-2 px-4`} onClick={onClick} onMouseEnter={() => setIsActive != null ? setIsActive(true) : ''} onMouseLeave={() => setIsActive != null ? setIsActive(false) : ''}>
      <img src={utils.bluecar} alt="patrol car" width={"26px"} className="mr-2"/>
      <p className="inline-block text-indigo08 leading-7">{text} 순찰 반영</p>
      {isActive && (
          <div className="z-30 absolute left-full transform -translate-x-full -translate-y-full w-full bg-gray-800 text-white p-2 text-sm rounded shadow-md">
            {'반영 버튼을 눌러야 데이터가 저장됩니다.'}
          </div>
        )}
    </button>
  );
}

export default ReflectBtn;