import React, {useEffect, useState} from 'react';
import { Button } from './Button';
import ConfirmReport from './ConfirmReport';

function Confirm({type, isOpen, onClose, onDel, onReport}) {
  const [reportReason, setReportReason] = useState('');

  const onCancel = () => {
    setReportReason('');
    onClose();
  }
  const onConfirm = () => {
    if (type === 'report') {
      const val = reportReason;
      setReportReason('');
      onReport(val);
    } else if (type === 'del') {
      onDel();
    }
  }

  useEffect(() => {

  }, [reportReason])
  return (
    <div
      className={`fixed inset-0 z-40 ${
        isOpen ? 'block' : 'hidden'
      } bg-black bg-opacity-50`}
    >
      <div className="absolute inset-0 flex justify-center items-center">
      <div className="bg-white p-2 rounded-lg w-5/6 max-w-xl max-h-[500px] overflow-auto">
      {type === 'del' ? (
        <p className="text-center text-lg font-bold my-3">정말 삭제하시겠습니까?</p>
      ) : type === 'report' ? <ConfirmReport reason={reportReason} setReason={setReportReason} /> : ''}

    <div className='w-full flex justify-end my-3'>
      <Button type={'error'} text={'취소'} click={onCancel}/>
      <Button type={'custom'} text={'확인'} click={onConfirm} disabled={type === 'report' ? !reportReason.trim() : false}/>
    </div>
    </div>
    </div>
    </div>
  );
}

export default Confirm;