import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {isMobile, isAndroid, isDesktop, isChrome, isSafari, isEdge, isBrowser, isFirefox, isMobileSafari} from 'react-device-detect';
import { useHeader } from '../../contexts/HeaderContext';
import utils, { getHeaderTitle } from '../common/Utils';
import SetRegion from '../common/SetRegion';
import { useUser } from '../../contexts/UserContext';

function Header(props) {
  const { user, setUser, setUserAuth } = useUser();
  const { header, setHeader } = useHeader();
  const location = useLocation();
  const [updated, setUpdated] = useState(false);
  const [patrol, setPatrol] = useState({
    belong1: user.belong1,
    belong2: user.belong2,
    belong3: user.patrol.name
  });


  const onChangeAuthClick = () => {
    if (props.isAuthenticated) {
      setHeader(getHeaderTitle());
    }
    props.changeAuth();
  }

  const onChangeRegion = (data) => {
    setPatrol(data);
    setUser({
      belong1: data.belong1,
      belong2: data.belong2,
      patrol: {name: data.belong3, crimeVals: []}
    }, 'header');
  }

  useEffect(() => {
    if (!updated) {
      if(props.userPatrol && props.userPatrol.belong1 !== '') {
        setPatrol({
          belong1: props.userPatrol.belong1,
          belong2: props.userPatrol.belong2,
          belong3: props.userPatrol.belong3
        });
        setUpdated(true);
      } 
    } else {
      console.log('updated true', patrol.belong3);
    }
    // else {
    //   setPatrol({
    //     belong1: user.belon1,
    //     belong2: user.belong2,
    //     belong3: user.patrol.name
    //   })
    // }
  }, [props.userPatrol])

  return (
    <nav className={`${[0, 1, 2].includes(props.isAuthenticated) ? 'bg-indigo08 text-white w-full max-w-screen-md mx-auto' : 'bg-white text-gray900' }`}>
      <div className="mx-auto py-3 px-4 sm:px-6">
        <div className="flex items-center justify-between h-20 relative">
          <div className="flex-shrink-0">
            <h1 className="relative text-left my-2">
              <Link
                  to="/"
                  className="hover:text-black rounded-md text-2xl font-black">
                {header} 
              </Link>
            </h1>
            {[3, 4].includes(props.isAuthenticated) && patrol.belong3 !== '' && <SetRegion setTargetRegion={onChangeRegion} addr={patrol}/>}
            {/* <p className="text-center m-0 bg-indigo01 ">{[3, 4].includes(props.isAuthenticated) ? props.userPatrol ? props.userPatrol + ' 자율방범대' : '' : ''}</p> */}
          </div>
          <div className="relative">
            <div className="flex items-baseline">
              {
                !props.isAuthenticated ? 
                <div className="flex content-center">
                  <button
                    onClick={onChangeAuthClick}
                    className="px-3 py-2 rounded-md text-sm font-medium"
                  >
                    로그인
                  </button>
                  {/* <button
                    onClick={props.signup}
                    className="px-3 py-2 rounded-md text-sm font-medium"
                  >
                    회원가입
                  </button> */}
                </div>
              : 
                <div className="flex content-center">
                  {[3, 4].includes(props.isAuthenticated) &&
                  <button
                    onClick={props.onChangePwClick}
                    className="px-3 py-2 rounded-md text-sm font-medium"
                  >
                    비밀번호변경
                  </button>}
                  <button
                    onClick={onChangeAuthClick}
                    className="px-3 py-2 rounded-md text-sm font-medium"
                  >
                    로그아웃
                  </button>
                </div>
              }
            </div>
            <p className="absolute -top-5 right-0" style={{fontSize: '10px'}}>
              {(!isBrowser && !isDesktop && !isSafari && !isChrome && !isEdge && !isFirefox && !isMobileSafari && isMobile && !isAndroid) ? 'iOS V2.0': ''}
              </p>
          </div>
          {props.isAuthenticated === 2 && <p className="absolute -top-2"><span className="text-yellow-300">⚠️</span> 관리자의 승인이 필요합니다.</p>}
        </div>
      </div>
    </nav>
  );
}

export default Header;