import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Overlay from '../../common/Overlay';
import Confirm from '../../common/Confirm';
import '../../../styles/utils.css';
import exportedObject from '../../common/Utils';
import EditButtons from '../../common/EditButtons';
import { useUser } from '../../../contexts/UserContext';
import { delMemo, reportMemo } from '../../../services/mapMain';

function Memo({memo, isExpanded, setIsExpanded, isDeleted}) {
  const {user} = useUser();
  const [isAuthor, setIsAuthor] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoExpanded, setPhotoExpanded] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);
  const navigate = useNavigate();

  const memoClicked = (e) => {
    if (!e.target.classList.contains('memoPic') && e.target.tagName !== 'IMG') {
      setIsExpanded(memo.id)
    }
  }
  const expandPhoto = (e) => {
    setPhotoExpanded(e.target.src)
    setIsOpen(true)
  }
  const photoOverlayClosed = () => {
    setIsOpen(false);
  }

  const onEdit = async () => {
    navigate('/route/'+memo.id);
  }
  const onDel = async () => {
    setConfirmType('del');
    setIsConfirm(true);
  }

  const onDelConfirm = async () => {
    const res = await delMemo(memo.id);
    if (res) {
      setIsConfirm(false);
      isDeleted(memo.id);
    }
  }

  const onReport = async () => {
    setConfirmType('report');
    setIsConfirm(true);
  }

  const onReportConfirm = async (reason) => {
    const res = await reportMemo(memo.id, reason);
    if (res) setIsConfirm(false);
  }

  useEffect(() => {

    if (user.id === memo.author) {
      console.log(user.id, memo.author)
      setIsAuthor(true);
    }
  }, [user])

  return (
    <div className="border-b p-2 text-left" onClick={memoClicked}>
      <div className="grid grid-cols-4">
        <p className={`${isExpanded ? 'overflow-hidden' : 'display2lines'} font-bold text-md col-span-3`}>{memo.title}</p>
        <span className="col-span-1 edit">
          <EditButtons onDel={onDel} onEdit={onEdit} onReport={onReport} isAuthor={isAuthor} />
        </span>
      </div>
      <Confirm type={confirmType} isOpen={isConfirm} onReport={onReportConfirm} onDel={onDelConfirm} onClose={() => setIsConfirm(false)}/>
      <p className={isExpanded ? '' : 'display2lines'}>{memo.contents}</p>
      {isExpanded && (
        <span>
          <p><span className='font-bold'>유형: </span>{memo.type}</p>
          <p><span className='font-bold'>시작일자: </span>{memo.created_at || '-'}</p>
          <p><span className='font-bold'>종료일자: </span>{memo.close_at || '-'}</p>
          <p><span className='font-bold'>위치: </span>{memo.addr || '-'}</p>
          {memo.files ? (
            <div className="overflow-x-auto whitespace-nowrap snap-x touch-auto">
              {/* <span className="inline-block">
                <div className="snap-center memoPic flex items-center justify-center border rounded w-24 h-24 p-2" onClick={expandPhoto}>
                  <img className="memoPic w-full block mx-auto" src={memo.files.path} alt={memo.title}/>
                </div>
              </span> */}
              {memo.files.files.map((file, idx) => {
                return (
                  <span key={memo.title+(idx + 1)} className="inline-block">
                    <div className="snap-center memoPic flex items-center justify-center border rounded w-24 h-24 p-2" onClick={expandPhoto}>
                      <img className="memoPic w-full block mx-auto" src={memo.files.path + file} alt={memo.title + (idx + 1)}/>
                    </div>
                  </span>
                )
              })}
            </div>
          ) : ''}
        </span>
      )}
      {photoExpanded && (
        <Overlay type="img" contents={photoExpanded} isOpen={isOpen} onClose={photoOverlayClosed} />
      )}
      {memo.date ? <p className="text-sm text-right">{exportedObject.dateFormatter(memo.date)}</p> : ''}
    </div>
  );
}

export default Memo;