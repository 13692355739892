import React, {useEffect, useState} from 'react';
import ToggleSwitch from './ToggleSwitch';
import patrolData from '../../assets/data/patrol.json';
import { getUserCategory, dateFormatter } from './Utils';
import '../../styles/utils.css'

function UserConfirm({isOpen, onConfirm, onClose, user}) {
  const [userCategory] = useState(getUserCategory())
  const [data, setData] = useState({id: user.id});
  const [role, setRole] = useState(user.role);
  const [phone, setPhone] = useState(user.phone);
  const [first, setFirst] = useState(user.first_name);
  const [last, setLast] = useState(user.last_name);
  const [addr2List, setAddr2List] = useState();
  const [addr2, setAddr2] = useState('');
  const [addr3List, setAddr3List] = useState(patrolData['전주시']['완산구'].map(x => x.patrol));
  const [addr3, setAddr3] = useState('');

  const staticCategory = ['ID', '시도', '시군구', '방범대', '가입일', '최근접속일', '교육현황(분)'];
  const selectCategory = {
    'role': {'citizen': '주민', 'patroller': '방범대원', 'police': '경찰'},
    // 'belong2': {patrolData},
    // 'belong3': {},
    'accept': {'pass': '승인', 'fail': '미승인'},
  }
  const dynamicCategory = {
    '전화번호': phone,
    '성': first,
    '이름': last
  }

  const inputChanged = async (e) => {
    console.log(e.target.name);
    const targetName = e.target.name;
    const targetValue = e.target.value;
    console.log(data);
    switch (targetName) {
      case 'phone':
        setPhone(targetValue);
        break;
      case 'first_name':
        setFirst(targetValue);
        break;
      case 'last_name':
        setLast(targetValue);
        break;
      default:
        break;
    }
    if (user[targetName] !== targetValue) {
      console.log('here',user[targetName], targetValue);
      setData(prevData => ({
        ...prevData,
        [targetName]: targetValue
      }))
    } else {
      if (data.hasOwnProperty(targetName)) {
        const newData = { ...data };
        delete newData[targetName];
        setData(newData);
      }
    }
  }

  const onConfirmClicked = async (e) => {
    if (Object.keys(data).length >= 2) {
      onConfirm(data);
    }
    onConfirm();
  }

  return (
    <div
      className={`fixed inset-0 z-50 ${
        isOpen ? 'block' : 'hidden'
      } bg-black bg-opacity-50`}
    >
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="bg-indigo01 flex flex-col p-2 rounded-lg w-5/6 max-w-xl max-h-[550px] overflow-auto">
          <div className="inputWrapper max-w-md mx-auto text-left p-2 mt-2">
            {Object.keys(userCategory).map((category, idx) => {
              return (
                <div key={category} className="grid grid-cols-4">
                  <p className="font-bold p-2">{category}</p>
                  {staticCategory.includes(category)
                  ? <p key={userCategory[category]} className="static">{
                    userCategory[category] === 'date_joined' ? dateFormatter(new Date(user[userCategory[category]]))
                    : userCategory[category] === 'last_login' ? user[userCategory[category]] ? dateFormatter(new Date(user[userCategory[category]])) : '-'
                    : userCategory[category] === 'learned_time' ? user[userCategory[category]] ? Math.floor(user[userCategory[category]]/60) : 0
                    : user[userCategory[category]]
                    }</p>
                  : Object.keys(selectCategory).includes(userCategory[category])
                    ? <select key={userCategory[category]} name={userCategory[category]} defaultValue={user[userCategory[category]]} onChange={inputChanged}>
                        {Object.keys(selectCategory[userCategory[category]]).map(cat => {
                          return <option key={cat} value={cat} className="text-left">{selectCategory[userCategory[category]][cat]}</option>
                        })}
                      </select>
                    : <input key={userCategory[category]} name={userCategory[category]} className="dynamic" value={dynamicCategory[category]} onChange={inputChanged}></input>
                  }
                </div>
              )
            })}
          </div>
          <div className="text-right">
            <button onClick={onConfirmClicked} className="mt-4 bg-indigo08 text-white px-4 py-2 rounded">
              수정
            </button>
            <button onClick={onClose} className="mt-4 bg-indigo08 text-white px-4 py-2 rounded">
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserConfirm;