import React, { createContext, useContext, useState } from 'react';

const VideoContext = createContext();

export function VideoProvider({ children }) {
  const [video, setVideo] = useState([]);
  const [updateUserData, setUpdateUserData] = useState();

  return (
    <VideoContext.Provider value={{ video, setVideo, updateUserData, setUpdateUserData }}>
      {children}
    </VideoContext.Provider>
  );
}

export function useVideo() {
  return useContext(VideoContext);
}
