import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Maps from '../common/Maps';
import Overlay from '../common/Overlay';
import { setPath, getMemo, getVideo } from '../../services/mapMain';
import { HeaderProvider } from '../../contexts/HeaderContext';
import { BorderedButton } from '../common/Button';
import { useUser } from '../../contexts/UserContext';
import { direction15 } from '../../services/map';
import { useMapContext } from '../../contexts/MapContext';
import utils from '../common/Utils';
import { usePatrolMemo } from '../../contexts/PatrolMemoContext';

function TabRoute(props) {
  const {user} = useUser();
  const navigate = useNavigate();
  const {setPatrolMemo} = usePatrolMemo();
  const {eventLocation, setEventLocation} = useMapContext();
  const [data, setData]=useState({});
  const [selectedPath, setSelectedPath] = useState([]);
  const [wayPath, setWayPath] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const createPath = async () => {
    const date = utils.dateFormatter(new Date());
    const response = await setPath(data.area, data.crime_val, user.patrol.name, date);
    setWayPath(response.path);
    setSelectedPath(response.route);
  }

  const openMemoList = async () => {
    await getMemoList();
    setIsOpen(true);
  }
  const setMapInfo = (data) => {
    setData({
      area: { sw: {lat: data.bounds['_sw'].y, long: data.bounds['_sw'].x},
      ne: {lat: data.bounds['_ne'].y, long: data.bounds['_ne'].x}},
      crime_val: data.riskLevel
    });
  }

  const closeMemoList = async () => {
    setIsOpen(false);
    getMemoList();
  }

  const openMemoWriter = () => {
    navigate('/route/write');
  }

  const getMemoList = async () => {
    const res = await getMemo();
    console.log(res);
    setPatrolMemo(res);
  }

  const setCur = async () => {
    try {
      const currentPosition = await utils.getCur();
      await setEventLocation(currentPosition);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getMemoList();
  }, [])
  return (
    <div className="">
      <p className="bg-indigo08 -mt-4 pb-2 text-white text-xl font-black whitespace-pre">{user.patrol.name} 자율방범대</p>
      <div className="flex justify-around p-2 bg-white">
        <BorderedButton type="custom" text="자동경로생성" click={createPath}/>
        <BorderedButton type="custom" text="순찰메모목록" click={openMemoList} />
        <Link to="/route/write" onClick={setCur}><BorderedButton type="custom" text="순찰메모등록" /></Link>
      </div>
      <Overlay type="patrol_memo" isOpen={isOpen} title="순찰메모목록" onClose={closeMemoList} />
      <Maps layer={'route'} onLongTap={openMemoWriter} target={selectedPath} way={wayPath} setMapInfo={setMapInfo}/>
    </div>
  );
}

export default TabRoute;