import { createContext, useContext, useState } from 'react';
import mockUser from '../assets/data/user.json';

const UserContext = createContext();
const userSample = mockUser;

export function UserProvider({ children }) {
  const [user, updateUser] = useState(userSample);
  const [userAuth, setUserAuth] = useState(0);
  const [addrUpdated, setAddrUpdated] = useState(false);
  const setUser = (data, origin='none') => {
    if (origin === 'admin' && addrUpdated) {
      return;
    }
    updateUser(prevUser => ({
      ...prevUser,
      ...data
    }));
    // updateUser(data);
    setAddrUpdated(true);
  }

  return (
    <UserContext.Provider value={{ user, setUser, userAuth, setUserAuth }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  return useContext(UserContext);
}