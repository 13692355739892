import React from 'react';

function Privacy(props) {
  return (
    <div className="text-sm text-gray-600 space-y-4 text-left z-50">
    <div>
      <h5 className="font-bold">[개인정보 처리방침]</h5>
  
      <div className="mt-2 space-y-0.5">
        <p>테바소프트(주) (이하 “회사”)는 개인정보보호법, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 항상 최선을 다하고 있습니다. 또한 개인정보처리방침은 웹이나 앱의 하단 메뉴를 통해 공개하여 서비스 이용자가 언제나 용이하게 열람할 수 있도록 하고 있습니다. 회사의 개인정보처리방침은 다음과 같습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제1조 개인정보의 수집 항목 및 수집 방법</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 수집항목</p>
        <p>회사는 회원가입, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.</p>
        <p>1. 필수항목: 회원가입 시 개인정보로 수집합니다. 이메일, 닉네임, 전화번호, 방범대 소속정보 등</p>
        <p>2. 서비스 이용 과정이나 사업 처리 과정에서 자동으로 생성되어 수집될 수 있는 항목: IP Address, 쿠키, 방문 일시, 위치 정보, 서비스 이용 기록, 불량 이용 기록 </p>
        <p>② 개인정보 수집 방법</p>
        <p>1. 서비스 이용 과정에서 수집</p>
        <p>2. 팩스, 전화, 상담, 이메일, 이벤트 응모 등의 과정에서 수집</p>
        <p>3. 협력 회사로부터의 정보 수집 툴을 통한 수집 이외에도 서비스 이용 과정에서 서비스 이용 기록, 기기 정보가 생성되어 수집될 수 있습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제2조 개인정보 수집 및 이용 목적</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 회사는 회원관리(회원가입 의사 확인, 본인 확인, 개인 식별, 회원탈퇴 의사 확인 등), 회사 서비스 제공, 서비스 개발 및 개선, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.</p>
        <p>② 회원 관리 서비스 이용, 본인 확인, 개인 식별, 서비스 이용 과정에서 법령 및 회사 이용 약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 추후 법정 대리인 본인 확인, 분쟁 조정을 위한 기록 보존, 불만 처리 등 민원 처리, 고지 사항 전달</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제3조 개인정보 공유 및 제공</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 회사는 이용자들의 개인정보를 “제2조 개인정보 수집 및 이용 목적”에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우를 예외로 하며 자세한 내용은 별첨 [개인정보 목적 외 이용 및 제3자 제공 지침]을 따릅니다.</p>
        <p>1. 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 동의를 한 경우</p>
        <p>2. 법령의 규정에 의거하는 경우</p>
        <p>3. 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우</p>
        <p>② 기타 세금과 관련하여 부가가치세 징수 목적 또는 연말정산 등을 위해 관계법령에 따라 개인정보가 국세청 또는 관세청 등 과세 관련 처분청에 제공될 수 있습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제4조 개인정보의 처리 위탁</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 회사는 편리하고 더 나은 서비스를 제공하기 위해 업무 중 일부를 외부에 위탁할 수 있습니다.</p>
        <p>② 회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁할 수 있으며, 위탁할 경우 위탁 받은 업체가 정보통신망법에 따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 합니다.</p>
       </div>
    </div>
    <div>
      <h5 className="font-bold">제5조 개인정보 보유 및 이용 기간</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 원칙적으로 이용자가 서비스에 가입한 날로부터 계정 삭제 또는 해지 시까지 개인정보를 보유 및 이용합니다. 회원탈퇴 시 회사는 이용자의 수집된 개인정보가 열람 또는 이용될 수 없도록 파기 처리합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.</p>
        <p>② 회원의 동의를 받아 보유하고 있는 거래 정보에 대해 회원이 열람을 요구하는 경우에는 지체 없이 해당 정보를 열람•확인할 수 있도록 조치합니다.</p>
        <p>③ 회원이 자발적으로 서비스 가입을 탈퇴할 경우 재가입, 임의 해지 등을 반복적으로 행함으로써 본 서비스가 제공하는 할인 쿠폰, 이벤트 혜택 등 경제상의 이익을 불•편법적으로 수취하거나 이 과정에서 명의 도용 등의 행위가 발생하는 것을 방지하기 위한 목적으로 서비스 탈퇴 후 1개월 동안 회원의 개인 식별 정보를 보관합니다.</p>
        <p>④ 회원의 동의를 받아 통계 작성 및 학술 연구 등의 목적을 위하여 특정 개인을 알아볼 수 없는 형태로 개인정보를 계속 보유할 수 있습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제6조 개인정보의 열람 및 정정</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 회원은 언제든지 본 서비스에 등록되어 있는 본인의 개인정보를 열람하거나 정정할 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 개인정보 관리 책임자 및 담당자에게 서면, 전화 또는 전자우편으로 연락하시면 지체 없이 조치하겠습니다.</p>
        <p>② 회원이 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제7조 개인정보 파기 절차 및 방법</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 회사는 원칙적으로 회원이 회원탈퇴 시 특정 보호 기간을 거쳐 이용자의 수집된 개인정보가 열람 또는 이용될 수 없도록 파기 처리합니다. 단, 회원에게 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</p>
        <p>② 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
        <p>1. 파기 절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련법령에 따라 일정 기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</p>
        <p>2. 파기 방법: 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적인 방법을 사용하여 삭제합니다.</p>
        <p>3. 회사는 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 개인정보보호법 제39조의 6에 근거하여 이용자에게 사전 통지하고 개인정보를 파기하거나 별도로 분리하여 저장합니다.</p>
        <p>③ 회사는 개인정보가 파기되거나 분리되어 저장∙관리된다는 사실, 서비스 미이용 기간 만료일, 해당 개인정보의 항목을 공지사항, 전자우편 등의 방법으로 미이용 기간 30일 전에 이용자에게 알립니다. 이를 위해 이용자는 회사에게 정확한 연락처 정보를 알리거나 수정하여야 합니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제8조 개인정보의 수집/이용/제공 동의 철회</h5>
      <div className="mt-2 space-y-0.5">
        <p>이용자는 회원가입 등을 통해 개인정보의 수집/이용/제공에 대해 동의한 내용을 언제든지 철회할 수 있습니다. 동의 철회는 관리 책임자에게 전자적 통신수단(E-mail 등) 및 앱 상의 기능 등을 이용하여 신청할 수 있으며, 회사는 이를 확인 후 회원탈퇴를 위해 필요한 조치를 취합니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제9조 개인정보의 안전성 확보조치(개인정보보호를 위한 관리적/기술적 조치)</h5>
      <div className="mt-2 space-y-0.5">
        <p>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
        <p>① 관리적 조치</p>
        <p>– 개인정보보호 내부관리계획 수립 시행</p>
        <p>– 개인정보보호전담기구의 운영, 사내 개인정보보호전담기구 등을 통하여 회사의 개인정보처리방침 이행 사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</p>
        <p>1. 본 서비스는 회원의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.</p>
        <p>· 이용자를 직접 상대로 하여 상담, 마케팅 업무를 수행하는 자</p>
        <p>· 개인정보 관리 책임자 및 담당자 등 개인정보 관리 업무를 수행하는 자</p>
        <p>· 기타 업무상 개인정보의 취급이 불가피한 자</p>
        <p>2. 본 서비스는 회원의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여 소속 직원으로 하여금 이를 숙지하고 준수하도록 하고 있으며 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁 교육을 실시하고 있습니다.</p>
        <p>3. 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.</p>
        <p>4. 전산실 및 자료 보관실 등을 특별 보호 구역으로 설정하여 출입을 통제하고 있습니다.</p>
        <p>5. 본 서비스는 컴퓨터를 이용하여 고객님의 개인정보를 처리하는 경우 개인정보에 대한 접근 권한을 가진 담당자를 지정하여 식별 부호(ID) 및 비밀번호를 부여하고 해당 비밀번호를 정기적으로 갱신하고 있습니다.</p>
        <p>6. 본 서비스는 신규 직원 채용 시 정보보호서약 또는 개인정보보호서약을 하도록 하여 직원에 의한 정보 유출을 사전에 방지하고 개인정보 처리방침에 대한 이행 및 직원의 준수 여부를 감사하기 위한 내부 절차를 마련하여 지속적으로 시행하고 있습니다.</p>
        <p>7. 본 서비스는 직원 퇴직 시 비밀유지서약을 하도록 하여 이용자의 개인정보를 취급하였던 자가 직무 상 알게 된 개인정보를 훼손•침해 또는 누설하지 않도록 하고 있습니다.</p>
        <p>8. 본 서비스는 회원 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원의 경우 개개인이 본인의 개인정보를 보호하기 위해서 정보를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.</p>
        <p>9. 내부 관리자의 실수나 기술 관리상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 본 서비스는 지체 없이 회원에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</p>
        <p>② 기술적 조치</p>
        <p>– 개인정보처리시스템 등의 접근 권한 관리, 접근 통제 시스템 설치, 고유 식별 정보 등의 암호화, 보안 프로그램 설치 등 관계법령에 따라 필요한 조치</p>
        <p>– 기술적 대책으로 본 서비스는 고객님의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.</p>
        <p>1. 회원의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안 기능을 통해 보호되고 있습니다.</p>
        <p>2. 본 서비스는 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신 프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오면 지체 없이 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.</p>
        <p>3. 본 서비스는 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안 장치를 채택하고 있습니다.</p>
        <p>4. 해킹 등 외부 침입에 대비하여 각 서버마다 침입 차단 시스템 및 취약점 분석 시스템 등을 이용하여 보안에 만전을 기하고 있습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제10조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h5>
      <div className="mt-2 space-y-0.5">
        <p>① 회사는 이용자들에게 특화된 맞춤 서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</p>
        <p>② 쿠키는 이용자들이 방문한 자율방범 순찰 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.</p>
        <p>③ 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</p>
        <p>④ 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</p>
        <p>설정방법 예(인터넷 익스플로어의 경우): 웹브라우저 상단의 도구 > 인터넷 옵션 > 개인정보</p>
        <p>다만, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제12조 개인 정보 관련 담당자 연락처</h5>
      <div className="mt-2 space-y-0.5">
        <p>이용자는 회사의 서비스를 이용하여 발생하는 모든 개인정보보호 관련 민원을 개인 정보관리책임자 혹은 담당부서로 신고할 수 있습니다. 회사는 회원의 신고사항에 대해 신속하게 답변할 것입니다.</p>
        <p>■ 개인정보 보호책임자</p>
        <p>– 이름: 오정섭</p>
        <p>– 소속: 테바소프트(주)</p>
        <p>– 직위: 대표</p>
        <p>– 전화: 070-4456-7100</p>
        <p>– 이메일: james@tebahsoft.com</p>
        <p>■ 개인정보 관리책임자</p>
        <p>– 담당부서 : 소프트웨어개발팀</p>
        <p>– 이름: 이병훈</p>
        <p>– 소속: 테바소프트(주)</p>
        <p>– 직위: 이사</p>
        <p>– 전화: 070-4456-7102</p>
        <p>– 이메일: bright@tebahsoft.com</p>
        <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
        <p>1. 개인정보침해신고센터 (http://www.118.or.kr / 국번 없이 118)</p>
        <p>2. 대검찰청 사이버범죄수사단 (http://www.spo.go.kr / 02-3480-2000)</p>
        <p>3. 경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 1566-0112)</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제13조 기타</h5>
      <div className="mt-2 space-y-0.5">
        <p>회사에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 “회사 개인정보 처리방침”이 적용되지 않음을 알려 드립니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">제14조 개정 및 고지</h5>
      <div className="mt-2 space-y-0.5">
        <p>“개인정보 처리방침”에 내용이 추가되거나 삭제 및 수정이 있을 시 개정 최소 7일 전부터 홈페이지의 ‘공지사항’ 게시 및 다양한 방법으로 고지할 것입니다. 단, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.</p>
      </div>
    </div>
    <div>
      <h5 className="font-bold">[부 칙]</h5>
      <div className="mt-2 space-y-0.5">
        <p>▶ 공고일자 : 2023. 11. 01.</p>
        <p>▶ 시행일자 : 2023. 11. 01.</p>
      </div>
    </div>
  </div>  
  );
}

export default Privacy;