import React from 'react';

function Label({type, text}) {
  const buttonClasses = {
    custom: 'bg-indigo08 text-white',
    success: 'bg-green-700 text-white',
    error: 'bg-red-700 text-white',
    warning: 'bg-amber-700 text-white',
    info: 'bg-blue-800 text-white',
    unset: 'bg-gray-300 text-gray-700'
  };
  return (
    <span className={`px-2 py-1 rounded-full font-bold text-sm ${buttonClasses[type]}`}
        >{text}</span>
  );
}

export default Label;