import React, { useState, useEffect } from "react";

function ToggleSwitch(props) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(props.val)
  }, [props.val])
  const handleToggle = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
    setIsChecked(!isChecked);
  };

  return (
    <label className="flex justify-center items-center cursor-pointer" id="toggleSwitch">
      <div className="relative">
        <input
          type="checkbox"
          className="hidden"
          checked={isChecked}
          onChange={handleToggle}
        />
        <div className={`toggle__line w-10 h-6 rounded-full shadow-inner ${isChecked? "bg-indigo08" : "bg-gray-400"}`}></div>
        <div className={`toggle__dot absolute w-4 h-4 bg-white rounded-full shadow left-1 top-1 transition-transform duration-300 ${isChecked ? "transform translate-x-full" : ""}`}></div>
      </div>
    </label>
  );
}

export default ToggleSwitch;