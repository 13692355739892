import React, {useEffect, useState} from 'react';

function DaySelect({lastDay, selectedDay, onDayChange}) {
  const [curDay, setCurDay] = useState(selectedDay);

  useEffect(() => {
    setCurDay(selectedDay)
  }, [selectedDay])

  useEffect(() => {
    onDayChange(curDay);
  }, [curDay])

  const barClicked = (e) => {
    const target = e.target.closest("#barDiv");
    const boundingRect = target.getBoundingClientRect();
    const relativeClickX = e.clientX - boundingRect.left;
    const totalWidth = boundingRect.width;

    const indexClicked = Math.ceil((relativeClickX / totalWidth) * lastDay);
    setCurDay(indexClicked < 1 ? 1 : indexClicked);
  }
  const dayClicked = (e, index) => {
    const boundingRect = e.target.getBoundingClientRect();
    setCurDay(index < 1 ? 1 : index);
  }

  return (
    <div className="col-span-6 md:col-span-4 py-2 flex flex-col flex-wrap justify-center">
      <div id="barDiv" onClick={barClicked}
          className="relative mx-auto bg-indigo03 h-1" style={{width: '90%'}}>
        <div className="absolute left-0 top-0 bg-indigo08 h-1"
             style={{width: `${Math.ceil((100 / (lastDay)) * curDay)}%`, minWidth: `${Math.ceil((100 / (lastDay)) * 1)}%`}}>
          {curDay > 0 ? (
            <div
              style={{width: '36px'}}
              className="absolute -right-4 -top-3 bg-white px-2 py-1 rounded-full border border-indigo08 font-bold">
              {curDay}
            </div>
            ) : ''}
        </div>
      </div>
      <div style={{width: '90%', height: 'fit-content'}}
          className="hidden md:flex justify-between mx-auto">
        {Array.from({ length: lastDay+1 }).map((_, index) => (
          <div key={index} className="text-center flex items-center justify-center"
              style={{width: '17px'}} onClick={(e) => dayClicked(e, index)}>
            {index % 2 !== 0 ? <span className={`${index === curDay ? 'hidden' : ''}`}>{index}</span> : (
                <div className="h-1 w-1 bg-black rounded-full"></div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DaySelect;