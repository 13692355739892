import React from 'react';
import utils from '../common/Utils';

function EditButtons({parent, onEdit, onDel, onReport, isAuthor}) {
  const onClick = (target, type) => {
    target.parentNode.classList.add('ring-1');
    if (type === 'edit') {
      target.parentNode.classList.add('bg-indigo01');
      onEdit()
    } else if (type === 'del') {
      target.parentNode.classList.add('bg-red-100');
      onDel()
    } else if (type === 'report') {
      target.parentNode.classList.add('bg-red-100');
      onReport();
    }
    setTimeout(() => {
      target.parentNode.classList.toggle('ring-1');
      if (type === 'edit') {
        target.parentNode.classList.remove('bg-indigo01');
      } else{
        target.parentNode.classList.remove('bg-red-100');
      }
    }, 300);
  }
  return (
    <div className="edit">
      {isAuthor ? (
        <div className={`flex gap-2 items-center ${parent === 'admin' ? 'justify-center' : 'justify-end'}`}>
          <span className={`${parent === 'admin' ? '' : 'hidden'} editWrapper p-1 ring-indigo08 rounded-full hover:bg-indigo01 hover:cursor-pointer`}>
            <img src={utils.edit} alt="edit" onClick={(e) => onClick(e.target, 'edit')} width='18px'/>
          </span>
          <span className="delWrapper p-1 ring-whome rounded-full hover:bg-red-100 hover:cursor-pointer">
            <img src={utils.trash} alt="delete" onClick={(e) => onClick(e.target, 'del')} width='18px'/>
          </span>
        </div>
      ): (
        <div className="flex gap-2 items-center justify-end">
          <span className="reportWrapper p-1 ring-whome rounded-full hover:cursor-pointer">
            <img src={utils.siren} alt="siren" onClick={(e) => onClick(e.target, 'report')} width='18px'/>
          </span>
        </div>
      )}
    </div>
  );
}

export default EditButtons;