import { createContext, useContext, useState } from 'react';

const AdContext = createContext();

export function AdProvider({ children }) {
  const [adArr, setAdArr] = useState([]);

  return (
    <AdContext.Provider value={{ adArr, setAdArr }}>
      {children}
    </AdContext.Provider>
  );
};

export function useAd() {
  return useContext(AdContext);
}