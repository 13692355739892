import React, {useState, useEffect} from 'react';

function News(props) {
  const [src, setSrc] = useState([]);

  const setData = async (data) => {
    const updatedData = data;
    const fileData = await JSON.parse(updatedData.files.replace(/'/g, '"'));
    updatedData.files = await fileData;
    setSrc(updatedData);
  }
  useEffect(() => {
    if (props.source.title) {
      console.log(props.source)
      setData(props.source);
    }
  }, [props.source])
  useEffect(() => {
    console.log(src)
  }, [src])
  if (src.title) {
    return (
      <div className='p-2'>
        <p className='p-2 text-2xl text-left border-gray-600 border-b-2'>{src.title}</p>
        <div>
          <p className='text-right my-2'>{src.created_at} ~ {src.close_at}</p>
          <p className='text-left'>{src.contents}</p>
        </div>
      </div>
    );
  }
}

export default News;