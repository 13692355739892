import React, {useState, useEffect} from 'react';
import NewsEditor from './tabNews/NewsEditor';
import NewsList from './tabNews/NewsList';
import Overlay from '../common/Overlay';
import { getNews, delNews } from '../../services/mapAdmin';
import SetRegion from '../common/SetRegion';
import { BorderedButton } from '../common/Button';

function TabNews(props) {
  const [target, setTarget] = useState('공지');
  const [isLocal, setIsLocal] = useState(true);
  const [mode, setMode] = useState('등록');
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState({});
  const [clearDetail, setClearDetail] = useState(true);
  const [addr, setAddr] = useState({belong1: '전라북도', belong2: '', belong3: ''});
  const [src, setSrc] = useState([{
    id: "1",
    created_at: "2023-10-17",
    close_at: "2023-10-17",
    title: "title1",
    attached: 1,
  },
    {
    id: "2",
    created_at: "2023-10-17",
    close_at: "2023-10-17",
    title: "title2",
    attached: 0,
  },]);

  const onEditClick = async (data) => {
    // setIsOpen(true);
    setId(data);
    setMode('수정');
  }
  const onCreateClick = async () => {
    setId('');
    setClearDetail(!clearDetail);
    setMode('등록');
  }
  const onDelClick = async (data) => {
    let msg = '삭제되었습니다';
    if (isLocal) {
      const filteredNews = src.filter(news => news.id!==data);
      setSrc(filteredNews);
    } else {
      const res = await delNews(data);
      if (res) {
        const filteredNews = src.filter(news => news.id!==data);
        setSrc(filteredNews);
      } else {
        msg = '에러가 발생했습니다';
      }
    }
    alert(msg);
  }

  const getNewsList = async () => {
    const res = await getNews('list', 'news', addr);
    setSrc(res.sort((a, b) => a.id - b.id));
  }
  const setTargetRegion = async(data) => {
    setAddr(data);
  }

  const getAdList = async () => {
    const res = await getNews('list', 'adver', addr);
    setSrc(res.sort((a, b) => a.id - b.id));
  }

  // useEffect(() => {
  //   if (target === '공지') {
  //     console.log('hi3')

  //     getNewsList();
  //   }
  // }, [addr])

  useEffect(() => {
    if (window.location.origin !== 'http://localhost:3000') {
      setIsLocal(false);
    } else {
      setIsLocal(true);
    }
    if (props.param === 'popup') {
      setTarget('광고');
      getAdList();
    } else {
      setTarget('공지');
      getNewsList();
    }
  }, [props.param])

  return (
    <div className='p-6'>
      <div className="text-right mb-2">
        <BorderedButton type='custom' isSubmit={false} text={`새 ${target}`} click={onCreateClick} />
      </div>
      <div className="flex gap-4">
        <div className="flex-1">
          {target === '공지' && <SetRegion setTargetRegion={setTargetRegion} />}
          <NewsList target={target} source={src} onEditClick={onEditClick} onDelClick={onDelClick}/>
        </div>
        <div className="flex-1">
          <NewsEditor target={target} mode={mode} clearDetail={clearDetail} onCreate={setSrc} id={id} />
        </div>
        <Overlay isOpen={isOpen} contents={'hi'} onClose={() => setIsOpen(false)}/>
      </div>
    </div>
  );
}

export default TabNews;