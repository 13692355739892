import React, {useState, useEffect, useRef, Suspense, useTransition} from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import { mainSection } from '../../../styles';
import lectures from '../../../assets/data/lectures.json';
import { useUser } from '../../../contexts/UserContext';
import { setVideoData } from '../../../services/mapMain';
import { useVideo } from '../../../contexts/VideoContext';
import { dateFormatter } from '../../common/Utils';

function EduDetail({handleEduData}) {
  const { user, setUser } = useUser();
  const { video, setVideo } = useVideo();
  const { id } = useParams();
  const [targetVideo, setTargetVideo] = useState();
  const [userLectureData, setUserLectureData] = useState();
  const [savedTime, setSavedTime] = useState(0);
  const [prevPlayedTime, setPrevPlayedTime] = useState(0);
  const location = useLocation();
  const [isPending, startTransition] = useTransition();
  const updatedUserLectureData = useRef();
  const getLectures = async() => {
    // let videoList;
    // if (window.location.hostname === 'localhost') {
    //   videoList = await lectures;
    // } else {
      // videoList = video;
    // }
    const target = await video.filter(x => x.video_id == id);
    if (target.length) {
      setTargetVideo(target[0]);
    } else console.log('비디오가 없습니다');
    const userLectureIndex = user.lectures.findIndex((item) => item.video_id == id);
    console.log('getLectures', userLectureIndex)
    if (userLectureIndex !== -1) {
      const targetData = user.lectures[userLectureIndex];
      await setUserLectureData(targetData);
      await setSavedTime(targetData.last_update_time);
      await setPrevPlayedTime(targetData.played_time);
    }
  }

  useEffect(() => {
    if (id && video) {
      getLectures();
    }
  }, [id, video])

  const timeUpdated = async (playedTime, lastTime, isEnded) => {
    let updatedData;
    const existingIndex = user.lectures.findIndex((item) => item.video_id == id);
    if (userLectureData) {
      updatedData = {
          ...userLectureData,
        last_play_date: dateFormatter(new Date()),
        last_update_time: lastTime > savedTime ? lastTime : savedTime,
        played_time: prevPlayedTime + playedTime >= targetVideo.length ? targetVideo.length : prevPlayedTime + playedTime,
        state: isEnded ? 2 : 1,
      }
    } else {
      updatedData = {
        video_id: targetVideo.video_id,
        title: targetVideo.title,
        last_play_date: dateFormatter(new Date()),
        last_update_time: lastTime > savedTime ? lastTime : savedTime,
        played_time: prevPlayedTime + playedTime >= targetVideo.length ? targetVideo.length : prevPlayedTime + playedTime,
        state: isEnded ? 2 : 1
      }
    }
    updatedUserLectureData.current = updatedData;
    await setUserLectureData(updatedData);
    if (lastTime > savedTime) {
      setSavedTime(lastTime);
    }
    if (existingIndex !== -1) {
      const updatedList = [...user.lectures];
      updatedList[existingIndex] = updatedData;
      setUser({
        ...user,
        lectures: updatedList
      })
    } else {
      setUser({
        ...user,
        lectures: [...user.lectures, updatedData]
      })
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      const message = '변경사항을 저장하지 않을 수 있습니다. 정말로 나가시겠습니까?';
      event.returnValue = message;
      // return message;
      alert(message);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      handleEduData(updatedUserLectureData.current)
      setVideoData(updatedUserLectureData.current);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [])

  if (targetVideo) { 
    return (
      <div className="bg-white h-screen flex-grow overflow-auto pb-20">
        <div className="bg-black text-white text-2xl text-left font-bold px-3 flex flex-grow justify-between">
          <Link to="/edu">뒤로</Link>
          <p>{targetVideo.title}</p>
        </div>
        <VideoPlayer src={targetVideo.src} time={savedTime} prevPlayedTime={prevPlayedTime} setTime={timeUpdated} />
        <div className="text-left text-black p-4">
          <p className="text-xl font-bold">{targetVideo.title}</p>
          <p className="mb-5">{targetVideo.desc}</p>
          <p className="text-right">{targetVideo.tutor} 강사</p>
        </div>
      </div>
    );
  }
}

export default EduDetail;