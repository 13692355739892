import React, { useState, useEffect } from 'react';

export const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].replace(' ', '');
          //var cookie = jQuery.trim(cookies[i]); 당신이 만약 jQuery를 사용한다면, 위 코드 대신 이 코드를 사용하여도 좋다
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
const CSRFToken = ({isExist}) => {
    const [token, setToken] = useState(getCookie('csrftoken'));
    useEffect(() => {
        if (isExist) {
            const res = getCookie('csrftoken');
            setToken(res)
        }
    }, [isExist])
    if (token) {
        return(
            <input type="hidden" name="csrfmiddlewaretoken" value={token}/>
        )
    }
};

export default CSRFToken