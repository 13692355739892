import React, {useState, useEffect} from 'react';
import { setLocal, delLocal } from '../../services/mapAdmin';
import { getLocal } from '../../services/map';
import utils from '../common/Utils';
import DataList from '../common/DataList';
import Alert from '../common/Alert';
import DownloadBtn from '../common/DownloadBtn';
import UploadBtn from '../common/UploadBtn';
import ReflectBtn from '../common/ReflectBtn';
import Loader from '../common/Loader';

function TabCharacter(props) {
  const [src, setSrc] = useState([]);
  const [storedArray, setStoredArray] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [uploadedArray, setUploadedArray] = useState([]);
  const [delArray, setDelArray] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [errPhrase, setErrPhrase] = useState('에러가 발생했습니다.');
  const [isLoading, setIsLoading] = useState(false);

  const dataUploaded = async (data) => {
    console.log(data);
    setIsLoading(true);
    const tempErrs = [];
    const tempDups = [];
    const tempUploaded = [];
    const storedMap = new Map(storedArray.map(item => [item['축제명'], item]));
    try {
      for (const item of data) {
        for (const key in item) {
          if (item[key] === "null") {
            item[key] = "";
          }
        }
        const isDup = storedMap.has(item['축제명']);
        if (isDup) {
          tempDups.push(item['축제명']);
          continue;
        }
        const dateParts = item['축제기간'] ? item['축제기간'].split("~") : null;
        if (isNaN(utils.excelSerialToDate(item['축제시작일자'] ?? dateParts[0])) || isNaN(utils.excelSerialToDate(item['축제종료일자'] ?? dateParts[1]))) {
          tempErrs.push(item['축제명']);
          continue;
        }
        tempUploaded.push({
          ...item,
          축제시작일자: item['축제시작일자'] ?? dateParts[0],
          축제종료일자: item['축제종료일자'] ?? dateParts[1],
          관리번호: '',
          접수일자: utils.dateFormatter(new Date()),
        });
      }
    } catch (error) {
      setErrPhrase(`에러가 발생했습니다. 데이터를 확인해주세요.`);
      setIsLoading(false);
      return setIsErr(true);
    }
    setIsLoading(false);
    if (tempErrs.length || tempDups.length) {
      setErrPhrase(`다음 데이터는 보여지지 않습니다 \n\n날짜가 정확하지 않습니다: \n${tempErrs.join('\n')}\n\n이미 등록된 축제입니다: \n${tempDups.join('\n')}`);
      setIsErr(true);
    }
    setSrc(tempUploaded);
    setUploadedArray(tempUploaded);
    if (tempUploaded.length) setIsActive(true);
  }

  const onSelectedArrayChanged = async (item) => {
    setIsActive(true);
    const isExisted = selectedArray.some((existingItem) => existingItem['축제명'] === item['축제명']);
    if (isExisted) {
      setDelArray([...delArray, item['관리번호']]);
      setSelectedArray(selectedArray.filter((existingItem) => existingItem['축제명'] !== item['축제명']));
    } else {
      const filteredArr = delArray.filter((existingItem) => existingItem !== item['관리번호']);
      setDelArray(filteredArr);
      setSelectedArray([...selectedArray, item]);
    }
  }

  const onUploadedArrayChanged = async (item) => {
    if (uploadedArray.includes(item)) {
      const filteredArr = uploadedArray.filter((existingItem) => existingItem !== item);
      setUploadedArray(filteredArr);
    } else {
      setUploadedArray([...uploadedArray, item]);
    }
  }

  const onSave = async(data) => {
    let updatedStoredArray = [...storedArray];
    for (let i=0; i<delArray.length; i++) {
      const response = await delLocal(delArray[i]);
      if (response === 'success') {
        updatedStoredArray = updatedStoredArray.filter(item => item['관리번호'] !== delArray[i]);
      }
    }
    if (uploadedArray.length) {
      const convertedArr = uploadedArray.map(x => {
        for (const key in x) {
          if (x[key] === "null") {
            x[key] = "";
          }
        }
        return ({
          event_id: x['관리번호'] || '',
          get_date: utils.dateFormatter(new Date()),
          start_date: utils.dateFormatter(utils.excelSerialToDate(x['축제시작일자'])),
          end_date: utils.dateFormatter(utils.excelSerialToDate(x['축제종료일자'])),
          addr1: x['시군'],
          category: x['구분'],
          title: x['축제명'],
          place: x['장소'],
          road: x['주소'] || '',
          reason: x['비고'] || ''
        })
      })
      const result = await setLocal(convertedArr);
      if (result) {
        const uploadNos = new Set(uploadedArray.map(item => item['축제명']));
        setSrc(src.filter((item) => !uploadNos.has(item['축제명'])));
        setStoredArray([...updatedStoredArray, ...uploadedArray]);
        setSelectedArray([...updatedStoredArray, ...uploadedArray]);
        setUploadedArray([]);
      }
    } else {
      setSelectedArray(updatedStoredArray);
      setStoredArray(updatedStoredArray);
      if (delArray.length < 1) setIsEmpty(true);
    }
  }
  const getFestivalData = async() => {
    const res = await getLocal('', 'all');
    const reversedArr = res.map(item => {
      return ({
        관리번호: item.event_id,
        접수일자: item.get_date,
        축제시작일자: item.start_date,
        축제종료일자: item.end_date,
        시군: item.addr1,
        구분: item.category,
        축제명: item.title,
        장소: item.place,
        주소: item.road,
        비고: item.reason,
      })
    })
    setStoredArray(reversedArr);
    setSelectedArray(reversedArr);
  }
  useEffect(() => {
    getFestivalData();
  }, [])
  
  return (
    <div className="flex flex-grow">
      {isLoading && <div className='absolute z-50 w-full h-full bg-black opacity-30'><Loader className='opacity-100'></Loader></div>}
      <div className="flex flex-col px-20">
      <span className="flex-1">
        <div className="flex flex-wrap justify-between gap-2 bg-white mt-6 p-3">
          <div className='flex flex-wrap mb-0 w-full'>
            <p className="bg-white text-sm text-left break-all">&#8251; 
              <span className='bg-red-100 text-sm break-all '>축제 기간이 지난 데이터</span>입니다.
            </p>
          </div>
          <div className="flex gap-1 items-center" >
            <img src={utils.character} alt="local data" width={'24px'}/>
            <p className="text-lg font-bold">지역특성데이터</p>
          </div>
          <DownloadBtn text={'지역 특성 데이터 양식'} />
          <div className="w-full flex gap-2 justify-end">
            <UploadBtn text={'지역 특성 데이터 업로드'} onComplete={dataUploaded}/>
            <ReflectBtn text={'지역특성'} onClick={onSave} isActive={isActive} setIsActive={(v) => setIsActive(v)}/>
          </div>
        </div>
        <div className="bg-white">
          <DataList parent={'local'} source={src} selectedArray={uploadedArray} onChange={onUploadedArrayChanged} />
        </div>
        </span>
        <span className="flex-1">
          <div className="flex gap-1 p-4 items-center bg-white mt-6 p-3">
            <img src={utils.car} alt="patrol car" width={'24px'}/>
            <p className="text-lg font-bold">지역특성 적용 지점</p>
          </div>
          {/* <button onClick={getFlexPoints} >조회</button> */}
          <div className="bg-white">
            {selectedArray.length ? (
                <DataList parent={'local'} source={storedArray} selectedArray={selectedArray} onChange={onSelectedArrayChanged}/>
              ) : ''}
          </div>
        </span>
      </div>
      {isEmpty && <Alert type="error" message="변경 사항이 없습니다." onClose={() => setIsEmpty(false)}/>}
      {isErr && <Alert type="error" message={errPhrase} onClose={() => setIsErr(false)}/>}
    </div>
  );
}

export default TabCharacter;