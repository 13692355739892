import React, {useState} from 'react';


function ConfirmReport({reason, setReason}) {
  const valueChanged = (e) => {
    const value = e.target.value;
    setReason(value);
  }
  return (
    <div className="p-2 z-50">
      <p className="text-lg font-bold mb-3">정말 신고하시겠습니까?</p>

      <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">신고 사유</label>
      <textarea id="message" onChange={valueChanged} value={reason} rows="4" className="resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo08 focus:border-indigo08 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo08 dark:focus:border-indigo08" placeholder="신고 사유를 작성해주세요"></textarea>

    </div>
  );
}

export default ConfirmReport;