import React from 'react';

function Company(props) {
  return (
    <div>
      <div className="footer-row"><p>상호명: 테바소프트(주)</p><p>대표자명: 오정섭</p><p>주소: 대전 유성구 대덕대로512번길 20</p><p>통신판매신고: 제2022-대전유성-0475호</p><p>이메일: tebahsoft@tebahsoft.com</p><p>고객센터: 042-864-5566</p><p>사업자등록번호: 440-87-02207</p><p>개인정보책임: 오정섭(070-4456-7100)</p></div>
      <div className="" style={{fontSize: '14px', textAlign: 'right'}}><p>COPYRIGHT © TEBAH SOFT. 2021. ALL RIGHTS RESERVED.</p></div>
    </div>
  );
}

export default Company;