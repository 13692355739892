import React, { useEffect, useState } from 'react';
import Festival from './TabLocal/Festival';
import { getLocal } from '../../services/map';

function TabLocal(props) {
  const [festivalData, setFestivalData] = useState([]);
  const getFestivalData = async() => {
    const res = await getLocal('', 'all');
    setFestivalData(res.sort((a, b) => (a.end_date > b.end_date ? -1 : 1)))
  }
  useEffect(() => {
    getFestivalData();
  }, [])
  return (
    <div className="bg-white h-screen flex-grow pb-20 flex flex-col overflow-y-auto">
      <div className="flex-grow overflow-auto mb-20 h-full">
        {festivalData.map((item, idx) => {
          return (
            <Festival key={item+idx} data={item}/>
          )
        })}
      </div>
    </div>
  );
}

export default TabLocal;