import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAd } from '../../contexts/AdContext';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../styles/swiper.css';
import { logoImg } from './Utils';

function SwiperComponent(props) {
  const {adArr} = useAd();
  if (adArr && adArr.length) {
    return (
      <Swiper
          cssMode={true}
          navigation={true}
          pagination={{ clickable: true }}
          mousewheel={true}
          keyboard={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {adArr.map((article, idx) => {
            return (
              <SwiperSlide key={article.contents+idx}>
                <a href={article.contents}>
                  <img src={article.files.path ? article.files.path + article.files.files[0] : logoImg} alt={article.title} />
                </a>
              </SwiperSlide>
              )
          })}
        </Swiper>
    );
  }
}

export default SwiperComponent;