import cctv from "../../assets/images/cctv.svg"
import cctvFill from "../../assets/images/cctvFill.svg"
import light from "../../assets/images/light.svg"
import lightFill from "../../assets/images/lightFill.svg"
import police from "../../assets/images/police.svg"
import policeFill from "../../assets/images/policeFill.svg"
import whome from "../../assets/images/whome.svg"
import whomeFill from "../../assets/images/whomeFill.svg"

import map from "../../assets/tab/map.svg"
import profile from "../../assets/tab/report.svg"
import path from "../../assets/tab/path.svg"
import edu from "../../assets/tab/edu.svg"
import mapActive from "../../assets/tab/map-active.svg"
import profileActive from "../../assets/tab/report-active.svg"
import pathActive from "../../assets/tab/path-active.svg"
import eduActive from "../../assets/tab/edu-active.svg"

import logo from "../../assets/images/spatrol_logo.svg"
import filledLogo from "../../assets/images/spatrol_logo_indigo.svg"
import title from "../../assets/images/spatrol_title.svg"
import text from "../../assets/images/spatrol_text.svg"
import textIndigo from "../../assets/images/spatrol_text_indigo.svg"
import textWhite from "../../assets/images/spatrol_text_white.svg"
import mainImg from "../../assets/images/spatrol_main.png"
import star from "../../assets/images/star.png"
import channelLogo from "../../assets/images/channelLogo.png"
import back from "../../assets/images/back.svg"
import plus from "../../assets/images/plus.svg"
import del from "../../assets/images/del.svg"
import lock from "../../assets/images/LockKey.svg"
import unlock from "../../assets/images/LockKeyOpen.svg"
import open from "../../assets/images/open.svg"
import logout from "../../assets/images/logout.svg"
import logoutFill from "../../assets/images/logoutFill.svg"
import target from "../../assets/images/target.svg"
import gps from "../../assets/images/gpsSign.png"
import car from "../../assets/images/PoliceCar.svg"
import bluecar from "../../assets/images/IndigoPoliceCar.svg"
import memo from "../../assets/images/memo.svg"
import edit from "../../assets/images/edit.svg"
import trash from "../../assets/images/trash.svg"
import person from "../../assets/images/person.svg"
import character from "../../assets/images/character.svg"
import siren from "../../assets/images/siren.svg"

export const logoImg = logo;
export const filledLogoImg = filledLogo;
export const textIndigoImg = textIndigo;
export const titleImg = title;
export const memoImg = memo;

const getMainTabImg = () => {
  return [map, path, edu, profile];
}
export const getActiveTabImg = () => {
  return {
    map: mapActive,
    route: pathActive,
    edu: eduActive,
    profile: profileActive
  }
}

const getSafetyMapImg = () => {
  return [{name: 'CCTV', val: 'cctv', img: cctv}, {name: '지구대', val: 'police', img: police}, {name: '스마트보안등', val: 'light', img: light}, {name: '여성안심귀갓길', val: 'whome', img: whome}]
}
const getActiveSafetyMapImg = () => {
  return [{name: 'CCTV', img: cctvFill}, {name: '지구대', img: policeFill}, {name: '스마트보안등', img: lightFill}, {name: '여성안심귀갓길', img: whomeFill}]
}

const getSafetyMapColors = () => {
  return ['bg-cctv','bg-indigo06','bg-light','bg-whome']
}

const getSafetyMapBorders = () => {
  return ['border-teal-500','border-indigo05','border-amber-300','border-rose-400']
}

export const addZero = (n) => {
  return n<10? '0'+n : String(n);
}

export const excelSerialToDate = (serial) => {
  if (typeof serial === 'number') {
    const excelStartDate = new Date(Date.UTC(1899, 11, 30));
    const daysInMillis = serial * 86400 * 1000;
    const date = new Date(excelStartDate.getTime() + daysInMillis);
    return date;
  } else {
    return new Date(serial);
  }
}

export const dateFormatter = (date) => {
  return (date.getFullYear() + '-' + addZero(date.getMonth() + 1) + '-' + addZero(date.getDate()));
}

export const stringToDateFormatter = (string) => {
  return (string.substring(0, 4) + "-" + string.substring(4, 6) + "-" + string.substring(6, 8));
}

function convertToTwoDigits(num) {
  return num < 10 ? `0${parseInt(num)}` : num;
}


export const getWeekAfterDate = (date) => {
  const targetDate = date ? date : new Date();
  const nextWeek = new Date(targetDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  const formattedNextWeek = dateFormatter(nextWeek);
  return formattedNextWeek;
}

let headerTitle = '자율방범 순찰';
export const getHeaderTitle = () => {
  return headerTitle;
}

export const getUserCategory = (type='full') => {
  let category;
  if (type === 'full') {
    category = {'ID': 'username', '성': 'first_name', '이름': 'last_name',  '구분': 'role', '시도': 'belong1', '시군구': 'belong2', '방범대': 'belong3', '전화번호': 'phone', '교육현황(분)': 'learned_time', '가입일': 'date_joined', '최근접속일': 'last_login', '승인': 'accept'};
  } else if (type === 'skim') {
    category = {'ID': 'username', '이름': 'first_name', '구분': 'role', '자율방범대': 'belong3', '전화번호': 'phone', '교육현황(분)': 'learned_time', '최근접속일': 'last_login', '승인': 'accept'};
  }
  return category;
}

export const getCur = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      },
      (error) => {
        reject(error);
      }
    );
  });
}

const exportedObject = {
  getMainTabImg,
  getActiveTabImg,
  getSafetyMapImg,
  getActiveSafetyMapImg,
  getSafetyMapColors,
  getSafetyMapBorders,
  getCur,
  addZero,
  excelSerialToDate,
  dateFormatter,
  convertToTwoDigits,
  star,
  logoImg,
  filledLogo,
  title,
  text,
  textIndigo,
  textWhite,
  mainImg,
  channelLogo,
  back,
  plus,
  del,
  lock,
  unlock,
  open,
  logout,
  logoutFill,
  target,
  gps,
  car,
  bluecar,
  memo,
  edit,
  trash,
  person,
  character,
  siren,
}

export default exportedObject;