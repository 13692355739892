import React, {useRef, useState, useEffect} from 'react';
import ReactPlayer from 'react-player/lazy';


function VideoPlayer({src, time, prevPlayedTime, setTime}) {
  const videoRef = useRef();
  const [curTime, setCurTime] = useState(time);
  const [playCount, setPlayCount] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);

  const onReady = React.useCallback(() => {
    if (!isReady) {
      if (time) videoRef.current.seekTo(time, "seconds");
      setIsReady(true);
      setDuration(videoRef.current.getDuration());
    }
  }, [isReady]);
  const onProgress = (e) => {
    setCurTime(e.playedSeconds);
    setPlayCount((prev) => prev + 1);
    if (playCount > 0) {
      setTime(playCount, curTime, prevPlayedTime + playCount >= duration * 0.9);
    }
  }
  return (
    <div className="aspect-video">
      <ReactPlayer
        url={src}
        ref={videoRef}
        muted
        controls
        playing={false}
        width={"100%"}
        height={"100%"}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload", // 다운로드 버튼 비활성화
            },
          },
        }}
        onReady={onReady}
        onProgress={onProgress}
      />
    </div>
  );
}

export default VideoPlayer;