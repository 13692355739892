import React, { useEffect, useState, useRef } from 'react';
import { read, utils } from 'xlsx';
import downloadImg from '../../assets/images/download.svg';
import flexSample from '../../assets/docs/patrol_data_sample.xlsx';
import localSample from '../../assets/docs/local_data_sample.xlsx';
import Loader from './Loader';
import Alert from './Alert';

function DownloadBtn(props) {
  const [text, setText] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [failMsg, setFailMsg] = useState('');
  const [isParsing, setIsParsing] = useState(false);
  const inputRef = useRef(null);
  const tableRows = ['grid_id', 'prdt_ymd', 'dd_all_crim_dgrsd_val'];
  useEffect(() => {
    setText(props.text);
    ['탄력 순찰 데이터 양식', '지역 특성 데이터 양식'].includes(props.text) ? setIsParsing(true) : setIsParsing(false);
  }, [props.text]);

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="col-span-6 md:col-span-1">
      {isError ? <Loader /> : ''}
      {isSuccess ? <Alert type={'success'} message={'파일이 업로드되었습니다.'} onClose={() => setIsSuccess(false)}/> : ''}
      {isFail ? <Alert type={'error'} message={`업로드에 실패했습니다.\n ${failMsg}`} onClose={() => setIsFail(false)}/> : ''}
      <a href={props.text === '탄력 순찰 데이터 양식' ? flexSample : localSample} download={props.text}>
        <button className="w-full hover:bg-indigo01 font-bold py-1 px-4 flex justify-center align-center rounded relative">
          <img src={downloadImg} alt="upload file" width={"18px"} className="mr-2"/>
          <p className="inline-block text-black leading-5 text-sm">{text}</p>
        </button>
      </a>
    </div>
  );
}

export default DownloadBtn;