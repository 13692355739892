import React, {useEffect, useState} from 'react';
import Overlay from '../common/Overlay';
import UploadBtn from '../common/UploadBtn';
import ButtonsRow from '../common/ButtonsRow';
import Alert from '../common/Alert';
import SafetyOptions from '../main/TabMap/SafetyOptions';
import { getPath, toLatlang, toAddress } from '../../services/map';
import { getMemo, getVideo } from '../../services/mapMain';
import { setGrid, setPath } from '../../services/mapAdmin';
import MonthSelect from './tabPrecas/MonthSelect';
import DaySelect from './tabPrecas/DaySelect';
import Maps from '../common/Maps';
import { useMapContext } from '../../contexts/MapContext';
import { useUser } from '../../contexts/UserContext';
import utils, { addZero } from '../common/Utils';
import { usePatrolMemo } from '../../contexts/PatrolMemoContext';

function getLastDayOfMonth(date) {
  const nextMonth = new Date(date.year, date.month);
  const lastDay = new Date(nextMonth - 1);
  return lastDay.getDate();
}

function TabPrecas(props) {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth()+1;
  const day = today.getDate();
  const {precasDate} = useMapContext();
  const {user} = useUser();
  const {setPatrolMemo} = usePatrolMemo();
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [monthsArray, setMonthsArray] = useState();
  const [selectedDay, setSelectedDay] = useState(day);
  const [lastDay, setLastDay] = useState(30);
  const [isDayChanged, setIsDayChanged] = useState(false);
  const [isProcessing, setIsProcessing] = useState(0); // 0: default, 수정 취소 1: 수정 중 2: 저장
  const [changedArray, setChangedArray] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isError, setIsError] = useState({state: false, type: 'error', message:'에러가 발생했습니다'})
  const [isOpen, setIsOpen] = useState(0);
  const [memoLocation, setMemoLocation] = useState();
  const [options, setOptions] = useState(utils.getSafetyMapImg().reduce((acc, option) => {
    acc[option.name] = false;
    return acc;
  }, {}))


  const optionClicked = (option) => {
    setOptions((prevData) => ({
      ...prevData, [option]: !prevData[option]
    }))
  }
  const setLastDayOfMonth = () => {
    const lastDayofMonth = getLastDayOfMonth({year: selectedYear, month: selectedMonth});
    setLastDay(lastDayofMonth);
  }

  const handleSelectChange = async (selectedValue) => {
    await setSelectedYear(selectedValue.year);
    await setSelectedMonth(selectedValue.month);
    setLastDayOfMonth();
    if (year === selectedValue.year && month === selectedValue.month) {
      setSelectedDay(day);
    } else {
      setSelectedDay(1);
    }
  };

  const dayChanged = (day) => {
    setSelectedDay(day);
  }

  const savePoints = () => {
    setIsProcessing(2);
  }

  const onSetArray = (data) => {
    setChangedArray(data);
  }

  const closeMemoList = async () => {
    setIsOpen(0);
    getMemoList();
  }
  const closeSafetyList = async () => {
    setIsOpen(0);
    getMemoList();
  }

  const getMemoList = async () => {
    const res = await getMemo();
    setPatrolMemo(res);
  }

  const goMemoLocation = (location) => {
    setMemoLocation({lat: location.lat, lng: location.long})
  }

  useEffect(() => {
    if (precasDate) {
      if (precasDate) {
        const dateArray = [];
        for (let i=0; i < precasDate.length; i++) {
          let aYear = Number(precasDate[i].slice(0, 4));
          let aMonth = Number(precasDate[i].slice(-2));
          dateArray.push({year: aYear, month: aMonth});
        }
        if (!dateArray.includes({year: year, month: month})) {
          setSelectedDay(1);
          setSelectedMonth(dateArray[0].month);
          setSelectedYear(dateArray[0].year);
        }
        setMonthsArray(dateArray);
      }
    }
  }, [precasDate])
  useEffect(() => {
    getMemoList();

  }, [user])
  useEffect(() => {
    setLastDayOfMonth();
  }, [])

  useEffect(() => {
    if (changedArray.length !== 0) {
      const filteredArray = changedArray.map(x => {
        return {
          grid: x.grid,
          long: x.long,
          lat: x.lat
        }
      })
      setGrid(filteredArray).then(() => {
        setChangedArray([]);
        setIsProcessing(0);
        setIsChanged(false);
      })
    }
  }, [changedArray])

  return (
    <div className="flex-grow">
      {isError.state ? (<Alert type={isError.type} message={isError.message} onClose={() => {setIsError({state: false})}} />) : ''}
      <div className="bg-white">
        <div className="grid grid-cols-6 p-3">
          <MonthSelect months={monthsArray} onSelectChange={handleSelectChange} selectedYear={selectedYear} selectedMonth={selectedMonth} selectedDay={selectedDay} />
          <DaySelect lastDay={lastDay} selectedDay={selectedDay} onDayChange={(day) => dayChanged(day)} />
          <UploadBtn text={'Precas'} setIsChanged={() => setIsChanged(true)}/>
        </div>
        <div className="grid grid-cols-6 p-3">
        <ul className={`col-span-6 -mt-2 pl-auto `}>
          <li className="text-right">
            {isProcessing ? (
              <ButtonsRow onClose={() => setIsProcessing(0)} onConfirm={savePoints} disabled={!isChanged} />
            ): (
              <div>
                <button className="mr-4 px-2 border-b-4 rounded border-indigo08 hover:bg-indigo01"
                  onClick={() => setIsOpen(1)}>
                    안심지도 업로드
                </button>
                <button className="mr-4 px-2 border-b-4 rounded border-indigo08 hover:bg-indigo01"
                  onClick={() => setIsOpen(2)}>
                    순찰 메모
                </button>
                <button className="px-2 border-b-4 rounded border-indigo08 hover:bg-indigo01"
                        onClick={() => setIsProcessing(1)}>지점 수정</button>
              </div>
            )}
          </li>
        </ul>
        </div>
        <Overlay type="safety_upload" isOpen={isOpen === 1} title="안전지도" onClose={closeSafetyList} />
        <Overlay type="patrol_memo" isOpen={isOpen === 2} title="순찰메모목록" onClose={closeMemoList} onMemoClick={goMemoLocation} />
        <div className="mt-2">
          <SafetyOptions safetymapOption={options} onOptionClick={optionClicked}/>
        </div>
        <Maps layer={'grid'} date={`${selectedYear}-${addZero(selectedMonth)}-${addZero(selectedDay)}`} isDayChanged={isDayChanged} isPoint={true} isProcessing={isProcessing} onChanged={setIsChanged} setChangedArray={(data) => onSetArray(data)} memoLocation={memoLocation} safetymapOption={options} onOptionClick={optionClicked}/>
      </div>
    </div>
  );
}

export default TabPrecas;