import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import utils, { dateFormatter, getWeekAfterDate } from '../../common/Utils';
import { ColoredButton, BorderedButton } from '../../common/Button';
import SetRegion from '../../common/SetRegion';
import Alert from '../../common/Alert';
import { mobileInput, inputLabel } from '../../../styles';
import { getNews, setNews, pushNews } from '../../../services/mapAdmin';
import "react-datepicker/dist/react-datepicker.css";
import '../../../styles/utils.css';

const newsTypeList = [
  '공지'
]
const adTypeList = [
  '광고'
]
function NewsEditor(props) {
  const [id, setId] = useState();
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [attached, setAttached] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [filePath, setFilePath] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [type, setType] = useState('');
  const [addr, setAddr] = useState({belong1: '전라북도', belong2: '', belong3: ''});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [completeMsg, setCompleteMsg]= useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alert2Msg, setAlert2Msg] = useState('');
  const [isAlert2Open, setIsAlert2Open] = useState(false);

  const clearNewsDetail = async() => {
    setTitle('');
    setContents('');
    setType('공지');
    setStartDate(new Date());
    setEndDate(new Date());
    setFilePath('');
    setAttached([]);
    setDeleted([]);
    setAddr({belong1: '전라북도', belong2: '', belong3: ''})
    setSelectedFiles([]);
  }

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'title':
        setTitle(e.target.value);
        break;
      case 'contents':
        setContents(e.target.value);
        break;
      case 'pic':
        const files = Array.from(e.target.files);
        console.log(files);
        setSelectedFiles(files);
        break;
      case 'type':
        setType(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const removeFile = (index, src) => {
    if (src === 'selected') {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
    } else if (src === 'attached') {
      setDeleted([...deleted, attached[index]]);
      const updatedFiles = [...attached];
      updatedFiles.splice(index, 1);
      setAttached(updatedFiles);
    }
  };
  const openFileInput = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };

  const updateType = async () => {
    const updatedType = props.target === '공지' ? newsTypeList[0] : adTypeList[0];
    setType(updatedType);
  }

  const onClick = async () => {
    const data = {
      id: id || '',
      created_at: startDate ? dateFormatter(startDate) : dateFormatter(new Date()),
      close_at: dateFormatter(endDate),
      title: title,
      contents: contents,
      type: type,
      attach_files: [...selectedFiles],
      delete_files: [...deleted],
      belong1: addr.belong1,
      belong2: addr.belong2 === '전체' ? '' : addr.belong2,
      patrol: addr.belong3 === '전체' ? '' : addr.belong3
    }
    const res = await setNews(props.target === '공지' ? 'news' : 'adver', data);
    console.log(res, res ? 't' : 'f');
    if (res) {
      if (props.mode === '수정') {
        props.onCreate(prev => {
          return prev.map(item => {
            return item.id === id ? data : item;
          });
        });
      } else {
        data.id = res;
        data.attached = (selectedFiles.length || attached.length > deleted.length) ? 1 : 0;
        props.onCreate(prev => [...prev, data])
        clearNewsDetail();
      }
      setCompleteMsg(`${props.target} ${props.mode}이 완료되었습니다.`)
      setIsCompleted(true);
    } else {
      setCompleteMsg(`${props.target} ${props.mode} 에러가 발생했습니다.`)
      setIsCompleted(true);
    }
  }

  const onAppPush = async () => {
    setIsAlertOpen(true);
  }

  const onAppPushConfirm = async (data) => {
    setIsAlertOpen(false);
    const res = await pushNews({title: title, contents: contents, channel: data});
    await onClick();
    if (res) {
      await setAlert2Msg('푸시 메시지가 전송되었습니다.')
    } else {
      await setAlert2Msg('푸시 메시지 에러가 발생했습니다.')
    }
    setIsAlert2Open(true);
  }


  const getNewsDetail = async(id) => {
    let res;
    if (window.location.origin === 'http://localhost:3000') {
      res = await {
            "id": 5,
            "created_at": "2023-12-19",
            "close_at": "2023-12-27",
            "title": "공지입니다",
            "contents": "오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다 오늘은 공지를 테스트해보는 날입니다",
            "belong2": "전주시 완산구",
            "files": "{'files': ['BAND_512.png', 'twitter.png'], 'path': 'https://kr.object.ncloudstorage.com/spatrol-test/files/2023/12/20/police001/'}",
            "attached": 1
      }
    } else {
      res = await getNews(Number(id), props.target==='공지' ? 'news' : 'adver', addr);
    }
    if (res) {
      setTitle(res.title);
      setContents(res.contents);
      if (res.type) {
        setType(res.type);
      } else {
        updateType();
      }
      setStartDate(new Date(res.created_at));
      setEndDate(new Date(res.close_at));
      const fileData = JSON.parse(res.files.replace(/'/g, '"'));
      setFilePath(fileData.path);
      setSelectedFiles([]);
      setAttached(fileData.files);
      setAddr({belong1: '전라북도', belong2: res.belong2, belong3: res.patrol || ''})
    }
  }

  const setTargetRegion = async(data) => {
    setAddr(data);
  }

  useEffect(() => {
    if (!error && title && contents && type && endDate) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [title, contents, type, error, endDate]);
  useEffect(() => {
    if (props.mode === '수정' && props.id) {
      setId(props.id);
      getNewsDetail(props.id);
    } else if (props.mode === '등록') {
      clearNewsDetail();
    }
  }, [props.mode, props.id])
  useEffect(() => {
    updateType();
  }, [props.target])
  useEffect(() => {
    if (props.mode !== '수정') {
      clearNewsDetail();
    }
  }, [props.clearDetail])

  return (
    <div className="bg-white h-max max-h-[80vh] mx-auto overflow-auto">
      <div className="relative py-3">
        <p className="text-center font-bold text-lg">
          {props.target} {props.mode}
        </p>
      </div>
      <div className="flex flex-col p-2 pb-0 pr-6 gap-2">
        <span className="grid grid-cols-4">
          <label htmlFor="select" className={`${inputLabel} col-span-1`}>제목</label>
          <div className="col-span-3 w-full">
            <input type='text' name="title" value={title} onChange={handleInputChange} className={`${mobileInput} w-full`} placeholder='제목을 입력해주세요' />
          </div>
        </span>
        <span className="grid grid-cols-4">
          <label htmlFor="select" className={`${inputLabel} col-span-1`}>{props.target === '공지' ? '내용' : 'URL'}</label>
          <div className="col-span-3 w-full">
            {props.target === '공지' && <textarea name="contents" value={contents} onChange={handleInputChange} className={`${mobileInput} w-full min-h-[280px] `} placeholder='내용을 입력해주세요'>
            </textarea>}
            {props.target === '광고' && (
              <span>
                <input type="text" name="contents" value={contents} onChange={handleInputChange} className={`${mobileInput} w-full`} placeholder='광고 클릭시 이동할 url을 입력해주세요'></input>
                <p className="text-sm text-gray-600 text-left">http:// 혹은 https://로 시작해주세요</p>
              </span>
            )}
          </div>
        </span>
      </div>
      <div className="flex flex-col p-2 pr-6 gap-2">
      {props.target === '광고' && <span className="grid grid-cols-4">
          <label className={`${inputLabel} col-span-1`} onClick={openFileInput}>사진</label>
          <div className="col-span-3">
            { (selectedFiles.length > 0 || attached.length > 0)  ?
              <ul className="w-full max-h-[300px] overflow-auto">
                {selectedFiles.map((file, index) => (
                  <li key={index} className="flex flex-col">
                    <span className="flex justify-between align-center">
                      <p className="truncate">{file.name || 'file'+index}</p>
                      <img
                        src={utils.del}
                        alt="Remove"
                        onClick={() => removeFile(index, 'selected')}
                        className="ml-2 h-4 w-4 cursor-pointer"
                      />
                    </span>
                    <span>
                    <img
                        src={URL.createObjectURL(file)}
                        alt={file.name || 'file' + index}
                        onClick={() => removeFile(index, 'selected')}
                        className="w-80 cursor-pointer mx-auto"
                      />
                    </span>
                  </li>
                ))}
                {attached.map((file, index) => (
                  <li key={index} className="flex flex-col items-center mb-4">
                    <span>
                      <span className="flex items-center">
                        <p className="truncate">{file}</p>
                        <img
                          src={utils.del}
                          alt="Remove"
                          onClick={() => removeFile(index, 'attached')}
                          className="ml-2 h-4 w-4 cursor-pointer"
                        />
                      </span>
                    <img
                        src={filePath + attached[index]}
                        alt={file.name || 'file' + index}
                        onClick={() => removeFile(index, 'attached')}
                        className="border p-2 w-80 cursor-pointer mx-auto"
                      />
                    </span>
                  </li>
                ))}
              </ul>
            : <p className="text-left text-gray-600 text-sm">정사각형의 이미지를 첨부해주세요</p>}
            <ColoredButton type="custom" image="plus" text="사진 첨부" click={openFileInput}/>
          </div>
          <input 
            type='file'
            id="fileInput" name="pic"
            accept=".jpg, .png"
            className="hidden"
            onChange={handleInputChange}
          />
        </span>}
        <span className="grid grid-cols-4">
          <label htmlFor="select" className={`${inputLabel} col-span-1`}>유형</label>
          <select id="select" name="type" value={type} onChange={handleInputChange} className="col-span-3 border rounded px-3 py-2 w-full">
            {(props.target === '공지' ? newsTypeList : adTypeList).map(x => (
              <option key={x} value={x}>{x}</option>
            ))}
          </select>
        </span>
        {props.target === '공지' && <span className="grid grid-cols-4">
          <label htmlFor="select" className={`${inputLabel} col-span-1`}>지역</label>
          <div className="col-span-3 w-full">
            <SetRegion addr={addr} setTargetRegion={setTargetRegion} />
          </div>
        </span>}
        <span className="grid grid-cols-4">
          <label htmlFor="endDate" className={`${inputLabel} col-span-1`}>기간</label>
          <div className="col-span-3 w-full datePickerWrapper">
            <DatePicker 
              id="endDate" className={`${mobileInput} w-full text-center`} name="endDate"
              selected={startDate} onChange={handleDateChange}
              startDate={startDate} endDate={endDate}
              selectsRange
              />
          </div>
          {/* <input id="endDate" type="text" name="endDate" value={endDate} onChange={handleInputChange} className={`${mobileInput} col-span-3`} placeholder="2024-01-01" /> */}
          <p className="col-start-2 col-span-3 text-left">{error}</p>
        </span>
      </div>
      <div className="p-4 flex flex-col gap-2">
        <ColoredButton type="custom" text={props.mode+ '하기'} click={onClick} disabled={isDisabled}/>
        {props.target === '공지' && <BorderedButton type="custom" text={props.mode+'및 푸시 알림 보내기'} click={onAppPush} disabled={isDisabled}/>}
      </div>
      {isCompleted && <Alert
        type='custom'
        onClose={() => setIsCompleted(false)}
        message={props.target + ' ' + props.mode + '이 완료되었습니다.'}/>}
      {isAlertOpen && <Alert
        type='custom'
        selection={{'all': '전체', 'patroller': '자율방범대원', 'police': '경찰'}}
        onConfirm={onAppPushConfirm}
        onClose={() => setIsAlertOpen(false)}
        message={'에게 푸시 메세지를 보내시겠습니까?'}/>}
      {isAlert2Open && <Alert
        type='custom'
        onClose={() => setIsAlert2Open(false)}
        message={alert2Msg}/>}
    </div>
  );
}

export default NewsEditor;