import React from 'react';
import { Link } from 'react-router-dom';
import BorderLabel from '../../common/BorderLabel';
import utils, {dateFormatter} from '../../common/Utils';

const festival = {
  "event_id" : "<ID>",   // blank이면신규, 아니면 수정
  "get_date": "2023-07-30",
  "start_date": "2023-08-01",
  "end_date": "2023-09-02",
  "addr1": "전주", // 시군
  "category": "대표축제", // 구분
  "title":"전주비빔밥축제", // 축제명
  "place": "한옥마을 일원", // 장소
  "road": "전주시 완산구 태조로 44", // 주소
  "reason": "경기전" //비고
}

function Festival(props) {
  return (
    <div className="border-b text-left px-2 py-3 grid grid-cols-5">
      <div className={`col-span-1 flex justify-center align-center items-center`}>
        <div className={`aspect-square p-2 rounded-full bg-indigo01`}>
          <img src={utils.filledLogo} alt="logo"/>
        </div>
      </div>
      <div className="col-span-4">
        <p className="text-lg font-bold truncate">{props.data.title}</p>
        <p className="truncate text-gray06">{props.data.place}</p>
        <div className="flex justify-between datas-center mt-2">
          <p className="text-sm">{props.data.start_date} · {props.data.end_date}</p>
          <BorderLabel type={props.data.status === 0 ? 'error' : props.data.status === 1 ? 'info' : 'custom'} text={props.data.category} />
        </div>
      </div>
    </div>
  )
}

export default Festival;