import React, {useState, useEffect} from 'react';
import {Button, BorderedButton, ColoredButton} from '../common/Button';
import { filledLogoImg } from '../common/Utils';
import '../../styles/utils.css';
import { SERVER } from '../../environment';
import { getCSRF } from '../../services/auth';
import CSRFToken, {getCookie} from '../common/CSRF';
import patrolData from '../../assets/data/patrol.json';

const roleList = ['일반주민', '자율방범대'];
const roleFormList = ['citizen', 'patroller'];
const jeonbukList = ['전주시 완산구', '전주시 덕진구', '김제시', '임실군', '부안군'];
const errorMsgs = {
  'email': '이메일 형식이 맞지 않습니다',
  // 'pw': '비밀번호는 영어 대문자, 소문자, 특수문자($,@,#,%,.), 숫자의 조합으로 만들어주세요',
  'pw2': '비밀번호가 일치하지 않습니다',
  'phone': '휴대폰 번호는 숫자만 입력해주세요'
}
function Signup(props) {
  const [isCSRFExist, setIsCSRFExist] = useState(false);
  const [CSRF, setCSRF] = useState('');
  const [username, setUsername] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [role, setRole] = useState('patroller');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [addr1, setAddr1] = useState('전라북도');
  const [addr2List, setAddr2List] = useState(jeonbukList);
  const [addr2, setAddr2] = useState('');
  const [addr3List, setAddr3List] = useState(patrolData['전주시']['완산구'].map(x => x.patrol));
  const [addr3, setAddr3] = useState('');
  const [errorList, setErrorList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (e) => {
    let regex;
    switch (e.target.name) {
      case 'email':
        setMail(e.target.value.trim());
        if (errorList.includes('email')) {
          regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          if (regex.test(e.target.value)) setErrorList(errorList.filter(item => item !== 'email'));
        }
        break;
      case 'username':
        setUsername(e.target.value.trim());
        break;
      case 'password1':
        setPassword(e.target.value.trim());
        // if (errorList.includes('pw')) {
        //   regex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[$@#%.])(?=.*\d).{8,}/;
        //   if (regex.test(e.target.value))setErrorList(errorList.filter(item => item !== 'pw'));
        // }
        if (errorList.includes('pw2')) {
          if (e.target.value === password2) setErrorList(errorList.filter(item => item !== 'pw2'));
        }
        break;
      case 'password2':
        setPassword2(e.target.value.trim());
        if (errorList.includes('pw2')) {
          if (password === e.target.value) setErrorList(errorList.filter(item => item !== 'pw2'));
        }
        break;
      case 'role':
        setRole(e.target.value);
        break;
      case 'first_name':
        setFirstName(e.target.value.trim());
        break;
      case 'last_name':
        setLastName(e.target.value.trim());
        break;
      case 'phone':
        setPhone(e.target.value);
        if (errorList.includes('phone')) {
          regex = /^\d+$/;
          if (regex.test(e.target.value)) setErrorList(errorList.filter(item => item !== 'phone'));
        }
        break;
      default:
        break;
    }
  };

  const handleAddrChange = (e) => {
    let list;
    let targetPatrolData;
    switch (e.target.name) {
      case 'belong1':
        setAddr1(e.target.value);
        break;
      case 'belong2':
        setAddr2(e.target.value);
        targetPatrolData = e.target.value === '전주시 완산구'
        ? patrolData['전주시']['완산구']
        : e.target.value === '전주시 덕진구'
          ? patrolData['전주시']['덕진구']
          : patrolData[e.target.value]
        list = targetPatrolData.map(x => x.patrol);
        setAddr3(list[0])
        setAddr3List(list);
        break;
      case 'belong3':
        setAddr3(e.target.value);
        break;
      default:
        break;
    }
  }
  const checkInput = (e) => {
    let regex;
    switch (e.target.name) {
      case 'email':
        regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (regex.test(e.target.value)) setErrorList(errorList.filter(item => item !== 'email'));
        else if (!regex.test(e.target.value) && !errorList.includes('email')) {
          setErrorList([...errorList, 'email'])
        }
        break;
      // case 'password1':
      //   regex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[$@#%.])(?=.*\d).{8,}/;
      //   if (regex.test(e.target.value)) setErrorList(errorList.filter(item => item !== 'pw'));
      //   else if (!regex.test(e.target.value) && !errorList.includes('pw')) {
      //     setErrorList([...errorList, 'pw'])
      //   }
      //   break;
      case 'password2':
        if (password === e.target.value) setErrorList(errorList.filter(item => item !== 'pw2'));
        else if (password !== e.target.value && !errorList.includes('pw2')) {
          setErrorList([...errorList, 'pw2'])
        }
        break;
      case 'phone':
        regex = /^\d+$/;
        if (regex.test(e.target.value)) setErrorList(errorList.filter(item => item !== 'phone'));
        else if (!regex.test(e.target.value) && !errorList.includes('phone')) {
          setErrorList([...errorList, 'phone'])
        }
        break;
      default:
        break;
      }
  }

  const navigateToSignin = () => {
    window.location.href = `${SERVER}/accounts/login`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(mail, username, firstName, lastName, role, phone, password, password2, addr1, addr2, addr3)
    const form = e.target;
    form.submit();
  }

  const checkCSRF = async () => {
    const res = getCSRF();
    if (res) setIsCSRFExist(true);
    else console.log('csrf token is not generated')
  }

  useEffect(() => {
    if (!errorList.length && mail && username && password && password2 && firstName && lastName && phone) {
      if ((role==='patroller' && addr2 !== '.' && addr3 !== '.') || role==='citizen') {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else setIsDisabled(true);
  }, [mail, username, password, password2, firstName, lastName, phone, addr2, addr3]);

  useEffect(() => {
    checkCSRF();
  }, [])

  return (
    <form className="mt-header pt-20 md:pt-0 mx-auto w-5/6 md:w-1/2 pb-20" method="POST" action="/accounts/signup/" onSubmit={handleSubmit}>
      <CSRFToken isExist={isCSRFExist} />
      <span className="flex gap-2">
        <img src={filledLogoImg} alt="filled logo" className="w-20"/>
        <h1 className="text-left rounded-md text-3xl font-bold my-6">안녕하세요! <br/>이메일로 가입해주세요.</h1>
      </span>
      <div className="flex flex-col gap-3 justify-center content-center my-9">
        <input name="email" value={mail} onChange={handleInputChange} onBlur={checkInput} type="text" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="이메일" />
        {errorList.includes('email') ? <p className="text-gray-600 text-sm text-left -mt-3">{errorMsgs['email']}</p> : ''}
        <input name="username" value={username} onChange={handleInputChange} type="text" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="아이디" />
        {/* <ColoredButton type={'custom'} text={'아이디 중복 확인'} click={() => checkDuplication('id')} disabled={!id}/> */}
        <input name="password1" value={password} onChange={handleInputChange} onBlur={checkInput} type="password" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="비밀번호" />
        <p className={`${errorList.includes('pw') ? 'font-bold' : ''} text-gray-600 text-sm text-left -mt-3`}>{errorMsgs['pw']}</p>
        <input name="password2" value={password2} onChange={handleInputChange} onBlur={checkInput} type="password" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="비밀번호 확인" />
        {errorList.includes('pw2') ? <p className="text-gray-600 text-sm text-left -mt-3">{errorMsgs['pw2']}</p> : ''}
        <div className="flex justify-center hidden">
          {roleList.map((x, idx) => (
            <div className="items-center p-2" key={x}>
              <input type="radio" id={x} name="role" value={roleFormList[idx]} checked={role === roleFormList[idx]} onChange={handleInputChange} className="mr-2" />
              <label htmlFor={x}>{x}</label>
            </div>
          ))}
        </div>
        <input name="first_name" value={firstName} onChange={handleInputChange} type="text" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="성" />
        <input name="last_name" value={lastName} onChange={handleInputChange} type="text" className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="이름" />
        <input name="phone" value={phone} onChange={handleInputChange} type="text" onBlur={checkInput} inputMode='numeric' className="text-black p-3 rounded border focus:outline-none focus:border-indigo08" placeholder="휴대폰: 01012345678" />
        {errorList.includes('phone') ? <p className="text-gray-600 text-sm text-left -mt-3">{errorMsgs['phone']}</p> : ''}

        <div className={`${role === 'citizen' ? 'hidden' : ''}`}>
          <select id="addr1" name="belong1" value={addr1} onChange={handleAddrChange} className="hidden col-span-3 border rounded p-3 mb-3 w-full">
            <option value="" disabled>시/도</option>
            {['전라북도'].map(x => (
              <option key={x} value={role==='patroller' ? '전라북도' : '.'}>{x}</option>
            ))}
          </select>
          <select id="addr2" name="belong2" value={addr2} onChange={handleAddrChange} className="col-span-3 border rounded p-3 mb-3 w-full">
            <option value="." disabled>주소를 선택해주세요</option>
            {addr2List.map(x => (
              <option key={x} value={role==='patroller' ? x : '.'}>{x}</option>
            ))}
          </select>
          <select id="addr3" name="belong3" value={addr3} onChange={handleAddrChange} className="col-span-3 border rounded p-3 w-full">
            <option value="." disabled>방범대를 선택해주세요</option>
            {addr3List.map(x => (
              <option key={x} value={role === 'patroller' ? x.slice(0, -5) : '.'}>{x}</option>
            ))}
          </select>
        </div>
      </div>
      {errorList.length ? <p>제출할 내용을 다시 확인해주세요</p> : ''}
      <ColoredButton type={'custom'} isSubmit={true} text={'회원 가입'} disabled={isDisabled}/>
      <BorderedButton classname={'w-full mt-3 py-2'} isSubmit={false} type={'custom'} text={'기존 계정으로 로그인하기'} click={navigateToSignin} />
    </form>
  );
}

export default Signup;
