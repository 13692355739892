import { useState, useEffect } from 'react';
import { Container as 
  MapDiv, NaverMap, useNavermaps,
  Overlay, Listener, useListener,
  Marker, Polyline, Polygon,
  } from 'react-naver-maps';
import SafetyOptions from '../../main/TabMap/SafetyOptions';
import { getSafetyMapData } from '../../../services/mapMain';
import utils from '../Utils';

const safetyMapImgs = utils.getSafetyMapImg();
const colors = utils.getSafetyMapColors();
const borders = utils.getSafetyMapBorders();

const SafetyMapMarkers = ({bounds, zoomLevel, data, safetymapOption}) => {
  const navermaps = useNavermaps();
  const [mapData, setMapData]=useState([
    {
      type: 'cctv',
      locations: [],
    },
    {
      type: 'police',
      locations: [],
    },
    {
      type: 'light',
      locations: [],
    },
    {
      type: 'whome',
      locations: [],
    }
  ])

  useEffect(() => {
    if (bounds && zoomLevel >= 15) {
      const cctvData = getSafetyMapData('cctv', {lat: bounds._sw.y, long: bounds._sw.x}, {lat: bounds._ne.y, long: bounds._ne.x})
      .then(res => {
        if (res[0].type) {
          setMapData(prevMapData => {
            const updatedMapData = [...prevMapData];
            res.forEach(newItem => {
              const index = updatedMapData.findIndex(item => item.type === newItem.type);
              if (index !== -1) {
                updatedMapData[index].locations = newItem.locations;
              }
            });
            return updatedMapData;
          });
        }
      })
      const policeData = getSafetyMapData('police', {lat: bounds._sw.y, long: bounds._sw.x}, {lat: bounds._ne.y, long: bounds._ne.x})
      .then(res => {
        if (res[0].type) {
          setMapData(prevMapData => {
            const updatedMapData = [...prevMapData];

            res.forEach(newItem => {
              const index = updatedMapData.findIndex(item => item.type === newItem.type);
              if (index !== -1) {
                updatedMapData[index].locations = newItem.locations;
              }
            });
            return updatedMapData;
          });
        }
      })
      const lightData = getSafetyMapData('light', {lat: bounds._sw.y, long: bounds._sw.x}, {lat: bounds._ne.y, long: bounds._ne.x})
      .then(res => {
        if (res[0].type) {
          setMapData(prevMapData => {
            const updatedMapData = [...prevMapData];

            res.forEach(newItem => {
              const index = updatedMapData.findIndex(item => item.type === newItem.type);
              if (index !== -1) {
                updatedMapData[index].locations = newItem.locations;
              }
            });
            return updatedMapData;
          });
        }
      })
    }
  }, [bounds])

  return (
    <>
    {mapData.map((data, idx) => {
      return safetymapOption[safetyMapImgs[idx].name] ? (
      data.locations.map((latlng, index) => {
        return (<Marker
          key={latlng[0].lat+index}
          position={new navermaps.LatLng(latlng[0].lat, latlng[0].long)}
          icon={{
            content: `<img class="${colors[idx]} rounded-full p-1" src=${safetyMapImgs[idx].img} width="25px"/>`
          }}
        />)
      })): ''
    })}
    </>
  )
}
export default SafetyMapMarkers