import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { NavermapsProvider } from 'react-naver-maps';
import './styles/App.css';
import { checkLogin } from './services/auth';
import { SERVER } from './environment';
import Header from './components/core/Header';
import Main from './components/core/Main';
import PasswordChange from './components/account/PasswordChange';
import Admin from './components/core/Admin';
import Account from './components/account/Account';
import PageNotFound from './components/common/PageNotFound';
import { PatrolMemoProvider } from './contexts/PatrolMemoContext';
import { HeaderProvider } from './contexts/HeaderContext';
import { MapProvider } from './contexts/MapContext';
import { UserProvider } from './contexts/UserContext';
import { AdProvider } from './contexts/AdContext';
import { NCPID } from './environment';
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import * as FirebaseMessaging from "webtonative/Firebase/Messaging"

function App() {
  const [isLoginNeeded, setIsLoginNeeded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [userData, setUserData] = useState({"lectures": []});
  const [precasDate, setPrecasDate] = useState([]);
  const navigate = useNavigate();

  const moveToLogin = () => {
    if (!isAuthenticated) {
      if (window.location.origin !== 'http://localhost:3000') {
        window.location.href = `${SERVER}/accounts/login/`;
      } else {
        console.log('setting local storage');
        localStorage.setItem("isLoggedIn", "LOGGED_IN");
      }
    } else {
      localStorage.removeItem("isLoggedIn");
      console.log('deleting local storage');

      if (window.location.origin !== 'http://localhost:3000') {
        window.location.href = `${SERVER}/accounts/logout/`;
        setIsAuthenticated(0);
      } else {
        setIsAuthenticated(0);
      }
    }
  }

  const onChangePwClick = () => {
    navigate('/password');
  }
  const signup = () => {
    setIsLoginNeeded(true);
    window.location.href = `${SERVER}/accounts/signup`;
  }
  const checkLoginState = async() => {
    // only when coming from signup page(with submit button)
    const isRerendered = window.sessionStorage.getItem('rerender')==='1' ? true : false;
    if (isRerendered) {
      setIsLoginNeeded(true)
      window.sessionStorage.setItem('rerender', 0);
    } else {
      const res = await checkLogin();
      if (res) {
        localStorage.setItem("isLoggedIn", "LOGGED_IN");
        setPrecasDate(JSON.parse(res.crimedb));
        if (res.role === 'patroller' && res.accept === 'pass') {
          setUserData(prevUser => ({
            ...prevUser,
            role: res.role,
            id: res.user_id,
            accept: res.accept,
            belong1: res.belong1,
            belong2: res.belong2,
            patrol: {
              name: res.patrol,
              crimeVals: []
            },
            groups: res.groups,
            lat: res.lat,
            long: res.long,
            username: res.username,
            firstname: res.first_name,
            lastname: res.last_name,
            phone: res.phone,
          }))
          FirebaseMessaging.subscribe({
            toTopic: 'patroller'
          });
          setIsAuthenticated(1);
        } else if (['patroller', 'citizen'].includes(res.role)) {
          setIsAuthenticated(2);
        } else if (res.role === 'police' && res.groups.includes('policeadmin')) {
          setUserData(prevUser => ({
            ...prevUser,
            role: res.role,
            isSuperAdmin: true,
            id: res.user_id,
            accept: res.accept,
            belong1: res.belong1,
            belong2: res.belong2,
            patrol: {
              name: res.patrol,
              crimeVals: []
            },
            groups: res.groups,
            lat: res.lat,
            long: res.long,
            username: res.username,
            firstname: res.first_name,
            lastname: res.last_name,
            phone: res.phone,
          }))
          FirebaseMessaging.subscribe({
            toTopic: 'police'
          });
          setIsAuthenticated(4);
        } else if (res.role === 'police') {
          setUserData(prevUser => ({
            ...prevUser,
            role: res.role,
            isSuperAdmin: false,
            id: res.user_id,
            accept: res.accept,
            belong1: res.belong1,
            belong2: res.belong2,
            patrol: {
              name: res.patrol,
              crimeVals: []
            },
            groups: res.groups,
            lat: res.lat,
            long: res.long,
            username: res.username,
            firstname: res.first_name,
            lastname: res.last_name,
            phone: res.phone,
          }))
          FirebaseMessaging.subscribe({
            toTopic: 'police'
          });
          setIsAuthenticated(3);
        }
        await setIsLoginNeeded(false);
      }
      else {
        setIsAuthenticated(0);
        // window.location.href = `${SERVER}/accounts/login`;
      }
    }
  }

  useEffect(() => {
    FirebaseMessaging.subscribe({
      toTopic: "all"
    })
    if (isMobile) {
      if (isAndroid) {
        FirebaseMessaging.subscribe({
          toTopic: "android"
        })
      } else if (isIOS) {
        FirebaseMessaging.subscribe({
          toTopic: "ios"
        })
      }
    }
    
    if (window.location.origin !== 'http://localhost:3000') {
      checkLoginState();
    } else {
      FirebaseMessaging.subscribe({
          toTopic: "patroller"
      })
      setUserData(prevUser => ({
        ...prevUser,
        "role": "patroller",
        "id": 1,
        "accept": "pass",
        "isSuperAdmin": true,
        "patrol": {
          "name": "진북",
          "crimeVals": []
        },
        "groups": [],
        "lat": 35.8277895,
        "long": 127.1314926,
        "username": "tester",
        "belong1": "전라북도",
        "belong2": "전주시 덕진구",
        "firstname": "테스트",
        "lastname": "아이디",
        "phone": "01012345678",
        "lectures": [
          {
              "video_id": 1,
              "title": "성희롱예방교육",
              "last_play_date": "2024-08-13",
              "last_update_time": 66.781164,
              "played_time": 3,
              "state": 2
          }
      ]
      }))
      setIsAuthenticated(3);
      setIsLoginNeeded(false);
    }
  }, [])
    return (
      <NavermapsProvider 
        ncpClientId={NCPID}
      >
        <UserProvider>
          <PatrolMemoProvider>
            <HeaderProvider>
              <MapProvider>
                <AdProvider>
                <div className="App min-h-screen flex flex-col max-h-screen overflow-hidden">
                  {isLoginNeeded ? '' : <Header isAuthenticated={isAuthenticated} changeAuth={moveToLogin} signup={signup} onChangePwClick={onChangePwClick} userPatrol={userData.belong1 ? {belong1: userData.belong1, belong2: userData.belong2, belong3: userData.patrol.name} : {belong1: '', belong2: '', belong3: ''}}/>}
                  <Routes>
                    {/* {isLoginNeeded ? <Route path="/" element={<Account />} />
                    : isChangePw ? <Route path="/" element={<PasswordChange cancel={() => setIsChangePw(false)} />} />
                    : [0, 1, 2].includes(isAuthenticated)
                      ?  <Route path="/*" element={<Main userState={isAuthenticated} userData={userData} />} />
                      : [3, 4].includes(isAuthenticated)
                      ? <Route path="/*" element={<Admin userState={isAuthenticated} userData={userData} />} />
                      : <><Route path="*" element={<PageNotFound />} /></>
                    } */}
                    { isLoginNeeded ? <Route path="/" element={<Account />} />
                    : document.location.pathname === '/password' ? <Route path="/password" element={<PasswordChange cancel={() => window.history.back()} />} />
                    : [0, 1, 2].includes(isAuthenticated)
                      ?  <Route path="/*" element={<Main userState={isAuthenticated} userData={userData} />} />
                      : [3, 4].includes(isAuthenticated)
                      ? <Route path="/*" element={<Admin userState={isAuthenticated} userData={userData} precasDate={precasDate}/>} />
                      : <><Route path="*" element={<PageNotFound />} /></>
                    }
                  </Routes>
                </div>
                </AdProvider>
              </MapProvider>
          </HeaderProvider>
          </PatrolMemoProvider>
        </UserProvider>
      </NavermapsProvider>
    );
  }

export default App;
