import React, { useState, useEffect } from 'react';
import utils from '../common/Utils';
import Memo from '../main/TabRoute/Memo';
import Company from './Company';
import Privacy from './Privacy';
import Alert from './Alert';
import Loader from './Loader';
import News from '../admin/tabNews/News';
import SwiperComponent from './Swiper';
import { BorderedButton, ColoredButton } from './Button';
import { getVideo } from '../../services/mapMain';
import { getNews, setSafety, delSafety, videoSave, videoRemove } from '../../services/mapAdmin';
import { useUser } from '../../contexts/UserContext';
import { usePatrolMemo } from '../../contexts/PatrolMemoContext';
import { useAd } from '../../contexts/AdContext';
import SetRegion from './SetRegion';
import UploadBtn from './UploadBtn';

const Overlay = ({ type, isOpen, onHide, onClose, contents, title, onMemoClick }) => {
  const {patrolMemo} = usePatrolMemo();
  const {user} = useUser();
  const {setAdArr} = useAd();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({type: 'custom', contents: ''});
  const [memoTab, setMemoTab] = useState(0);
  const [filteredMemo, setFilteredMemo] = useState(patrolMemo.filter(x => x.public === 1));
  const [expandedMemo, setExpandedMemo] = useState('');
  const [targetMemo, setTargetMemo] = useState({});
  const [addr, setAddr] = useState({belong1: '전라북도', belong2: '전주시 완산구', belong3: ''});
  const [safetyUploadTab, setSafetyUploadTab] = useState(0);
  const [safetyOption, setSafetyOption] = useState();
  const [safetyEmbed, setSafetyEmbed] = useState(true);
  const [safetyPrea, setSafetyPrea] = useState('');
  const [safetyFile, setSafetyFile] = useState(null);
  const [imgs, setImgs] = useState([]);
  const [activeImgs, setActiveImgs] = useState([]);
  const [videos, setVideos] = useState([]);
  const [hiddenStates, setHiddenStates] = useState([]);
  const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false);
  const [videoMetadata, setVideoMetadata] = useState({
    videoId: '', title: '', src: '', tutor: '', desc: '', length: 0, thumbnail: '', startDate: '', endDate: ''
  });

  

  const onTabChange = (menu) => {
    let data = [];
    if (menu) {
      data = patrolMemo.filter(x => (x.public === 0) && x.patrol === user.patrol.name );
    } else {
      data = patrolMemo.filter(x => x.public === 1);
    }
    setFilteredMemo(data)
    setMemoTab(menu);
  }
  const expandedMemoChanged = (id) => {
    console.log(id);
    console.log(targetMemo);
    console.log(patrolMemo);
    if (onMemoClick) {
      onMemoClick(patrolMemo.filter(x => x.id == id)[0].location);
    }
    if (expandedMemo === id) setExpandedMemo('');
    else setExpandedMemo(id);
  }

  const isDeleted = async (id) => {
    const data = filteredMemo.filter(x => x.id !== id);
    setFilteredMemo(data);
  }

  const closeClicked = () => {
    setMemoTab(0);
    onClose();
  }

  const onSafetyOptionUpload = async () => {
    setIsLoading(true);
    const metadata = {
      addr: addr.belong2,
      type: safetyOption,
      prea: safetyPrea,
      embed: safetyEmbed,
    }
    const result = await setSafety(metadata, safetyFile);
    setIsLoading(false);
    if (result === 'SetSafetyGeo Success') {
      setAlertData({type: 'custom', contents: '업로드되었습니다.'});
    } else {
      setAlertData({type: 'error', contents: `에러가 발생했습니다. \n${result}`});
    }
    setIsAlertOpen(true);
  }

  const onSafetyOptionDel = async () => {
    setIsLoading(true);
    const result = await delSafety(addr.belong2, safetyOption);
    setAlertData({type: result === 'no data' ? 'warning' : result === 'success' ? 'custom' : 'error',
      contents: result === 'no data' ? '삭제할 데이터가 없습니다.' : result === 'success' ? `${addr.belong2}의 ${safetyOption} 데이터가 삭제되었습니다.` : `에러가 발생했습니다. \n${result}`})
    setIsAlertOpen(true);
    setIsLoading(false);
  }


  const onVideoUploadChange = (cat, val) => {
    if (['startDate', 'endDate'].includes(cat)) {
      if (val.length && /^-?\d+$/.test(val.slice(-1)) === false) return;
      if ([4, 7].includes(val.length)) {
        if (val.length !== videoMetadata[cat].length - 1) {
          return setVideoMetadata((prevState) => ({...prevState, [cat]: val + '-'}))
        } else {
          return setVideoMetadata((prevState) => ({...prevState, [cat]: val.slice(0, -1)}))
        }
      }
      if (val.length===11) {
        return
      }
    }
    setVideoMetadata((prevState) => ({...prevState, [cat]: val}))
  }

  const handleLabelClick = (index) => {
    setHiddenStates((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index]; // Set the specific div's state to hidden
      return newState;
    });
  };

  const handleModVideo = async (video) => {
    setVideoMetadata({
      videoId: video.video_id, title: video.title, src: video.src, tutor: video.tutor, desc: video.desc, length: video.length, thumbnail: video.thumbnail, startDate: video.startDate || video.start_date, endDate: video.endDate || video.end_date
    });
    setIsVideoUploadOpen(true);
  }

  const handleDelVideo = async (video) => {
    const response = await videoRemove(video.video_id);
    if (response) {
      setAlertData({type: 'custom', contents: `${video.title}이 삭제되었습니다.`})
      setIsAlertOpen(true);
      getVideoList();
    }
  }

  const getVideoList = async () => {
    const res = await getVideo();
    console.log(res);
    if (!res.length) return onClose();
    // const result = res
    //   .sort((a, b) => a.id - b.id)
    //   .map(item => {
    //     const replacedFiles = item.files.replace(/'/g, '"');
    //     return ({
    //       ...item,
    //       files: JSON.parse(replacedFiles)
    //     });
    //   })
    setVideos(res);
    setHiddenStates(res.map(() => true))
  }

  const onVideoUploadSubmit = async () => {
    if (videoMetadata.title.trim().length < 1) {
      setAlertData({type: 'error', contents: '제목을 입력해주세요.'});
      return setIsAlertOpen(true);
    } else if (videoMetadata.src.trim().length < 1) {
      setAlertData({type: 'error', contents: '영상 주소를 입력해주세요.'});
      return setIsAlertOpen(true);
    } else if (!videoMetadata.length) {
      setAlertData({type: 'error', contents: '영상 길이를 입력해주세요.'});
      return setIsAlertOpen(true);
    } else if (!(/^-?\d+$/.test(videoMetadata.length))) {
      setVideoMetadata((prevState) => ({...prevState, length: 0}))
      setAlertData({type: 'error', contents: '영상 길이는 숫자만 입력해주세요.'});
      return setIsAlertOpen(true);
    } else if (videoMetadata.startDate.length < 10) {
      setAlertData({type: 'error', contents: '날짜를 입력해주세요.'});
      return setIsAlertOpen(true);
    }
    const result = await videoSave(videoMetadata);
    if (result) {
      setAlertData({type: 'custom', contents: videoMetadata.videoId ? `${videoMetadata.title}이 수정되었습니다.` : '영상이 업로드되었습니다.'});
      getVideoList();
      setIsAlertOpen(true);
      if (videoMetadata.videoId) setIsVideoUploadOpen(!isVideoUploadOpen);
      setVideoMetadata({
        videoId: '', title: '', src: '', tutor: '', desc: '', length: 0, thumbnail: '', startDate: '', endDate: ''
      });
    }
    // setIsVideoUploadOpen(!isVideoUploadOpen);
  }

  const getAdList = async () => {
    const res = await getNews('list', 'adver', {belong1: '전라북도', belong2: '', belong3: ''});
    if (!res.length) return onClose();
    const result = res
      .sort((a, b) => a.id - b.id)
      .map(item => {
        const replacedFiles = item.files.replace(/'/g, '"');
        return ({
          ...item,
          files: JSON.parse(replacedFiles)
        });
      })
    setAdArr(result);
  }


  useEffect(() => {
    if (type === 'patrol_memo') {
      const data = patrolMemo.filter((x => x.public === 1));
      setFilteredMemo(data);
    }
  }, [patrolMemo])
  
  useEffect(() => {
    if (type === 'patrol_memo_detail') {
      const data = patrolMemo.filter(x => x.id === contents)[0];
      setTargetMemo(data);
    } else if (type === 'ad') {
      getAdList();
    } else if (type === 'safety_upload') {
      const safetyMapOptions = utils.getSafetyMapImg()
      setImgs(safetyMapOptions);
      setSafetyOption(safetyMapOptions[0].val);
    } else if (type === 'video') {
      getVideoList();
    }
  }, [type])

  return (
    <div
      className={`fixed inset-0 z-[9999] ${
        isOpen ? 'block' : 'hidden'
      } bg-black bg-opacity-50`}
    >
      <div className={`absolute inset-0 flex justify-center ${type === 'ad' ? 'items-end' : 'items-center'}`}>
        <div className="bg-white p-2 rounded-lg w-5/6 max-w-xl max-h-[500px] overflow-auto">
        {isLoading ? <div className="h-[500px] relative">
          <Loader />
        </div> : ( <>
          {title && type!=='patrol_memo_detail' && (
            <div className="flex justify-between align-center p-2">
              <p className="text-xl font-bold">{title}</p>
              <span className="flex gap-2 align-center text-sm">
                {onHide&&<label>
                  <input
                    type="checkbox"
                    checked={contents}
                    onChange={() => onHide(!contents)}
                    className="mr-2"
                  />
                  오늘 하루 그만보기
                </label>}
                <img src={utils.del} alt="delete" width="20px" onClick={closeClicked} />
              </span>
            </div>
          )}
          {!type && <p>{contents}</p>}
          {type === 'img' && <img src={contents} alt="content" width={'90%'} className="flex justify-center my-2 mx-auto"/>}
          {type === 'ad' && <SwiperComponent />}
          {['patrol_memo', 'patrol_memo_detail'].includes(type) && !targetMemo.id && (
            <div className="my-2">
              <div className="flex">
                <div className={`flex-1 py-2 text-md rounded-t border-b-4 ${memoTab ? '':'border-indigo08 bg-indigo01'}`}
                    onClick={() => onTabChange(0)}
                >
                  전체
                </div>
                <div className={`flex-1 whitespace-pre p-2 text-md rounded-t border-b-4 ${memoTab ? 'border-indigo08 bg-indigo01':''}`}
                    onClick={() => onTabChange(1)}
                >
                  {user.patrol.name} 자율방범대
                </div>
              </div>
            {filteredMemo.length ? filteredMemo.map(memo => {
              return (
                <Memo key={memo.id} memo={memo} isExpanded={expandedMemo && expandedMemo === memo.id} setIsExpanded={expandedMemoChanged} isDeleted={isDeleted}/>
              )
            }) : (
              <p className="p-4 text-center" >작성된 메모가 없습니다</p>
            )}
            </div>
          ) }
          {type==='patrol_memo_detail' && targetMemo.id && (
            <Memo key={targetMemo.id} memo={targetMemo} isExpanded={true} setIsExpanded={expandedMemoChanged} isDeleted={isDeleted}/>
          )}
          {type==='flex_detail' && (
            <div className="text-left p-2">
              <p><b>요청기간: </b>{contents.start_date}~{contents.end_date}</p>
              <p><b>요청시간: </b>{utils.convertToTwoDigits(contents.start_time)}시~{utils.convertToTwoDigits(contents.end_time)}시</p>
              <p><b>요청주소: </b>{contents.addr && contents.addr.length > 0 ? contents.addr : contents.road}</p>
              <p><b>요청사항: </b>{contents.request && contents.request.length > 0 ? contents.request : '-'}</p>
              <p><b>요청사유: </b>{contents.reason && contents.reason.length > 0 ? contents.reason : '-'}</p>
            </div>
          )}
          {type==='safety_upload' && (
            <>
              <div className='grid grid-cols-2 gap-2'>
                <ColoredButton text={'안전지도 업로드'} type={safetyUploadTab === 0 ? 'custom' : 'gray'} click={() => setSafetyUploadTab(0)}></ColoredButton>
                <ColoredButton text={'안전지도 삭제'} type={safetyUploadTab === 1 ? 'custom' : 'gray'} click={() => setSafetyUploadTab(1)}></ColoredButton>
              </div>
              {safetyUploadTab === 0 ? (
                <div className="flex flex-col text-left p-2 gap-2">
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">지역:</b>
                    <SetRegion addr={addr} limit={'belong2'} setTargetRegion={(val) => setAddr(val)} isAllIncluded={false}/>
                  </div>
                  
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">유형:</b>
                    <select id="safetyOption" name="safetyOption" value={safetyOption} onChange={(e) => setSafetyOption(e.target.value)} className="border rounded p-3 w-max">
                      {imgs && imgs.length && imgs.map(x => (
                        <option key={x.name} value={x.val}>{x.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">주소 접두어:</b>
                    <input type='text' value={safetyPrea} onChange={(e) => setSafetyPrea(e.value.trim())} className="border rounded p-3 w-max"
                    placeholder='비워 두셔도 됩니다'
                    />
                  </div>
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">위경도 사용:</b>
                    <select value={safetyEmbed} onChange={(e) => setSafetyEmbed(e.target.value)}  className="border rounded p-3">
                      <option key={'safetyEmbed1'} value={true}>네</option>
                      <option key={'safetyEmbed2'} value={false}>아니오</option>
                    </select>
                    {/* <input type='text' value={safetyEmbed} onChange={(e) => setSafetyEmbed(e.value)} className="border rounded p-3 w-max"/> */}
                  </div>
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">파일:</b>
                    {/* <input
                      type='file'
                      className="py-3 w-max"
                      accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                    /> */}
                    <UploadBtn src={'safetyUpload'} text={'파일 선택'} onFileChange={(file) => setSafetyFile(file)}/>
                  </div>
                  <div className='-mt-3 flex flex-wrap gap-2'>
                    <b className="w-1/4 text-center"></b>
                    <p className="text-sm break-all">{safetyFile ? safetyFile.name : '엑셀 파일을 올려주세요.'}</p>
                  </div>
                  <div className='flex flex-wrap gap-3 items-center justify-center'>
                    <BorderedButton text={'업로드하기'} type={'custom'} click={() => onSafetyOptionUpload()}/>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col text-left p-2 gap-2">
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">지역:</b>
                    <SetRegion addr={addr} limit={'belong2'} setTargetRegion={(val) => setAddr(val)} isAllIncluded={false}/>
                  </div>
                  <div className='flex flex-wrap gap-2'>
                    <b className="p-3 w-1/4 text-center">유형:</b>
                    <select id="safetyOption" name="safetyOption" value={safetyOption} onChange={(e) => {setSafetyOption(e.target.value)}} className="border rounded p-3 w-max">
                      {imgs && imgs.length && imgs.map(x => (
                        <option key={x.name} value={x.val}>{x.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='flex flex-wrap gap-3 items-center justify-center'>
                    <BorderedButton text={'삭제하기'} type={'error'} click={() => onSafetyOptionDel()}/>
                  </div>
                </div>
              )}
            </>
          )}
          {type==='video' && (
            <div className="flex flex-col p-3">
              <div className={isVideoUploadOpen ? "grid grid-cols-2 gap-2" : "grid "}>
                <ColoredButton text={isVideoUploadOpen ? '업로드 취소' : '+ 업로드하기'} type={'gray'} click={() => setIsVideoUploadOpen(!isVideoUploadOpen)}></ColoredButton>
                {isVideoUploadOpen && <ColoredButton text={'저장'} type={'custom'} click={() => onVideoUploadSubmit()}></ColoredButton>}
              </div>
              <div className={isVideoUploadOpen ? 'mt-2' : 'hidden'}>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 flex items-center justify-center">제목:</b>
                  <input className='col-span-3 p-3 break-all text-left ps-0 outline-none'
                    onChange={(e) => {onVideoUploadChange('title', e.target.value)}}
                    value={videoMetadata.title}
                  />
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 flex items-center justify-center">주소:</b>
                  <input className='col-span-3 p-3 break-all text-left ps-0 outline-none'
                    placeholder='영상의 웹 주소를 입력해주세요: https://spatrol.finger.solutions/'
                    onChange={(e) => {onVideoUploadChange('src', e.target.value)}}
                    value={videoMetadata.src}
                  />
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <div className='bg-indigo01 flex flex-col p-3'>
                    <b className="bg-indigo01 items-center justify-center">길이: </b>
                    <p className='text-sm'>(단위: 초)</p>
                  </div>
                  <input className="col-span-3 p-3 break-all text-left ps-0 outline-none"
                  placeholder='1분 15초이면 75만 입력해주세요'
                  type='number'
                  onChange={(e) => {onVideoUploadChange('length', e.target.value)}}
                  value={videoMetadata.length}
                  />
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">강사:</b>
                  <input className="col-span-3 p-3 break-all text-left ps-0 outline-none"
                    onChange={(e) => {onVideoUploadChange('tutor', e.target.value)}}
                    value={videoMetadata.tutor}
                  />
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">설명:</b>
                  <textarea className="col-span-3 p-3 break-all text-left ps-0 outline-none"
                    onChange={(e) => {onVideoUploadChange('desc', e.target.value)}}
                    value={videoMetadata.desc}
                  />
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">시작일:</b>
                  <input className="col-span-3 p-3 break-all text-left ps-0 outline-none"
                    placeholder={`${utils.dateFormatter(new Date())} 형식으로 입력해주세요`}
                    onChange={(e) => {onVideoUploadChange('startDate', e.target.value)}}
                    value={videoMetadata.startDate}
                  />
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">종료일:</b>
                  <input className="col-span-3 p-3 break-all text-left ps-0 outline-none"
                    placeholder={`${utils.dateFormatter(new Date())} 형식으로 입력해주세요`}
                    onChange={(e) => {onVideoUploadChange('endDate', e.target.value)}}
                    value={videoMetadata.endDate}
                  />
                </div>
              </div>
            </div>
          )}
          {type==='video' && !isVideoUploadOpen && (
            videos && videos.length && videos.map((x, idx) => (
            <div className='flex flex-col p-2' key={x.video_id} >
              
              <div className='flex flex-wrap items-center'>
                <p className="p-2 border-b-2 border-indigo08 font-bold text-left break-all">{idx + 1}. {x.title}</p>
                <BorderedButton type={'custom'} text={hiddenStates[idx] ? '열기' : '닫기'} click={() => handleLabelClick(idx)} classname={'me-3'}/>
                <BorderedButton type={'error'} text={'삭제'} click={() => handleDelVideo(x)} classname={'me-3'}/>
                {!hiddenStates[idx] &&
                  <BorderedButton type={'warning'} text={'수정하기'} click={() => handleModVideo(x)} />
                }
              </div>
              <div className={hiddenStates[idx] ? 'hidden' : 'mt-2'}>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 flex items-center justify-center">주소:</b>
                  <p className='col-span-3 p-3 break-all text-left'>{x.src}</p>
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">강사:</b>
                  <p className="col-span-3 p-3 text-left">{x.tutor}</p>
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">설명:</b>
                  <p className="col-span-3 p-3 break-all text-left">{x.desc}</p>
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">길이:</b>
                  <p className="col-span-3 p-3 text-left">{x.length}</p>
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">썸네일 고민 중:</b>
                  <p className="col-span-3 p-3 text-left">{x.title}</p>
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">시작일:</b>
                  <p className="col-span-3 p-3 text-left">{x.start_date}</p>
                </div>
                <div className='grid grid-cols-4 gap-2 border'>
                  <b className="bg-indigo01 p-3 items-center justify-center">종료일:</b>
                  <p className="col-span-3 p-3 text-left">{x.end_date}</p>
                </div>
              </div>
            </div>
            ))
          )}
          {type==='privacy' && <Privacy />}
          {type==='company' && <Company />}
          {type==='newsDetail' && <News source={contents} />}
          {type!=='ad'&&<div className="text-right">
            <button onClick={closeClicked} className="mt-4 bg-indigo08 text-white px-4 py-2 rounded">
              닫기
            </button>
          </div>}
          {isAlertOpen && <Alert type={alertData.type} message={alertData.contents} onClose={() => setIsAlertOpen(false)} />}
          </>)}
        </div>
      </div>
    </div>
  );
};

export default Overlay;