import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import EduHeader from './TabEdu/EduHeader';
import { mainSection } from '../../styles/index';
import lectures from '../../assets/data/lectures.json';
import Lecture from './TabEdu/Lecture';
import { getVideo, getVideoData } from '../../services/mapMain';
import { useVideo } from '../../contexts/VideoContext';
import { useUser } from '../../contexts/UserContext';


function TabEdu(props) {
  const [lectureArr, setLectureArr] = useState([]);
  const [userLectureData, setUserLectureData] = useState([]);
  const {user, setUser} = useUser();
  const {video, setVideo} = useVideo();
  const updateVideo = async(res, list) => {
    await setLectureArr(list);
    await setVideo(list);
    await setUserLectureData(res);
  }
  const getLectures = async() => {
    let videoList;
    let res;
    if (window.location.hostname === 'localhost') {
      videoList = await getVideo();
      res = user.lectures || [];
    } else {
      res = await getVideoData();
      videoList = await getVideo();
    }
    await updateVideo(res, videoList);
  }

  const updateUserLectureData = async () => {
    const res = await getVideoData();
    await setUserLectureData(res);
  }
  useEffect(() => {
    if (props.eduData) {
      updateUserLectureData();
    }
  }, [props.eduData])
  useEffect(() => {
    getLectures()
  }, [])
  return (
    <div className={`${mainSection} h-screen flex flex-col overflow-y-auto`}>
      <EduHeader />
      <p className="font-bold text-xl text-left mt-2 px-3">수강목록</p>
      <div className="p-2 my-2 pb-20">
        {lectureArr && lectureArr.map(lecture => {
          return <Link key={lecture.video_id} to={`/edu/${lecture.video_id}`}><Lecture lecture={lecture} wasPlayed={userLectureData.filter(x => x.video_id === lecture.video_id)}/></Link>
        })}
      </div>
    </div>
  );
}

export default TabEdu;